import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useConfigSite } from "../../hooks/use-config-site";
import { ApiService, BASE_URL_API } from "../../services/api";
import { PageHeder } from "../page-header";

const INITIAL_ITEM = {
  imagemPaginaHome: null,
  imagemCabecalhoOutrasPaginas: null,
  arquivoPaginaClubeDeVantagens: null,
};

const REGISTER_ITEM_VIEW = "REGISTER_ITEM_VIEW";

export const ConfiguracaoSitePage = () => {
  const [view, setView] = useState(REGISTER_ITEM_VIEW);
  const [item, setItem] = useState(INITIAL_ITEM);
  const [habilitarEdicao, setHabilitarEdicao] = useState(false);
  const [errorMessageApi, setErrorMessageApi] = useState("");
  const { handleConfigSite } = useConfigSite();

  const loadView = async () => {
    setErrorMessageApi("");
    await loadItem();
    loadRegisterItemView(item);
  };

  const loadRegisterItemView = (item) => {
    setErrorMessageApi("");
    setView(REGISTER_ITEM_VIEW);
  };

  const changeConfigSiteField = (field, value) => {
    const newIag = { ...item, [field]: value };

    setItem(newIag);
  };

  const requestErrorHandler = (error) => {
    if (error.response && error.response.data && error.response.data.mensagem) {
      setErrorMessageApi(error.response.data.mensagem);
      toast.error(error.response.data.mensagem);
    } else if (error.request) {
      toast.error("Falha na requisição.");
      console.log(error.request);
    } else {
      toast.error("Ops! Algo de errado aconteceu.");
      console.log("Error", error.message);
    }
  };

  const validateForm = async (item) => {
    let errorMessages = [];

    return errorMessages.length ? errorMessages.join(";") : null;
  };

  const loadItem = async () => {
    await ApiService.get("/configuracao-site").then(({ data }) =>
      setItem(data)
    );
  };

  const saveItem = async (item) => {
    setErrorMessageApi("");

    const errorMessage = await validateForm(item);
    if (errorMessage) {
      return;
    }

    const data = new FormData();

    data.append("imagemPaginaHome", item.imagemPaginaHome);
    data.append(
      "imagemCabecalhoOutrasPaginas",
      item.imagemCabecalhoOutrasPaginas
    );
    data.append(
      "arquivoPaginaClubeDeVantagens",
      item.arquivoPaginaClubeDeVantagens
    );

    try {
      let newData = await ApiService.post("/configuracao-site", data).then(({ data }) => data);

      toast.success("Edição efetuada com sucesso!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      handleConfigSite(newData);

      setHabilitarEdicao(false);
    } catch (error) {
      requestErrorHandler(error);
    }
  };

  const handleSubmitRegisterItem = (event) => {
    event.preventDefault();
  };

  const renderRegisterItemView = () => (
    <div className="row">
      <div className="col-md-12">
        {item ? (
          <form onSubmit={handleSubmitRegisterItem}>
            <div className="form-group">
              <label htmlFor="imagemPaginaHome">
                Imagem da Pagina Inicial (obrigatório)
              </label>
              <ul className="list-group">
                <li className="list-group-item">
                  {item.imagemPaginaHome &&
                  typeof item.imagemPaginaHome == "string" ? (
                    <>
                      <button
                        type="button"
                        className="btn btn-xs btn-danger"
                        disabled={!habilitarEdicao}
                        onClick={() =>
                          changeConfigSiteField("imagemPaginaHome", null)
                        }
                      >
                        Alterar
                      </button>
                      &nbsp;
                      <a
                        target="_blank"
                        href={`${BASE_URL_API}/files/${item.imagemPaginaHome}`}
                        download="Imagem da Pagina Inicial"
                        rel="noreferrer"
                      >
                        {item.imagemPaginaHome}
                      </a>
                    </>
                  ) : (
                    <input
                      required
                      id="imagemPaginaHome"
                      type="file"
                      accept="image/png, image/jpeg, image/jpg, image/jpeg"
                      className="form-control"
                      disabled={!habilitarEdicao}
                      onChange={(event) =>
                        changeConfigSiteField(
                          "imagemPaginaHome",
                          event.target.files[0]
                        )
                      }
                    />
                  )}
                </li>
              </ul>
            </div>

            <div className="form-group">
              <label htmlFor="imagemCabecalhoOutrasPaginas">
                Imagem de cabeçalho das demais páginas (obrigatório)
              </label>
              <ul className="list-group">
                <li className="list-group-item">
                  {item.imagemCabecalhoOutrasPaginas &&
                  typeof item.imagemCabecalhoOutrasPaginas == "string" ? (
                    <>
                      <button
                        type="button"
                        className="btn btn-xs btn-danger"
                        disabled={!habilitarEdicao}
                        onClick={() =>
                          changeConfigSiteField(
                            "imagemCabecalhoOutrasPaginas",
                            null
                          )
                        }
                      >
                        Alterar
                      </button>
                      &nbsp;
                      <a
                        target="_blank"
                        href={`${BASE_URL_API}/files/${item.imagemCabecalhoOutrasPaginas}`}
                        download="Imagem de cabeçalho das demais páginas"
                        rel="noreferrer"
                      >
                        {item.imagemCabecalhoOutrasPaginas}
                      </a>
                    </>
                  ) : (
                    <input
                      required
                      id="imagemCabecalhoOutrasPaginas"
                      type="file"
                      accept="image/png, image/jpeg, image/jpg, image/jpeg"
                      className="form-control"
                      disabled={!habilitarEdicao}
                      onChange={(event) =>
                        changeConfigSiteField(
                          "imagemCabecalhoOutrasPaginas",
                          event.target.files[0]
                        )
                      }
                    />
                  )}
                </li>
              </ul>
            </div>

            <div className="form-group">
              <label htmlFor="arquivoPaginaClubeDeVantagens">
                Arquivo da Lista dos Clubes de Vantagens (obrigatório)
              </label>
              <ul className="list-group">
                <li className="list-group-item">
                  {item.arquivoPaginaClubeDeVantagens &&
                  typeof item.arquivoPaginaClubeDeVantagens == "string" ? (
                    <>
                      <button
                        type="button"
                        className="btn btn-xs btn-danger"
                        disabled={!habilitarEdicao}
                        onClick={() =>
                          changeConfigSiteField(
                            "arquivoPaginaClubeDeVantagens",
                            null
                          )
                        }
                      >
                        Alterar
                      </button>
                      &nbsp;
                      <a
                        target="_blank"
                        href={`${BASE_URL_API}/files/${item.arquivoPaginaClubeDeVantagens}`}
                        download="Arquivo da Lista dos Clubes de Vantagens"
                        rel="noreferrer"
                      >
                        {item.arquivoPaginaClubeDeVantagens}
                      </a>
                    </>
                  ) : (
                    <input
                      required
                      id="arquivoPaginaClubeDeVantagens"
                      type="file"
                      className="form-control"
                      disabled={!habilitarEdicao}
                      onChange={(event) =>
                        changeConfigSiteField(
                          "arquivoPaginaClubeDeVantagens",
                          event.target.files[0]
                        )
                      }
                    />
                  )}
                </li>
              </ul>
            </div>  
          </form>
        ) : null}

        {habilitarEdicao ? (
          <div className="text-right">
            <button
              type="button"
              className="btn btn-default"
              onClick={() => setHabilitarEdicao(false)}
            >
              Cancelar
            </button>
            &nbsp;
            <button
              type="button"
              className="btn btn-success"
              onClick={() => saveItem(item)}
            >
              Salvar
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );

  const renderErrorMessageApi = (errorMessageApi) => (
    <div className="alert alert-danger" role="alert">
      {errorMessageApi}
    </div>
  );

  useEffect(() => {
    loadView();
  }, []);

  useEffect(() => {
    if (!habilitarEdicao) {
      loadItem();
    }
  }, [habilitarEdicao]);

  return (
    <>
      <PageHeder isAdminIag>Configuração do Site</PageHeder>

      <section className="pt-30 pb-30">
        <div className="container">
          {errorMessageApi ? renderErrorMessageApi(errorMessageApi) : null}
          {view === REGISTER_ITEM_VIEW ? (
            item.id !== null ? (
              <>
                {!habilitarEdicao ? (
                  <>
                    <button
                      className="btn btn-primary"
                      onClick={() => setHabilitarEdicao(!habilitarEdicao)}
                    >
                      Habilitar Edição
                    </button>
                    <br />
                    <br />
                  </>
                ) : null}
                {renderRegisterItemView()}
              </>
            ) : (
              <span>Carregando...</span>
            )
          ) : null}
        </div>
      </section>
    </>
  );
};
