import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ApiService } from "../../services/api";
import { PageHeder } from "../page-header";

const INITIAL_ITEM = {
  id: null,
  usuario: "",
  email: "",
  senha: "",
  confirmeSenha: "",
  flagAdmin: false,
  iag: null,
};

const LIST_ITEMS_VIEW = "LIST_ITEMS_VIEW";
const REGISTER_ITEM_VIEW = "REGISTER_ITEM_VIEW";
const DELETE_ITEM_VIEW = "DELETE_ITEM_VIEW";

export const UsuarioAdminPage = () => {
  const [view, setView] = useState(LIST_ITEMS_VIEW);
  const [items, setItems] = useState([]);
  const [item, setItem] = useState(INITIAL_ITEM);
  const [editarSenha, setEditarSenha] = useState(0);
  const [errorMessageApi, setErrorMessageApi] = useState("");

  const loadListView = () => {
    setErrorMessageApi("");
    setItem(INITIAL_ITEM);
    setView(LIST_ITEMS_VIEW);
    loadItems();
  };

  const loadRegisterItemView = (item) => {
    setErrorMessageApi("");
    setEditarSenha(item && item.id ? 0 : 1);
    setItem(item);
    setView(REGISTER_ITEM_VIEW);
  };

  const loadDeleteItemView = (item) => {
    setErrorMessageApi("");
    setItem(item);
    setView(DELETE_ITEM_VIEW);
  };

  function changeItem(event) {
    const itemCopia = { ...item };
    itemCopia[event.target.name] = event.target.value;
    setItem(itemCopia);
  }

  const validateForm = async (item) => {
    let errorMessage = null;

    if (!item.usuario) {
      errorMessage = "Campo Usuário inválido";
      window.$(`form .form-group:has(#usuario)`).addClass("has-error");
      window.$(`form .form-group #usuario-help-block`).text(errorMessage);
      return errorMessage;
    } else {
      window.$(`form .form-group:has(#usuario)`).removeClass("has-error");
      window.$(`form .form-group #usuario-help-block`).text("");
    }

    return errorMessage;
  };

  const loadItems = async () => {
    await ApiService.get("/usuarios").then(({ data }) => setItems(data));
  };

  const saveItem = async (item) => {
    setErrorMessageApi("");

    const errorMessage = await validateForm(item);
    if (errorMessage) {
      return;
    }

    const payload = {
      id: item.id,
      usuario: item.usuario,
      email: item.email,
      flagAdmin: item.flagAdmin,
    };

    if (editarSenha == 1) {
      payload.senha = item.senha;
      payload.confirmeSenha = item.confirmeSenha;
    }

    try {
      if (!item.id) {
        await ApiService.post("/usuarios", payload).then();

        toast.success("Cadastro efetuado com sucesso!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        await ApiService.post("/usuarios/" + payload.id, payload).then();

        toast.success("Edição efetuada com sucesso!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      loadListView();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.mensagem
      ) {
        setErrorMessageApi(error.response.data.mensagem);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
    }
  };

  const deleteItem = async (item) => {
    setErrorMessageApi("");

    try {
      await ApiService.delete("/usuarios/" + item.id).then();

      toast.success("Remoção efetuada com sucesso!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      loadListView();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.mensagem
      ) {
        setErrorMessageApi(error.response.data.mensagem);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
    }
  };

  const renderDeleteItemView = () => (
    <div className="row">
      <div className="col-md-12 text-center">
        {item ? (
          <>
            <p className="m-0">
              Você realmente deseja remover o item "{item.usuario}"?
            </p>
            <p className="m-0">Esta ação não poderá ser revertida.</p>
          </>
        ) : null}

        <br />

        <div>
          <button
            type="button"
            className="btn btn-default"
            onClick={() => loadListView()}
          >
            Cancelar
          </button>
          &nbsp;
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => deleteItem(item)}
          >
            Remover
          </button>
        </div>
      </div>
    </div>
  );

  const renderRegisterItemView = () => (
    <div className="row">
      <div className="col-md-12">
        {item ? (
          <form>
            {item.id ? (
              <div className="form-group">
                <label htmlFor="id"># ID</label>
                <input
                  type="text"
                  className="form-control"
                  id="id"
                  placeholder="ID"
                  value={item.id}
                  disabled
                />
              </div>
            ) : null}

            {item.iag && item.iag.nome ? (
              <div className="form-group">
                <label htmlFor="iag">IAG</label>
                <input
                  disabled
                  id="iag"
                  name="iag"
                  type="text"
                  className="form-control"
                  value={`${item.iag.id} - ${item.iag.nome}`}
                />
              </div>
            ) : null}

            <div className="form-group">
              <label htmlFor="flagAdmin">Admin</label>
              <select
                required
                id="flagAdmin"
                name="flagAdmin"
                className="form-control"
                value={item.flagAdmin}
                onChange={changeItem}
              >
                <option value={true}>Sim</option>
                <option value={false}>Não</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="usuario">Usuário (obrigatório)</label>
              <input
                required
                id="usuario"
                name="usuario"
                type="text"
                className="form-control"
                value={item.usuario}
                onChange={changeItem}
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">E-mail (obrigatório)</label>
              <input
                required
                id="email"
                name="email"
                type="email"
                className="form-control"
                value={item.email}
                onChange={changeItem}
              />
            </div>

            {item.id ? (
              <div className="form-group">
                <label htmlFor="editarSenha">Editar senha do Usuario?</label>
                <select
                  required
                  id="editarSenha"
                  name="editarSenha"
                  className="form-control"
                  value={editarSenha}
                  onChange={(event) => setEditarSenha(event.target.value)}
                >
                  <option value={1}>Sim</option>
                  <option value={0}>Não</option>
                </select>
              </div>
            ) : null}

            {editarSenha == 1 ? (
              <>
                <div className="form-group">
                  <label htmlFor="senha">Senha (obrigatório)</label>
                  <input
                    required
                    id="senha"
                    name="senha"
                    type="password"
                    className="form-control"
                    onChange={changeItem}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="confirmeSenha">
                    Confirmar Senha (obrigatório)
                  </label>
                  <input
                    required
                    id="confirmeSenha"
                    name="confirmeSenha"
                    type="password"
                    className="form-control"
                    onChange={changeItem}
                  />
                </div>
              </>
            ) : null}
          </form>
        ) : null}

        <div className="text-right">
          <button
            type="button"
            className="btn btn-default"
            onClick={() => loadListView()}
          >
            Cancelar
          </button>
          &nbsp;
          <button
            type="button"
            className="btn btn-success"
            onClick={() => saveItem(item)}
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
  );

  const renderItem = (item, index) => (
    <tr key={`item-${index}`}>
      <td>{item.id}</td>
      <td>{item.usuario}</td>
      <td>{item.flagAdmin ? "Sim" : "Não"}</td>
      <td>{item.iag ? `${item.iag.id} - ${item.iag.nome}` : ""}</td>
      <td>
        <button
          type="button"
          className="btn btn-warning"
          onClick={() => loadRegisterItemView(item)}
        >
          Editar
        </button>
        &nbsp;
        <button
          type="button"
          className="btn btn-danger"
          onClick={() => loadDeleteItemView(item)}
        >
          Remover
        </button>
      </td>
    </tr>
  );

  const renderListView = () => (
    <>
      <div className="row">
        <div className="col-md-12 text-right mb-30">
          <button
            type="button"
            className="btn btn-success"
            onClick={() => loadRegisterItemView(INITIAL_ITEM)}
          >
            Cadastrar
          </button>
        </div>
      </div>

      <div className="row">
        <table className="col-md-12">
          <thead>
            <tr>
              <th># ID</th>
              <th>Usuário</th>
              <th>Admin</th>
              <th>Iag</th>
              <th style={{ width: "172px" }}>Opções</th>
            </tr>
          </thead>
          <tbody>
            {items && items.length ? (
              items.map(renderItem)
            ) : (
              <tr>
                <td className="text-center" colSpan={3}>
                  Nenhum registro encontrado
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );

  const renderErrorMessageApi = (errorMessageApi) => (
    <div className="alert alert-danger" role="alert">
      {errorMessageApi}
    </div>
  );

  useEffect(() => {
    loadListView();
  }, []);

  return (
    <>
      <PageHeder>Usuários</PageHeder>

      <section className="pt-30 pb-30">
        <div className="container">
          {errorMessageApi ? renderErrorMessageApi(errorMessageApi) : null}
          {view === LIST_ITEMS_VIEW ? renderListView() : null}
          {view === REGISTER_ITEM_VIEW ? renderRegisterItemView() : null}
          {view === DELETE_ITEM_VIEW ? renderDeleteItemView() : null}
        </div>
      </section>
    </>
  );
};
