import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useConfigSite } from "../hooks/use-config-site";
import { useQuery } from "../hooks/use-query";
import { ROUTES_PATHS } from "../routes";
import { ApiService, BASE_URL_API } from "../services/api";
import { PageHeder } from "./page-header";
import { SearchClubeDeVantagensForm } from "./search-clube-de-vantagens-form";

export const SearchClubeDeVantagensPage = () => {
  const query = useQuery();
  const [items, setItems] = useState([]);
  const { configSite } = useConfigSite();
  const [carregando, setCarregando] = useState(true);

  const params = useMemo(() => {
    const state = {
      nome: query.get("nome") || "",
      categoriaClubeDeVantagem: query.get("categoriaClubeDeVantagem") || "",
    };

    return state;
  }, [query]);

  const searchItems = async () => {
    const newParams = {};
    setCarregando(true);

    if (params.nome && params.nome != "") {
      newParams.nome = params.nome;
    }

    if (
      params.categoriaClubeDeVantagem &&
      params.categoriaClubeDeVantagem != ""
    ) {
      newParams.categoriaClubeDeVantagem = params.categoriaClubeDeVantagem;
    }

    const urlSearchParams = new URLSearchParams(newParams);
    const searchParms = urlSearchParams.toString();

    try {
      await ApiService.get("/clubes-de-vantagem?" + searchParms).then(
        ({ data }) => {
          setItems(data);
          setCarregando(false);
        }
      );
    } catch (err) {
      setCarregando(false);
    }
  };

  useEffect(() => {
    searchItems();
  }, [params]);

  const renderList = items.length ? (
    items?.map((item, key) => (
      <div key={key} className="col-sm-6 col-xs-12">
        <div className="course-item">
          <div className="image">
            <img
              src={`${BASE_URL_API}/files/${item.foto}`}
              aria-hidden
              alt={item.nome}
              className="img-responsive"
            />
          </div>
          <div className="content">
            <h4>
              <Link
                to={ROUTES_PATHS.CLUBE_DE_VANTAGENS_DETAIL.replace(
                  /:id/i,
                  item.id
                )}
              >
                {item.nome}
              </Link>
            </h4>

            <p style={{ whiteSpace: "pre-wrap", marginBottom: 10 }}>
              {item?.descricao}
            </p>

            {item?.categoriaClubeDeVantagem &&
            item?.categoriaClubeDeVantagem.length ? (
              <div className="culture-segments">
                <div>Categorias</div>
                <ul>
                  {item?.categoriaClubeDeVantagem?.map((categoria, key) => (
                    <li key={`categoria-${key}`}>
                      <span>{categoria.nome}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    ))
  ) : (
    <div className="col-xs-12">
      <h3>Nenhum item encontrado.</h3>
    </div>
  );

  return (
    <>
      <PageHeder>Página de Busca</PageHeder>

      <section className="artists padding-120">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <a
                className="button-default"
                style={{ marginBottom: 30 }}
                target="_blank"
                href={`${BASE_URL_API}/files/${configSite.arquivoPaginaClubeDeVantagens}`}
              >
                Lista de apoiadores
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-12 col-xs-12">
              <div className="sidebar">
                <div className="sidebar-item">
                  <h3 className="sidebar-title">Buscar por...</h3>
                  <SearchClubeDeVantagensForm initialState={params} />
                </div>
              </div>
            </div>

            <div className="col-md-8 col-sm-12 col-xs-12">
              <div className="row equal">
                {carregando ? <h3>Carregando...</h3> : renderList}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
