import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ApiService, BASE_URL_API } from "../../services/api";
import { cutWithReticence } from "../../utils";
import { PageHeder } from "../page-header";

const INITIAL_ITEM = {
  id: null,
  nome: "",
  arquivo: null,
  dataInicio: "",
  dataFim: "",
  show: false,
  anexoEdital: [],
};

const LIST_ITEMS_VIEW = "LIST_ITEMS_VIEW";
const REGISTER_ITEM_VIEW = "REGISTER_ITEM_VIEW";
const DELETE_ITEM_VIEW = "DELETE_ITEM_VIEW";

export const EditalAdminPage = () => {
  const [view, setView] = useState(LIST_ITEMS_VIEW);
  const [items, setItems] = useState([]);
  const [item, setItem] = useState(INITIAL_ITEM);
  const [errorMessageApi, setErrorMessageApi] = useState("");

  const loadListView = () => {
    setErrorMessageApi("");
    setItem(INITIAL_ITEM);
    setView(LIST_ITEMS_VIEW);
    loadItems();
  };

  const loadRegisterItemView = (item) => {
    setErrorMessageApi("");
    setItem(item);
    setView(REGISTER_ITEM_VIEW);
  };

  const loadDeleteItemView = (item) => {
    setErrorMessageApi("");
    setItem(item);
    setView(DELETE_ITEM_VIEW);
  };

  const changeitemField = (field, value) => {
    const newitem = { ...item, [field]: value };

    setItem(newitem);
  };

  const adicionarAnexoEdital = () => {
    const anexoEdital = [
      ...item.anexoEdital,
      {
        id: null,
        nome: "",
        arquivo: null,
        excluir: false,
      },
    ];
    const newItem = { ...item, anexoEdital };

    setItem(newItem);
  };

  const removerAnexoEdital = (idx) => {
    item.anexoEdital[idx].excluir = true;

    const anexoEdital = [...item.anexoEdital];
    const newItem = { ...item, anexoEdital };

    setItem(newItem);
  };

  const changeItemAnexoEditalField = (idx, field, value) => {
    item.anexoEdital[idx][field] = value;

    const anexoEdital = [...item.anexoEdital];
    const newItem = { ...item, anexoEdital };

    setItem(newItem);
  };

  const validateForm = async (item) => {
    let errorMessages = [];

    if (!item.nome) {
      const errorMessage = "Campo Nome inválido";
      window.$(`form .form-group:has(#nome)`).addClass("has-error");
      window.$(`form .form-group #nome-help-block`).text(errorMessage);

      errorMessages.push(errorMessage);
    } else {
      window.$(`form .form-group:has(#nome)`).removeClass("has-error");
      window.$(`form .form-group #nome-help-block`).text("");
    }

    return errorMessages.length ? errorMessages.join(";") : null;
  };

  const loadItems = async () => {
    await ApiService.get("/editais").then(({ data }) => setItems(data));
  };

  const saveItem = async (item) => {
    setErrorMessageApi("");

    const errorMessage = await validateForm(item);
    if (errorMessage) {
      return;
    }

    const data = new FormData();

    data.append("nome", item.nome);
    data.append("dataInicio", item.dataInicio);
    data.append("dataFim", item.dataFim);
    data.append("arquivo", item.arquivo);
    data.append("show", item.show);

    data.append(
      "anexoEdital",
      JSON.stringify(
        item.anexoEdital.map((ae) => ({ ...ae, arquivo: undefined }))
      )
    );

    for (let i = 0; i < item.anexoEdital.length; i++) {
      data.append(`anexoEdital[${i}].arquivo`, item.anexoEdital[i].arquivo);
    }

    try {
      if (!item.id) {
        await ApiService.post("/editais", data).then();

        toast.success("Cadastro efetuado com sucesso!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        await ApiService.post("/editais/" + item.id, data).then();

        toast.success("Edição efetuada com sucesso!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      loadListView();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.mensagem
      ) {
        setErrorMessageApi(error.response.data.mensagem);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
    }
  };

  const deleteItem = async (item) => {
    setErrorMessageApi("");

    try {
      await ApiService.delete("/editais/" + item.id).then();

      toast.success("Remoção efetuada com sucesso!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      loadListView();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.mensagem
      ) {
        setErrorMessageApi(error.response.data.mensagem);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
    }
  };

  const renderDeleteItemView = () => (
    <div className="row">
      <div className="col-md-12 text-center">
        {item ? (
          <>
            <p className="m-0">
              Você realmente deseja deletar o item "{item.nome}"?
            </p>
            <p className="m-0">Esta ação não poderá ser revertida.</p>
          </>
        ) : null}

        <br />

        <div>
          <button
            type="button"
            className="btn btn-default"
            onClick={() => loadListView()}
          >
            Cancelar
          </button>
          &nbsp;
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => deleteItem(item)}
          >
            Remover
          </button>
        </div>
      </div>
    </div>
  );

  const renderRegisterItemView = () => (
    <div className="row">
      <div className="col-md-12">
        {item ? (
          <form>
            {item.id ? (
              <div className="form-group">
                <label htmlFor="id"># ID</label>
                <input
                  type="text"
                  className="form-control"
                  id="id"
                  placeholder="ID"
                  value={item.id}
                  disabled
                />
              </div>
            ) : null}

            <div className="form-group">
              <label htmlFor="nome">Nome (obrigatório)</label>
              <input
                type="text"
                className="form-control"
                id="nome"
                placeholder="Nome"
                required
                value={item.nome}
                onChange={(event) =>
                  changeitemField("nome", event.target.value)
                }
                aria-describedby="nome-help-block"
              />
              <span id="nome-help-block" className="help-block"></span>
            </div>

            <div className="form-group">
              <label htmlFor="dataInicio">Data de Inicio</label>
              <input
                type="date"
                className="form-control"
                id="dataInicio"
                placeholder="dataInicio"
                required
                value={item.dataInicio}
                onChange={(event) =>
                  changeitemField("dataInicio", event.target.value)
                }
                aria-describedby="dataInicio-help-block"
              />
              <span id="dataInicio-help-block" className="help-block"></span>
            </div>

            <div className="form-group">
              <label htmlFor="dataFim">Data de Fim</label>
              <input
                type="date"
                className="form-control"
                id="dataFim"
                placeholder="dataFim"
                required
                value={item.dataFim}
                onChange={(event) =>
                  changeitemField("dataFim", event.target.value)
                }
                aria-describedby="dataFim-help-block"
              />
              <span id="dataFim-help-block" className="help-block"></span>
            </div>

            <div className="form-group">
            <label htmlFor="show">Mostrar na tela inicial?</label>
            <select
              className="form-control"
              id="show"
              value={item.show}
              onChange={(event) => changeitemField("show", event.target.value)}
            >
              <option value="true">Sim</option>
              <option value="false">Não</option>
            </select>
            </div>

            <div className="form-group">
              <label htmlFor="arquivo">Arquivo</label>

              <ul className="list-group">
                <li className="list-group-item">
                  {item.arquivo && typeof item.arquivo == "string" ? (
                    <>
                      <button
                        type="button"
                        className="btn btn-xs btn-danger"
                        onClick={() => changeitemField("arquivo", null)}
                      >
                        Alterar
                      </button>
                      &nbsp;
                      <a
                        target="_blank"
                        href={`${BASE_URL_API}/files/${item.arquivo}`}
                        download="Arquivo"
                        rel="noreferrer"
                      >
                        {cutWithReticence(item.arquivo, 30, false)}
                      </a>
                    </>
                  ) : (
                    <input
                      required
                      id="arquivo"
                      type="file"
                      accept="image/png, image/jpeg, image/jpg, image/jpeg, application/pdf,.doc, .docx"
                      className="form-control"
                      onChange={(event) =>
                        changeitemField("arquivo", event.target.files[0])
                      }
                    />
                  )}
                </li>
              </ul>
            </div>

            <div className="form-group">
              <label>Anexos</label>

              <br />

              <button
                type="button"
                className="btn btn-primary"
                onClick={() => adicionarAnexoEdital()}
              >
                Adicionar Anexo
              </button>

              <br />
              <br />

              <ul className="list-group">
                {item && item.anexoEdital
                  ? item.anexoEdital.map((cp, idx) =>
                      cp.excluir != true ? (
                        <li className="list-group-item">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="nome">Nome</label>
                                <input
                                  id="nome"
                                  type="text"
                                  className="form-control"
                                  value={item.anexoEdital[idx].nome}
                                  onChange={(event) =>
                                    changeItemAnexoEditalField(
                                      idx,
                                      "nome",
                                      event.target.value
                                    )
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor={`arquivo-${idx}`}>
                                  Arquivo
                                </label>

                                <div>
                                  {item.anexoEdital[idx].arquivo &&
                                  typeof item.anexoEdital[idx].arquivo ==
                                    "string" ? (
                                    <>
                                      <button
                                        type="button"
                                        className="btn btn-xs btn-danger"
                                        onClick={() =>
                                          changeItemAnexoEditalField(
                                            idx,
                                            "arquivo",
                                            null
                                          )
                                        }
                                      >
                                        Alterar
                                      </button>
                                      &nbsp;
                                      <a
                                        target="_blank"
                                        href={`${BASE_URL_API}/files/${item.anexoEdital[idx].arquivo}`}
                                        download="Arquivo"
                                        rel="noreferrer"
                                      >
                                        {cutWithReticence(
                                          item.anexoEdital[idx].arquivo,
                                          30,
                                          false
                                        )}
                                      </a>
                                    </>
                                  ) : (
                                    <input
                                      required
                                      id={`arquivo-${idx}`}
                                      type="file"
                                      accept="image/png, image/jpeg, image/jpg, image/jpeg, application/pdf,.doc, .docx"
                                      className="form-control"
                                      onChange={(event) =>
                                        changeItemAnexoEditalField(
                                          idx,
                                          "arquivo",
                                          event.target.files[0]
                                        )
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-md-2">
                              <button
                                type="button"
                                className="btn btn-danger"
                                style={{ marginTop: 33 }}
                                onClick={() => removerAnexoEdital(idx)}
                              >
                                Remover
                              </button>
                            </div>
                          </div>
                        </li>
                      ) : null
                    )
                  : null}
              </ul>
            </div>
          </form>
        ) : null}

        <div className="text-right">
          <button
            type="button"
            className="btn btn-default"
            onClick={() => loadListView()}
          >
            Cancelar
          </button>
          &nbsp;
          <button
            type="button"
            className="btn btn-success"
            onClick={() => saveItem(item)}
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
  );

  const renderItem = (item, index) => (
    <tr key={`item-${index}`}>
      <td>{item.id}</td>
      <td>{item.nome}</td>
      <td>
        <button
          type="button"
          className="btn btn-warning"
          onClick={() => loadRegisterItemView(item)}
        >
          Editar
        </button>
        &nbsp;
        <button
          type="button"
          className="btn btn-danger"
          onClick={() => loadDeleteItemView(item)}
        >
          Remover
        </button>
      </td>
    </tr>
  );

  const renderListView = () => (
    <>
      <div className="row">
        <div className="col-md-12 text-right mb-30">
          <button
            type="button"
            className="btn btn-success"
            onClick={() => loadRegisterItemView(INITIAL_ITEM)}
          >
            Cadastrar
          </button>
        </div>
      </div>

      <div className="row">
        <table className="col-md-12">
          <thead>
            <tr>
              <th># ID</th>
              <th>Nome</th>
              <th style={{ width: "172px" }}>Opções</th>
            </tr>
          </thead>
          <tbody>
            {items && items.length ? (
              items.map(renderItem)
            ) : (
              <tr>
                <td className="text-center" colSpan={3}>
                  Nenhum registro encontrado
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );

  const renderErrorMessageApi = (errorMessageApi) => (
    <div className="alert alert-danger" role="alert">
      {errorMessageApi}
    </div>
  );

  useEffect(() => {
    loadListView();
  }, []);

  return (
    <>
      <PageHeder>Editais</PageHeder>

      <section className="pt-30 pb-30">
        <div className="container">
          {errorMessageApi ? renderErrorMessageApi(errorMessageApi) : null}
          {view === LIST_ITEMS_VIEW ? renderListView() : null}
          {view === REGISTER_ITEM_VIEW ? renderRegisterItemView() : null}
          {view === DELETE_ITEM_VIEW ? renderDeleteItemView() : null}
        </div>
      </section>
    </>
  );
};
