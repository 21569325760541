import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "../hooks/use-query";
import { ROUTES_PATHS } from "../routes";
import { ApiService, BASE_URL_API } from "../services/api";
import { PageHeder } from "./page-header";
import { SearchProducersAndArtistsForm } from "./search-producers-and-artists-form";

export const SearchProducersAndArtistsPage = () => {
  const query = useQuery();
  const [items, setItems] = useState([]);
  const [carregando, setCarregando] = useState(true);

  const params = useMemo(() => {
    const state = {
      tipo: query.get("tipo") || "",
      categoriaTecnico: query.get("categoriaTecnico") || "",
      categoriaArtista: query.get("categoriaArtista") || "",
      nome: query.get("nome") || "",
      endereco_cidade: query.get("endereco_cidade") || "",
      endereco_bairro: query.get("endereco_bairro") || "",
    };

    return state;
  }, [query]);

  const searchItems = async () => {
    const newParams = {
      status: "Aprovado",
    };

    setCarregando(true);

    if (params.tipo == "ART") {
      newParams.flagArtista = true;
    }

    if (params.tipo == "TEC") {
      newParams.flagTecnico = true;
    }

    if (params.nome && params.nome != "") {
      newParams.nome = params.nome;
    }

    if (params.endereco_cidade && params.endereco_cidade != "") {
      newParams.endereco_cidade = params.endereco_cidade;
    }

    if (params.endereco_bairro && params.endereco_bairro != "") {
      newParams.endereco_bairro = params.endereco_bairro;
    }

    if (params.categoriaArtista && params.categoriaArtista != "") {
      newParams.categoriaArtista = params.categoriaArtista;
    }

    if (params.categoriaTecnico && params.categoriaTecnico != "") {
      newParams.categoriaTecnico = params.categoriaTecnico;
    }

    const urlSearchParams = new URLSearchParams(newParams);
    const searchParms = urlSearchParams.toString();

    try {
      await ApiService.get("/iags?" + searchParms).then(({ data }) => {
        setItems(data);
        setCarregando(false);
      });
    } catch (err) {
      setCarregando(false);
    }
  };

  useEffect(() => {
    searchItems();
  }, [params]);

  const renderList = items.length ? (
    items?.map((item, key) => (
      <div key={key} className="col-sm-6 col-xs-12">
        <div className="course-item">
          <div className="image">
            <img
              src={`${BASE_URL_API}/files/${item.foto}`}
              aria-hidden
              alt={item.nome}
              className="img-responsive"
            />
          </div>
          <div className="content">
            <h4>
              <Link
                to={ROUTES_PATHS.PRODUCER_ARTIST_DETAIL.replace(
                  /:id/i,
                  item.id
                )}
              >
                {item.nome}
              </Link>
            </h4>

            <div style={{ marginBottom: 10 }}>
              {item.flagTecnico && item.flagArtista ? (
                <span>Técnico e Artista</span>
              ) : item.flagTecnico ? (
                <span>Técnico</span>
              ) : item.flagArtista ? (
                <span>Artista</span>
              ) : null}
            </div>

            <div className="culture-segments">
              <div>Bairro / Cidade</div>
              <ul>
                <li>
                  <span>
                    {item?.endereco?.bairro + ", " + item?.endereco?.cidade}
                  </span>
                </li>
              </ul>
            </div>

            {item?.categoriaArtista && item?.categoriaArtista.length ? (
              <div className="culture-segments">
                <div>Categorias do Artista</div>
                <ul>
                  {item?.categoriaArtista?.map((categoria, key) => (
                    <li key={`categoria-${key}`}>
                      <span>{categoria.nome}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}

            {item?.categoriaTecnico && item?.categoriaTecnico.length ? (
              <div className="culture-segments">
                <div>Categorias do Técnico</div>
                <ul>
                  {item?.categoriaTecnico?.map((categoria, key) => (
                    <li key={`categoria-${key}`}>
                      <span>{categoria.nome}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    ))
  ) : (
    <div className="col-xs-12">
      <h3>Nenhum item encontrado.</h3>
    </div>
  );

  return (
    <>
      <PageHeder>Página de Busca</PageHeder>

      <section className="artists padding-120">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-12 col-xs-12">
              <div className="sidebar">
                <div className="sidebar-item">
                  <h3 className="sidebar-title">Buscar por...</h3>
                  <SearchProducersAndArtistsForm initialState={params} />
                </div>
              </div>
            </div>
            <div className="col-md-8 col-sm-12 col-xs-12">
              <div className="row">
                {carregando ? <h3>Carregando...</h3> : renderList}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
