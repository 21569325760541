export const Footer = ({ isAdmin = false, isAdminIag = false }) => (
  <footer>
    <div className="footer-bottom">
      <div className="container">
        <div className="row">
          <div
            className={`col-xs-12 ${
              isAdmin || isAdminIag ? "text-center" : ""
            }`}
          >
            <p>
              &copy; 2022. Desenvolvido por&nbsp;
              <a href="https://www.haniger.com.br/" target="_blank">
                Haniger
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
);
