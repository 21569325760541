import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../hooks/use-query";
import { ROUTES_PATHS } from "../routes";
import { ApiService } from "../services/api";

const INITIAL_STATE = {
  categoriaClubeDeVantagem: "",
  nome: "",
};

export const SearchClubeDeVantagensForm = ({
  initialState = INITIAL_STATE,
}) => {
  const query = useQuery();
  const navigate = useNavigate();
  const [item, setItem] = useState(initialState);
  const [categoriaClubeDeVantagem, setCategoriasClubeDeVantagem] = useState([]);

  const urlBusca = useMemo(() => {
    let url = ROUTES_PATHS.SEARCH_CLUBE_DE_VANTAGENS;
    let querySearch = {};

    if (item.categoriaClubeDeVantagem !== "") {
      querySearch.categoriaClubeDeVantagem = item.categoriaClubeDeVantagem;
    }

    if (item.nome !== "") {
      querySearch.nome = item.nome;
    }

    return `${url}?${new URLSearchParams(querySearch).toString()}`;
  }, [item]);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      navigate(urlBusca);
    },
    [urlBusca]
  );

  const changeItemField = (field, value) => {
    const newItem = { ...item, [field]: value };

    setItem(newItem);
  };

  const loadCategoriasClubeDeVantagem = async () => {
    await ApiService.get("/categorias-clube-de-vantagem").then(({ data }) =>
      setCategoriasClubeDeVantagem(data)
    );
  };

  const loadItems = async () => {
    loadCategoriasClubeDeVantagem();
  };

  useEffect(() => {
    const newItem = { ...item };

    newItem.categoriaClubeDeVantagem =
      initialState.categoriaClubeDeVantagem ||
      INITIAL_STATE.categoriaClubeDeVantagem;

    newItem.nome = initialState.nome || INITIAL_STATE.nome;

    setItem(newItem);
  }, [initialState]);

  useEffect(() => {
    loadItems();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <select
        name="categoriaClubeDeVantagem"
        value={item.categoriaClubeDeVantagem}
        onChange={(event) =>
          changeItemField("categoriaClubeDeVantagem", event.target.value)
        }
      >
        <option value={""}>Categoria...</option>
        {categoriaClubeDeVantagem?.map((cat) => (
          <option value={cat.id}>{cat.nome}</option>
        ))}
      </select>

      <input
        type="text"
        name="nome"
        value={item.nome}
        onChange={(event) => changeItemField("nome", event.target.value)}
        placeholder="Nome"
      />

      <button>Buscar</button>
    </form>
  );
};
