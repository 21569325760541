import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ApiService } from "../../services/api";
import { PageHeder } from "../page-header";

const INITIAL_ITEM = {
  id: null,
  nome: "",
};

const LIST_ITEMS_VIEW = "LIST_ITEMS_VIEW";
const REGISTER_ITEM_VIEW = "REGISTER_ITEM_VIEW";
const DELETE_ITEM_VIEW = "DELETE_ITEM_VIEW";

export const ClubsCategoriesAdminPage = () => {
  const [view, setView] = useState(LIST_ITEMS_VIEW);
  const [items, setItems] = useState([]);
  const [item, setItem] = useState(INITIAL_ITEM);
  const [errorMessageApi, setErrorMessageApi] = useState("");

  const loadListView = () => {
    setErrorMessageApi("");
    setItem(INITIAL_ITEM);
    setView(LIST_ITEMS_VIEW);
    loadItems();
  };

  const loadRegisterItemView = (item) => {
    setErrorMessageApi("");
    setItem(item);
    setView(REGISTER_ITEM_VIEW);
  };

  const loadDeleteItemView = (item) => {
    setErrorMessageApi("");
    setItem(item);
    setView(DELETE_ITEM_VIEW);
  };

  const changeName = (nome) => {
    setItem({ ...item, nome });
  };

  const validateForm = async (item) => {
    let errorMessage = null;

    if (!item.nome) {
      errorMessage = "Campo Nome inválido";
      window.$(`form .form-group:has(#nome)`).addClass("has-error");
      window.$(`form .form-group #nome-help-block`).text(errorMessage);
      return errorMessage;
    } else {
      window.$(`form .form-group:has(#nome)`).removeClass("has-error");
      window.$(`form .form-group #nome-help-block`).text("");
    }

    return errorMessage;
  };

  const loadItems = async () => {
    await ApiService.get("/categorias-clube-de-vantagem").then(({ data }) =>
      setItems(data)
    );
  };

  const saveItem = async (item) => {
    setErrorMessageApi("");

    const errorMessage = await validateForm(item);
    if (errorMessage) {
      return;
    }

    try {
      if (!item.id) {
        await ApiService.post("/categorias-clube-de-vantagem", item).then();

        toast.success("Cadastro efetuado com sucesso!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        await ApiService.post(
          "/categorias-clube-de-vantagem/" + item.id,
          item
        ).then();

        toast.success("Edição efetuada com sucesso!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      loadListView();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.mensagem
      ) {
        setErrorMessageApi(error.response.data.mensagem);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
    }
  };

  const deleteItem = async (item) => {
    setErrorMessageApi("");

    try {
      await ApiService.delete(
        "/categorias-clube-de-vantagem/" + item.id
      ).then();

      toast.success("Remoção efetuada com sucesso!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      loadListView();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.mensagem
      ) {
        setErrorMessageApi(error.response.data.mensagem);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
    }
  };

  const renderDeleteItemView = () => (
    <div className="row">
      <div className="col-md-12 text-center">
        {item ? (
          <>
            <p className="m-0">
              Você realmente deseja remover o item "{item.nome}"?
            </p>
            <p className="m-0">Esta ação não poderá ser revertida.</p>
          </>
        ) : null}

        <br />

        <div>
          <button
            type="button"
            className="btn btn-default"
            onClick={() => loadListView()}
          >
            Cancelar
          </button>
          &nbsp;
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => deleteItem(item)}
          >
            Remover
          </button>
        </div>
      </div>
    </div>
  );

  const renderRegisterItemView = () => (
    <div className="row">
      <div className="col-md-12">
        {item ? (
          <form>
            {item.id ? (
              <div className="form-group">
                <label htmlFor="id"># ID</label>
                <input
                  type="text"
                  className="form-control"
                  id="id"
                  placeholder="ID"
                  value={item.id}
                  disabled
                />
              </div>
            ) : null}

            <div className="form-group">
              <label htmlFor="nome">Nome (obrigatório)</label>
              <input
                type="text"
                className="form-control"
                id="nome"
                placeholder="Nome"
                required
                value={item.nome}
                onChange={(event) => changeName(event.target.value)}
                aria-describedby="nome-help-block"
              />
              <span id="nome-help-block" className="help-block"></span>
            </div>
          </form>
        ) : null}

        <div className="text-right">
          <button
            type="button"
            className="btn btn-default"
            onClick={() => loadListView()}
          >
            Cancelar
          </button>
          &nbsp;
          <button
            type="button"
            className="btn btn-success"
            onClick={() => saveItem(item)}
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
  );

  const renderItem = (item, index) => (
    <tr key={`item-${index}`}>
      <td>{item.id}</td>
      <td>{item.nome}</td>
      <td>
        <button
          type="button"
          className="btn btn-warning"
          onClick={() => loadRegisterItemView(item)}
        >
          Editar
        </button>
        &nbsp;
        <button
          type="button"
          className="btn btn-danger"
          onClick={() => loadDeleteItemView(item)}
        >
          Remover
        </button>
      </td>
    </tr>
  );

  const renderListView = () => (
    <>
      <div className="row">
        <div className="col-md-12 text-right mb-30">
          <button
            type="button"
            className="btn btn-success"
            onClick={() => loadRegisterItemView(INITIAL_ITEM)}
          >
            Cadastrar
          </button>
        </div>
      </div>

      <div className="row">
        <table className="col-md-12">
          <thead>
            <tr>
              <th># ID</th>
              <th>Nome</th>
              <th style={{ width: "172px" }}>Opções</th>
            </tr>
          </thead>
          <tbody>
            {items && items.length ? (
              items.map(renderItem)
            ) : (
              <tr>
                <td className="text-center" colSpan={3}>
                  Nenhum registro encontrado
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );

  const renderErrorMessageApi = (errorMessageApi) => (
    <div className="alert alert-danger" role="alert">
      {errorMessageApi}
    </div>
  );

  useEffect(() => {
    loadListView();
  }, []);

  return (
    <>
      <PageHeder>Categorias de Clubes de Vantagens</PageHeder>

      <section className="pt-30 pb-30">
        <div className="container">
          {errorMessageApi ? renderErrorMessageApi(errorMessageApi) : null}
          {view === LIST_ITEMS_VIEW ? renderListView() : null}
          {view === REGISTER_ITEM_VIEW ? renderRegisterItemView() : null}
          {view === DELETE_ITEM_VIEW ? renderDeleteItemView() : null}
        </div>
      </section>
    </>
  );
};
