import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { ApiService, BASE_URL_API } from "../services/api";
import "./login-page.css";
import { toast } from "react-toastify";
import { useAuth } from "../hooks/use-auth";
import { ROUTES_PATHS } from "../routes";
import { cutWithReticence } from "../utils";

export const INITIAL_IAG = {
  nome: "",
  email: "",
  telefone: "",
  foto: null,
  status: "Pendente",
  resolucao: "",
  flagArtista: false,
  flagTecnico: false,
  usuario: {
    usuario: "",
    senha: "",
    confirmeSenha: "",
  },
  contaBancaria: {
    banco: "",
    conta: "",
    agencia: "",
    chavePix: "",
  },
  endereco: {
    cep: "",
    logradouro: "",
    endereco: "",
    numero: "",
    bairro: "",
    cidade: "",
    complemento: "",
    comprovanteResidencia: null,
  },
  categoriaArtista: [],
  categoriaTecnico: [],
  perfil: {
    descricao: "",
    contatoPerfil: [],
    imagemoPerfil: [],
  },
  pessoaFisica: {
    nomeArtistico: "",
    nomeMae: "",
    cpf: "",
    pisPasepNit: "",
    rg: "",
    orgaoExpedidorRg: "",
    dataNascimento: "",
    comprovanteRg: null,
    comprovanteCpf: null,
  },
  flagPessoaFisica: true,
  flagPessoaJuridica: false,
  pessoaJuridica: {
    nomeFantasia: "",
    razaoSocial: "",
    cnpj: "",
    funcao: "",
    dataRegistro: "",
    comprovanteCnpj: null,
  },
  pessoaResponsavel: {
    nome: "",
    telefone: "",
  },
  comprovanteAtividade: [],
};
export const INITIAL_USER = { usuario: "", senha: "" };

export const LOGIN_VIEW = "LOGIN_VIEW";

export const REGISTER_VIEW = "REGISTER_VIEW";

export const LoginPage = ({ view = LOGIN_VIEW }) => {
  const [errorMessageApi, setErrorMessageApi] = useState("");
  const [categoriaTecnico, setTechnicianCategories] = useState([]);
  const [categoriaArtista, setArtistCategories] = useState([]);
  const [flagRead, setFlagRead] = useState(0);
  const [user, setUser] = useState(INITIAL_USER);
  const [iag, setIag] = useState(INITIAL_IAG);
  const [processando, setProcessando] = useState(false);
  const navigate = useNavigate();
  const { login, verificaUsuarioLogadoAdmin, verificaUsuarioLogadoAdminIag } =
    useAuth();

  const loadTechnicianCategories = async () => {
    await ApiService.get("/categorias-tecnico").then(({ data }) =>
      setTechnicianCategories(data)
    );
  };

  const loadArtistCategories = async () => {
    await ApiService.get("/categorias-artista").then(({ data }) =>
      setArtistCategories(data)
    );
  };

  const loadItems = async () => {
    loadArtistCategories();
    loadTechnicianCategories();
  };

  useEffect(() => {
    loadItems();
  }, []);

  const changeUserField = (field, value) => {
    setUser({ ...user, [field]: value });
  };

  const changeIagField = (field, value) => {
    const newIag = { ...iag, [field]: value };

    setIag(newIag);
  };

  const changeIagUserField = (field, value) => {
    const usuario = { ...iag.usuario, [field]: value };
    const newIag = { ...iag, usuario };

    setIag(newIag);
  };

  const changeIagPessoaFisicaField = (field, value) => {
    const pessoaFisica = { ...iag.pessoaFisica, [field]: value };
    const newIag = { ...iag, pessoaFisica };

    setIag(newIag);
  };

  const changeIagPessoaJuridicaField = (field, value) => {
    const pessoaJuridica = { ...iag.pessoaJuridica, [field]: value };
    const newIag = { ...iag, pessoaJuridica };

    setIag(newIag);
  };

  const changeIagPessoaResponsavelField = (field, value) => {
    const pessoaResponsavel = { ...iag.pessoaResponsavel, [field]: value };
    const newIag = { ...iag, pessoaResponsavel };

    setIag(newIag);
  };

  const changeIagEnderecoField = (field, value) => {
    const endereco = { ...iag.endereco, [field]: value };
    const newIag = { ...iag, endereco };

    setIag(newIag);
  };

  const changeIagPerfilField = (field, value) => {
    const perfil = { ...iag.perfil, [field]: value };
    const newIag = { ...iag, perfil };

    setIag(newIag);
  };

  const adicionarComprovanteAtividade = () => {
    const comprovanteAtividade = [
      ...iag.comprovanteAtividade,
      {
        id: null,
        comprovante: null,
      },
    ];
    const newItem = { ...iag, comprovanteAtividade };

    setIag(newItem);
  };

  const removerComprovanteAtividade = (idx) => {
    iag.comprovanteAtividade[idx].excluir = true;

    const comprovanteAtividade = [...iag.comprovanteAtividade];
    const newItem = { ...iag, comprovanteAtividade };

    setIag(newItem);
  };

  const changeItemComprovanteAtividadeField = (idx, field, value) => {
    iag.comprovanteAtividade[idx][field] = value;

    const comprovanteAtividade = [...iag.comprovanteAtividade];
    const newItem = { ...iag, comprovanteAtividade };

    setIag(newItem);
  };

  const adicionarContatoPerfil = () => {
    const contatoPerfil = [
      ...iag.perfil.contatoPerfil,
      {
        id: null,
        tipoContato: "",
        contato: "",
        excluir: false,
      },
    ];
    const perfil = { ...iag.perfil, contatoPerfil };
    const newIag = { ...iag, perfil };

    setIag(newIag);
  };

  const removerContatoPerfil = (idx) => {
    iag.perfil.contatoPerfil[idx].excluir = true;

    const contatoPerfil = [...iag.perfil.contatoPerfil];
    const perfil = { ...iag.perfil, contatoPerfil };
    const newIag = { ...iag, perfil };

    setIag(newIag);
  };

  const changeIagContatoPerfilField = (idx, field, value) => {
    iag.perfil.contatoPerfil[idx][field] = value;

    const contatoPerfil = [...iag.perfil.contatoPerfil];
    const perfil = { ...iag.perfil, contatoPerfil };
    const newIag = { ...iag, perfil };

    setIag(newIag);
  };

  const changeIagContaBancariaField = (field, value) => {
    const contaBancaria = { ...iag.contaBancaria, [field]: value };
    const newIag = { ...iag, contaBancaria };

    setIag(newIag);
  };

  const changeArtistCategories = (categoriaArtistaSelected) => {
    let cc = [...iag.categoriaArtista];
    let ccNew = categoriaArtistaSelected.map(({ value }) =>
      categoriaArtista.find((cc) => cc.id == value)
    );

    cc = cc.map((cco) => {
      if (!ccNew.find((ccn) => ccn.id == cco.id)) {
        cco.excluir = true;
      } else {
        delete cco.excluir;
      }

      return cco;
    });

    ccNew.filter((ccn) => {
      if (!cc.find((cco) => cco.id == ccn.id)) {
        cc.push(ccn);
      }
    });

    setIag({
      ...iag,
      categoriaArtista: cc,
    });
  };

  const changeTechnicianCategories = (categoriaTecnicoSelected) => {
    let cc = [...iag.categoriaTecnico];
    let ccNew = categoriaTecnicoSelected.map(({ value }) =>
      categoriaTecnico.find((cc) => cc.id == value)
    );

    cc = cc.map((cco) => {
      if (!ccNew.find((ccn) => ccn.id == cco.id)) {
        cco.excluir = true;
      } else {
        delete cco.excluir;
      }

      return cco;
    });

    ccNew.filter((ccn) => {
      if (!cc.find((cco) => cco.id == ccn.id)) {
        cc.push(ccn);
      }
    });

    setIag({
      ...iag,
      categoriaTecnico: cc,
    });
  };

  const requestErrorHandler = (error) => {
    if (error.response && error.response.data && error.response.data.mensagem) {
      setErrorMessageApi(error.response.data.mensagem);
      toast.error(error.response.data.mensagem);
    } else if (error.request) {
      toast.error("Falha na requisição.");
      console.log(error.request);
    } else {
      toast.error("Ops! Algo de errado aconteceu.");
      console.log("Error", error.message);
    }
  };

  const validateLoginForm = async (user) => {
    let errorMessage = null;

    if (!user.usuario) {
      errorMessage = "Campo Usuário inválido";
      window.$(`form .form-group:has(#usuario)`).addClass("has-error");
      window.$(`form .form-group #usuario-help-block`).text(errorMessage);
      return errorMessage;
    } else {
      window.$(`form .form-group:has(#usuario)`).removeClass("has-error");
      window.$(`form .form-group #usuario-help-block`).text("");
    }

    if (!user.senha) {
      errorMessage = "Campo Senha inválido";
      window.$(`form .form-group:has(#senha)`).addClass("has-error");
      window.$(`form .form-group #senha-help-block`).text(errorMessage);
      return errorMessage;
    } else {
      window.$(`form .form-group:has(#senha)`).removeClass("has-error");
      window.$(`form .form-group #senha-help-block`).text("");
    }

    return errorMessage;
  };

  const validateRegisterForm = async (iag) => {
    let errorMessage = null;

    if (!iag.usuario.usuario || iag.usuario.usuario == "") {
      errorMessage = "Campo Usuário inválido";
      window.$(`form .form-group:has(#iag-usuario)`).addClass("has-error");
      window.$(`form .form-group #iag-usuario-help-block`).text(errorMessage);
      return errorMessage;
    } else {
      window.$(`form .form-group:has(#iag-usuario)`).removeClass("has-error");
      window.$(`form .form-group #iag-usuario-help-block`).text("");
    }

    if (!iag.usuario.senha || iag.usuario.senha == "") {
      errorMessage = "Campo Senha inválido";
      window.$(`form .form-group:has(#iag-senha)`).addClass("has-error");
      window.$(`form .form-group #iag-senha-help-block`).text(errorMessage);
      return errorMessage;
    } else {
      window.$(`form .form-group:has(#iag-senha)`).removeClass("has-error");
      window.$(`form .form-group #iag-senha-help-block`).text("");
    }

    if (!iag.usuario.confirmeSenha || iag.usuario.confirmeSenha == "") {
      errorMessage = "Campo Senha inválido";
      window
        .$(`form .form-group:has(#iag-confirmeSenha)`)
        .addClass("has-error");
      window
        .$(`form .form-group #iag-confirmeSenha-help-block`)
        .text(errorMessage);
      return errorMessage;
    } else {
      window
        .$(`form .form-group:has(#iag-confirmeSenha)`)
        .removeClass("has-error");
      window.$(`form .form-group #iag-confirmeSenha-help-block`).text("");
    }

    return errorMessage;
  };

  const handleSubmitLogin = async (event) => {
    event.preventDefault();
    setErrorMessageApi("");

    const errorMessage = await validateLoginForm(user);
    if (errorMessage) {
      return;
    }

    try {
      await login(user);
    } catch (error) {
      requestErrorHandler(error);
    }
  };

  const handleSubmitRegister = async (event) => {
    event.preventDefault();
    setErrorMessageApi("");

    const errorMessage = await validateRegisterForm(iag);
    if (errorMessage) {
      return;
    }

    try {
      setProcessando(true);
      const data = new FormData();

      data.append("nome", iag.nome);
      data.append("email", iag.email);
      data.append("telefone", iag.telefone);
      data.append("foto", iag.foto);
      data.append("status", iag.status);
      data.append("resolucao", iag.resolucao);
      data.append("flagArtista", iag.flagArtista);
      data.append("flagTecnico", iag.flagTecnico);
      iag.usuario.email = iag.email;
      data.append("usuario", JSON.stringify(iag.usuario));
      data.append("contaBancaria", JSON.stringify(iag.contaBancaria));
      data.append(
        "endereco",
        JSON.stringify({
          ...iag.endereco,
          comprovanteResidencia: undefined,
        })
      );
      data.append(
        "endereco.comprovanteResidencia",
        iag.endereco.comprovanteResidencia
      );
      data.append("categoriaArtista", JSON.stringify(iag.categoriaArtista));
      data.append("categoriaTecnico", JSON.stringify(iag.categoriaTecnico));
      data.append("perfil", JSON.stringify(iag.perfil));
      data.append(
        "pessoaFisica",
        JSON.stringify({
          ...iag.pessoaFisica,
          comprovanteCpf: undefined,
          comprovanteRg: undefined,
        })
      );
      data.append(
        "pessoaFisica.comprovanteCpf",
        iag.pessoaFisica.comprovanteCpf
      );
      data.append("pessoaFisica.comprovanteRg", iag.pessoaFisica.comprovanteRg);
      data.append("flagPessoaFisica", iag.flagPessoaFisica);
      data.append("flagPessoaJuridica", iag.flagPessoaJuridica);
      data.append(
        "pessoaJuridica",
        JSON.stringify({
          ...iag.pessoaJuridica,
          comprovanteCnpj: undefined,
        })
      );
      data.append(
        "pessoaJuridica.comprovanteCnpj",
        iag.pessoaJuridica.comprovanteCnpj
      );
      data.append("pessoaResponsavel", JSON.stringify(iag.pessoaResponsavel));
      data.append(
        "comprovanteAtividade",
        JSON.stringify(
          iag.comprovanteAtividade.map((ae) => ({
            ...ae,
            comprovante: undefined,
          }))
        )
      );

      for (let i = 0; i < iag.comprovanteAtividade.length; i++) {
        data.append(
          `comprovanteAtividade[${i}].comprovante`,
          iag.comprovanteAtividade[i].comprovante
        );
      }

      await ApiService.post("/iags", data).then();
      setProcessando(false);
      toast.success("IAG solicitada com sucesso!");
      navigate("/login");
    } catch (error) {
      setProcessando(false);
      requestErrorHandler(error);
    }
  };

  const verificaUsuario = async () => {
    const token = localStorage.getItem("token");
    const flagAdmin = localStorage.getItem("flagAdmin");

    if (token) {
      if (flagAdmin && flagAdmin == "true") {
        await verificaUsuarioLogadoAdmin();
        navigate(ROUTES_PATHS.ADMIN_HOME);
      } else {
        await verificaUsuarioLogadoAdminIag();
        navigate(ROUTES_PATHS.ADMIN_IAG_HOME);
      }
    }
  };

  useEffect(() => {
    setErrorMessageApi("");
  }, [view]);

  useEffect(() => {
    verificaUsuario();
  }, []);

  const renderLoginView = () => {
    return user ? (
      <form onSubmit={handleSubmitLogin}>
        <div className="form-group">
          <label htmlFor="usuario">Usuário</label>
          <input
            id="usuario"
            type="text"
            className="form-control"
            value={user.usuario}
            required
            onChange={(event) => changeUserField("usuario", event.target.value)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="senha">Senha</label>
          <input
            id="senha"
            type="password"
            className="form-control"
            value={user.senha}
            required
            onChange={(event) => changeUserField("senha", event.target.value)}
          />
        </div>

        <br />

        <button type="submit" className="btn btn-block button-default">
          Entrar
        </button>
      </form>
    ) : null;
  };

  const renderRegisterView = () => {
    return iag ? (
      <form onSubmit={handleSubmitRegister}>
        <div className="form-group">
          <label htmlFor="nome">Nome Completo (obrigatório)</label>
          <input
            id="nome"
            type="text"
            required
            className="form-control"
            value={iag.nome}
            onChange={(event) => changeIagField("nome", event.target.value)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="flagArtista">Você é um artista ? (obrigatório)</label>
          <select
            required
            id="flagArtista"
            className="form-control"
            value={iag.flagArtista}
            onChange={(event) =>
              changeIagField("flagArtista", event.target.value)
            }
          >
            <option value={true}>Sim</option>
            <option value={false}>Não</option>
          </select>
        </div>

        {iag.flagArtista ? (
          <>
            <div className="form-group">
              <label htmlFor="categoriaArtista">
                Categorias Artistica (obrigatório)
              </label>
              <Select
                id="categoriaArtista"
                required
                placeholder="Selecione..."
                noOptionsMessage={() => "Não encontrado"}
                isMulti={true}
                value={iag.categoriaArtista
                  .filter((cc) => !cc.excluir)
                  .map((cc) => ({
                    value: cc.id,
                    label: cc.nome,
                  }))}
                onChange={(value) => changeArtistCategories(value)}
                options={categoriaArtista.map((cc) => ({
                  value: cc.id,
                  label: cc.nome,
                }))}
                aria-describedby="categoriaArtista-help-block"
              />
              <span
                id="categoriaArtista-help-block"
                className="help-block"
              ></span>
            </div>

            <div className="form-group">
              <label htmlFor="pessoaFisica.nomeArtistico">
                Nome Artístico (obrigatório)
              </label>
              <input
                id="pessoaFisica.nomeArtistico"
                type="text"
                required
                className="form-control"
                value={iag.pessoaFisica.nomeArtistico}
                onChange={(event) =>
                  changeIagPessoaFisicaField(
                    "nomeArtistico",
                    event.target.value
                  )
                }
              />
            </div>
          </>
        ) : null}

        <div className="form-group">
          <label htmlFor="flagTecnico">Você é um técnico ? (obrigatório)</label>
          <select
            required
            id="flagTecnico"
            className="form-control"
            value={iag.flagTecnico}
            onChange={(event) =>
              changeIagField("flagTecnico", event.target.value)
            }
          >
            <option value={true}>Sim</option>
            <option value={false}>Não</option>
          </select>
        </div>

        {iag.flagTecnico ? (
          <>
            <div className="form-group">
              <label htmlFor="categoriaTecnico">
                Categorias Técnica (obrigatório)
              </label>
              <Select
                id="categoriaTecnico"
                required
                placeholder="Selecione..."
                noOptionsMessage={() => "Não encontrado"}
                isMulti={true}
                value={iag.categoriaTecnico
                  .filter((cc) => !cc.excluir)
                  .map((cc) => ({
                    value: cc.id,
                    label: cc.nome,
                  }))}
                onChange={(value) => changeTechnicianCategories(value)}
                options={categoriaTecnico.map((cc) => ({
                  value: cc.id,
                  label: cc.nome,
                }))}
                aria-describedby="categoriaTecnico-help-block"
              />
              <span
                id="categoriaTecnico-help-block"
                className="help-block"
              ></span>
            </div>
          </>
        ) : null}

        <div className="form-group">
          <label htmlFor="pessoaFisica.nomeMae">
            Nome da mãe (obrigatório)
          </label>
          <input
            required
            id="pessoaFisica.nomeMae"
            type="text"
            className="form-control"
            value={iag.pessoaFisica.nomeMae}
            onChange={(event) =>
              changeIagPessoaFisicaField("nomeMae", event.target.value)
            }
          />
        </div>

        <div className="form-group">
          <label htmlFor="pessoaFisica.cpf">CPF (obrigatório)</label>
          <input
            required
            id="pessoaFisica.cpf"
            type="number"
            className="form-control"
            value={iag.pessoaFisica.cpf}
            onChange={(event) =>
              changeIagPessoaFisicaField("cpf", event.target.value)
            }
          />
        </div>

        <div className="form-group">
          <label htmlFor="pessoaFisica.comprovanteCpf">
            Comprovante de CPF (obrigatório)
          </label>
          <input
            required
            id="pessoaFisica.comprovanteCpf"
            type="file"
            accept="image/png, image/jpeg, image/jpg, image/jpeg, application/pdf"
            className="form-control"
            onChange={(event) =>
              changeIagPessoaFisicaField(
                "comprovanteCpf",
                event.target.files[0]
              )
            }
          />
        </div>

        <div className="form-group">
          <label htmlFor="pessoaFisica.rg">RG (obrigatório)</label>
          <input
            required
            id="pessoaFisica.rg"
            type="number"
            className="form-control"
            value={iag.pessoaFisica.rg}
            onChange={(event) =>
              changeIagPessoaFisicaField("rg", event.target.value)
            }
          />
        </div>

        <div className="form-group">
          <label htmlFor="pessoaFisica.comprovanteRg">
            Comprovante de Rg (obrigatório)
          </label>
          <input
            required
            id="pessoaFisica.comprovanteRg"
            type="file"
            accept="image/png, image/jpeg, image/jpg, image/jpeg, application/pdf"
            className="form-control"
            onChange={(event) =>
              changeIagPessoaFisicaField("comprovanteRg", event.target.files[0])
            }
          />
        </div>

        <div className="form-group">
          <label htmlFor="pessoaFisica.orgaoExpedidorRg">
            Orgão Expeditor (obrigatório)
          </label>
          <input
            required
            id="pessoaFisica.orgaoExpedidorRg"
            type="text"
            className="form-control"
            value={iag.pessoaFisica.orgaoExpedidorRg}
            onChange={(event) =>
              changeIagPessoaFisicaField("orgaoExpedidorRg", event.target.value)
            }
          />
        </div>

        <div className="form-group">
          <label htmlFor="pessoaFisica.pisPasepNit">PIS Pasep / Nit</label>
          <input
            id="pessoaFisica.pisPasepNit"
            type="number"
            className="form-control"
            value={iag.pessoaFisica.pisPasepNit}
            onChange={(event) =>
              changeIagPessoaFisicaField("pisPasepNit", event.target.value)
            }
          />
        </div>

        <div className="form-group">
          <label htmlFor="dataNascimento">
            Data de Nscimento (obrigatório)
          </label>
          <input
            required
            id="dataNascimento"
            type="date"
            className="form-control"
            value={iag.pessoaFisica.dataNascimento}
            onChange={(event) =>
              changeIagPessoaFisicaField("dataNascimento", event.target.value)
            }
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">E-mail (obrigatório)</label>
          <input
            required
            id="email"
            type="email"
            className="form-control"
            value={iag.email}
            onChange={(event) => changeIagField("email", event.target.value)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="telefone">Telefone (obrigatório)</label>
          <input
            required
            id="telefone"
            type="number"
            className="form-control"
            value={iag.telefone}
            onChange={(event) => changeIagField("telefone", event.target.value)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="pessoaResponsavel.nome">Nome do Responsável</label>
          <input
            required
            id="pessoaResponsavel.nome"
            type="text"
            className="form-control"
            value={iag.pessoaResponsavel.nome}
            onChange={(event) =>
              changeIagPessoaResponsavelField("nome", event.target.value)
            }
          />
        </div>

        <div className="form-group">
          <label htmlFor="pessoaResponsavel.telefone">
            Telefone do Responsavel
          </label>
          <input
            required
            id="pessoaResponsavel.telefone"
            type="text"
            className="form-control"
            value={iag.pessoaResponsavel.telefone}
            onChange={(event) =>
              changeIagPessoaResponsavelField("telefone", event.target.value)
            }
          />
        </div>

        <div className="form-group">
          <label htmlFor="endereco.cep">CEP (obrigatório)</label>
          <input
            required
            id="endereco.cep"
            type="number"
            className="form-control"
            value={iag.endereco.cep}
            onChange={(event) =>
              changeIagEnderecoField("cep", event.target.value)
            }
          />
        </div>

        <div className="form-group">
          <label htmlFor="endereco.endereco">Endereço (obrigatório)</label>
          <input
            required
            id="endereco.endereco"
            type="text"
            className="form-control"
            value={iag.endereco.endereco}
            onChange={(event) =>
              changeIagEnderecoField("endereco", event.target.value)
            }
          />
        </div>

        <div className="form-group">
          <label htmlFor="endereco.numero">Numero (obrigatório)</label>
          <input
            required
            id="endereco.numero"
            type="number"
            className="form-control"
            value={iag.endereco.numero}
            onChange={(event) =>
              changeIagEnderecoField("numero", event.target.value)
            }
          />
        </div>

        <div className="form-group">
          <label htmlFor="endereco.bairro">Bairro (obrigatório)</label>
          <input
            required
            id="endereco.bairro"
            type="text"
            className="form-control"
            value={iag.endereco.bairro}
            onChange={(event) =>
              changeIagEnderecoField("bairro", event.target.value)
            }
          />
        </div>

        <div className="form-group">
          <label htmlFor="endereco.cidade">Cidade (obrigatório)</label>
          <input
            required
            id="endereco.cidade"
            type="text"
            className="form-control"
            value={iag.endereco.cidade}
            onChange={(event) =>
              changeIagEnderecoField("cidade", event.target.value)
            }
          />
        </div>

        <div className="form-group">
          <label htmlFor="endereco.complemento">
            Complemento (obrigatório)
          </label>
          <input
            required
            id="endereco.complemento"
            type="text"
            className="form-control"
            value={iag.endereco.complemento}
            onChange={(event) =>
              changeIagEnderecoField("complemento", event.target.value)
            }
          />
        </div>

        <div className="form-group">
          <label htmlFor="endereco.comprovanteResidencia">
            Comprovante de Residência (obrigatório)
          </label>
          <input
            required
            id="endereco.comprovanteResidencia"
            type="file"
            accept="image/png, image/jpeg, image/jpg, image/jpeg, application/pdf"
            className="form-control"
            onChange={(event) =>
              changeIagEnderecoField(
                "comprovanteResidencia",
                event.target.files[0]
              )
            }
          />
        </div>

        <div className="form-group">
          <label htmlFor="flagPessoaJuridica">
            Pessoa Jurídica (obrigatório)
          </label>
          <select
            required
            id="flagPessoaJuridica"
            className="form-control"
            value={iag.flagPessoaJuridica}
            onChange={(event) =>
              changeIagField("flagPessoaJuridica", event.target.value)
            }
          >
            <option value={true}>Sim</option>
            <option value={false}>Não</option>
          </select>
        </div>

        {iag.flagPessoaJuridica ? (
          <>
            <div className="form-group">
              <label htmlFor="pessoaJuridica.nomeFantasia">
                Nome Fantasia (obrigatório)
              </label>
              <input
                required
                id="pessoaJuridica.nomeFantasia"
                type="text"
                className="form-control"
                value={iag.pessoaJuridica.nomeFantasia}
                onChange={(event) =>
                  changeIagPessoaJuridicaField(
                    "nomeFantasia",
                    event.target.value
                  )
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="pessoaJuridica.razaoSocial">
                Razão Social (obrigatório)
              </label>
              <input
                required
                id="pessoaJuridica.razaoSocial"
                type="text"
                className="form-control"
                value={iag.pessoaJuridica.razaoSocial}
                onChange={(event) =>
                  changeIagPessoaJuridicaField(
                    "razaoSocial",
                    event.target.value
                  )
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="pessoaJuridica.funcao">Função</label>
              <input
                id="pessoaJuridica.funcao"
                type="text"
                className="form-control"
                value={iag.pessoaJuridica.funcao}
                onChange={(event) =>
                  changeIagPessoaJuridicaField("funcao", event.target.value)
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="pessoaJuridica.cnpj">CNPJ (obrigatório)</label>
              <input
                required
                id="pessoaJuridica.cnpj"
                type="number"
                className="form-control"
                value={iag.pessoaJuridica.cnpj}
                onChange={(event) =>
                  changeIagPessoaJuridicaField("cnpj", event.target.value)
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="pessoaJuridica.dataRegistro">
                Data de registro (obrigatório)
              </label>
              <input
                required
                id="pessoaJuridica.dataRegistro"
                type="date"
                className="form-control"
                value={iag.pessoaJuridica.dataRegistro}
                onChange={(event) =>
                  changeIagPessoaJuridicaField(
                    "dataRegistro",
                    event.target.value
                  )
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="pessoaJuridica.comprovanteCnpj">
                Comprovante de Cnpj (obrigatório)
              </label>
              <input
                required
                id="pessoaJuridica.comprovanteCnpj"
                type="file"
                accept="image/png, image/jpeg, image/jpg, image/jpeg, application/pdf"
                className="form-control"
                onChange={(event) =>
                  changeIagPessoaJuridicaField(
                    "comprovanteCnpj",
                    event.target.files[0]
                  )
                }
              />
            </div>
          </>
        ) : null}

        <div className="form-group">
          <label>Material Comprobatório</label>

          <br />

          <button
            type="button"
            className="btn btn-primary"
            onClick={() => adicionarComprovanteAtividade()}
          >
            Adicionar Comprovante
          </button>

          <br />
          <br />

          <ul className="list-group">
            {iag && iag.comprovanteAtividade
              ? iag.comprovanteAtividade.map((cp, idx) =>
                  cp.excluir != true ? (
                    <li className="list-group-item">
                      <div className="row">
                        <div className="col-md-10">
                          <div className="form-group">
                            <label htmlFor={`comprovante-${idx}`}>
                              Comprovante
                            </label>

                            <div>
                              {iag.comprovanteAtividade[idx].comprovante &&
                              typeof iag.comprovanteAtividade[idx]
                                .comprovante == "string" ? (
                                <>
                                  <button
                                    type="button"
                                    className="btn btn-xs btn-danger"
                                    onClick={() =>
                                      changeItemComprovanteAtividadeField(
                                        idx,
                                        "comprovante",
                                        null
                                      )
                                    }
                                  >
                                    Alterar
                                  </button>
                                  &nbsp;
                                  <a
                                    target="_blank"
                                    href={`${BASE_URL_API}/files/${iag.comprovanteAtividade[idx].comprovante}`}
                                    download="Arquivo"
                                    rel="noreferrer"
                                  >
                                    {cutWithReticence(
                                      iag.comprovanteAtividade[idx].comprovante,
                                      30,
                                      false
                                    )}
                                  </a>
                                </>
                              ) : (
                                <input
                                  required
                                  id={`comprovante-${idx}`}
                                  type="file"
                                  accept="image/png, image/jpeg, image/jpg, image/jpeg, application/pdf"
                                  className="form-control"
                                  onChange={(event) =>
                                    changeItemComprovanteAtividadeField(
                                      idx,
                                      "comprovante",
                                      event.target.files[0]
                                    )
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-2">
                          <button
                            type="button"
                            className="btn btn-danger"
                            style={{ marginTop: 33 }}
                            onClick={() => removerComprovanteAtividade(idx)}
                          >
                            Remover
                          </button>
                        </div>
                      </div>
                    </li>
                  ) : null
                )
              : null}
          </ul>
        </div>

        <div className="form-group">
          <label htmlFor="foto">Foto (obrigatório)</label>
          <input
            required
            id="foto"
            type="file"
            accept="image/png, image/jpeg, image/jpg, image/jpeg, application/pdf"
            className="form-control"
            onChange={(event) => changeIagField("foto", event.target.files[0])}
          />
        </div>

        <div className="form-group">
          <label>Contatos</label>

          <br />

          <button
            type="button"
            className="btn btn-primary"
            onClick={() => adicionarContatoPerfil()}
          >
            Adicionar Contato
          </button>

          <br />
          <br />

          <ul className="list-group">
            {iag.perfil && iag.perfil.contatoPerfil
              ? iag.perfil.contatoPerfil.map((cp, idx) =>
                  cp.excluir != true ? (
                    <li className="list-group-item">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="tipoContato">Tipo</label>
                            <select
                              required
                              id="tipoContato"
                              className="form-control"
                              value={iag.perfil.contatoPerfil[idx].tipoContato}
                              onChange={(event) =>
                                changeIagContatoPerfilField(
                                  idx,
                                  "tipoContato",
                                  event.target.value
                                )
                              }
                            >
                              <option value={"email"}>Email</option>
                              <option value={"telefone"}>Telefone</option>
                              <option value={"linkYoutube"}>
                                Link Youtube
                              </option>
                              <option value={"linkFacebook"}>
                                Link Facebook
                              </option>
                              <option value={"linkInstagram"}>
                                Link Instagram
                              </option>
                              <option value={"linkSite"}>Link Site</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-5">
                          <div className="form-group">
                            <label htmlFor="contato">Contato</label>
                            <input
                              id="contato"
                              type="text"
                              className="form-control"
                              value={iag.perfil.contatoPerfil[idx].contato}
                              onChange={(event) =>
                                changeIagContatoPerfilField(
                                  idx,
                                  "contato",
                                  event.target.value
                                )
                              }
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <button
                            type="button"
                            className="btn btn-danger"
                            style={{ marginTop: 33 }}
                            onClick={() => removerContatoPerfil(idx)}
                          >
                            Remover
                          </button>
                        </div>
                      </div>
                    </li>
                  ) : null
                )
              : null}
          </ul>
        </div>

        <div className="form-group">
          <label htmlFor="perfil.descricao">
            Breve texto da trajetória artistica / técnica (obrigatório)
          </label>
          <textarea
            required
            id="perfil.descricao"
            type="text"
            className="form-control"
            value={iag.perfil.descricao}
            onChange={(event) =>
              changeIagPerfilField("descricao", event.target.value)
            }
            style={{ height: 200 }}
          ></textarea>
        </div>

        <div className="form-group">
          <label htmlFor="contaBancaria.banco">Banco</label>
          <input
            id="contaBancaria.banco"
            type="text"
            className="form-control"
            value={iag.contaBancaria.banco}
            onChange={(event) =>
              changeIagContaBancariaField("banco", event.target.value)
            }
          />
        </div>

        <div className="form-group">
          <label htmlFor="contaBancaria.conta">Conta</label>
          <input
            id="contaBancaria.conta"
            type="text"
            className="form-control"
            value={iag.contaBancaria.conta}
            onChange={(event) =>
              changeIagContaBancariaField("conta", event.target.value)
            }
          />
        </div>

        <div className="form-group">
          <label htmlFor="contaBancaria.agencia">Agencia</label>
          <input
            id="contaBancaria.agencia"
            type="text"
            className="form-control"
            value={iag.contaBancaria.agencia}
            onChange={(event) =>
              changeIagContaBancariaField("agencia", event.target.value)
            }
          />
        </div>

        <div className="form-group">
          <label htmlFor="contaBancaria.chavePix">Chave Pix</label>
          <input
            id="contaBancaria.chavePix"
            type="text"
            className="form-control"
            value={iag.contaBancaria.chavePix}
            onChange={(event) =>
              changeIagContaBancariaField("chavePix", event.target.value)
            }
          />
        </div>

        <div className="form-group">
          <label htmlFor="usuario">Usuário (obrigatório)</label>
          <input
            required
            id="usuario"
            type="text"
            className="form-control"
            value={iag.usuario.usuario}
            onChange={(event) =>
              changeIagUserField("usuario", event.target.value)
            }
          />
        </div>

        <div className="form-group">
          <label htmlFor="senha">Senha (obrigatório)</label>
          <input
            required
            id="senha"
            type="senha"
            className="form-control"
            value={iag.usuario.senha}
            onChange={(event) =>
              changeIagUserField("senha", event.target.value)
            }
          />
        </div>

        <div className="form-group">
          <label htmlFor="confirmeSenha">Confirme a Senha (obrigatório)</label>
          <input
            required
            type="senha"
            id="confirmeSenha"
            className="form-control"
            value={iag.usuario.confirmeSenha}
            onChange={(event) =>
              changeIagUserField("confirmeSenha", event.target.value)
            }
          />
        </div>

        <div>
          <input
            required
            type="checkbox"
            id="flagRead"
            name="flagRead"
            checked={flagRead}
            onChange={(event) => setFlagRead(event.target.checked ? 1 : 0)}
          />
          <label htmlFor="flagRead" style={{ display: "initial" }}>
            {" Eu li e concordo com a "}
            <a
              target="_blank"
              href={`${process.env.PUBLIC_URL}/POLiTICA-DE-PRIVACIDADE-SETUDEC.pdf`}
              rel="noreferrer"
            >
              Política de Privacidade do SETUDEC
            </a>
            {"."}
          </label>
        </div>

        <br />

        <button
          type="submit"
          className="btn btn-block btn-success"
          disabled={!flagRead || processando}
        >
          Registrar
        </button>
      </form>
    ) : null;
  };

  return (
    <>
      <div style={{ padding: "7rem 0" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <img
                src="/images/logo.png"
                alt="logo"
                className="img-responsive"
                style={{ margin: "auto" }}
              />

              <div style={{ maxWidth: "600px", margin: "30px auto" }}>
                <ul className="nav nav-tabs text-center">
                  <li
                    role="presentation"
                    className={view == LOGIN_VIEW ? "active" : ""}
                  >
                    <Link to="/login">Login</Link>
                  </li>
                  <li
                    role="presentation"
                    className={view == REGISTER_VIEW ? "active" : ""}
                  >
                    <Link to="/registre-se">Registre-se</Link>
                  </li>
                </ul>

                <div
                  className="card"
                  style={{
                    border: "1px solid #ddd",
                    borderTop: 0,
                    padding: "15px",
                  }}
                >
                  <div className="card-body">
                    {errorMessageApi ? (
                      <div className="alert alert-danger" role="alert">
                        {errorMessageApi}
                      </div>
                    ) : null}

                    {view == LOGIN_VIEW ? renderLoginView() : null}
                    {view == REGISTER_VIEW ? renderRegisterView() : null}
                  </div>
                </div>

                <br />

                <div className="text-center">
                  <Link to="/">Voltar à Página inicial</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
