import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { Routes } from "./app";

const rootElement = document.getElementById("root");

render(
  <BrowserRouter>
    <Routes />
  </BrowserRouter>,
  rootElement
);
