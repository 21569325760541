import { useEffect, useState, createContext, useContext } from "react";
import { ApiService } from "../services/api";

export const ConfigSiteContext = createContext(null);

export const ConfigSiteContextProvider = ({ children }) => {
  const [configSite, setConfigSite] = useState(null);

  function handleConfigSite(config) {
    setConfigSite(config);
  }

  useEffect(() => {
    async function aFunction() {
      const config = await ApiService.get("/configuracao-site").then(
        ({ data }) => data
      );

      handleConfigSite(config);
    }

    aFunction();
  }, []);

  return (
    <ConfigSiteContext.Provider value={{ configSite, handleConfigSite }}>
      {children}
    </ConfigSiteContext.Provider>
  );
};

export const useConfigSite = () => {
  return useContext(ConfigSiteContext);
};
