import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ROUTES_PATHS } from "../routes";
import { ProductsAndArtistsService } from "../services/products-and-artists.service";
// import { SearchProducersAndArtistsForm } from "./search-producers-and-artists-form";
import CarteiraIagImage from "../assets/carteira-iag.png";
import { useConfigSite } from "../hooks/use-config-site";
import { ApiService, BASE_URL_API } from "../services/api";

export const HomePage = () => {
  const [items, setItems] = useState([]);
  const { configSite } = useConfigSite();
  const [edital,setEdital] = useState([]);

  const searchItems = () => {
    const service = new ProductsAndArtistsService();
    setItems(service.find({}, 6));
  };

  const loadItems = async () => {
    await ApiService.get("/editais").then(({ data }) => setEdital(data));
  };

  useEffect(() => {
    searchItems();
    loadItems();
  }, []);

  const renderList = items.length ? (
    items.map((item, key) => (
      <div key={key} className="col-md-4 col-sm-6 col-xs-12">
        <div className="course-item">
          <div className="image">
            <img
              src={item.image}
              aria-hidden
              alt={item.name}
              className="img-responsive"
            />
          </div>
          <div className="content">
            <h4>
              <Link
                to={ROUTES_PATHS.PRODUCER_ARTIST_DETAIL.replace(
                  /:id/i,
                  item.id
                )}
              >
                {item.name}
              </Link>
            </h4>

            <span>
              {item.registerType === "PRC" ? "Produtor Cultural" : "Artista"}
            </span>

            <p>{item.description}</p>

            <div className="culture-segments">
              <div>Segmento Cultural</div>
              <ul>
                {item?.cultureSegments.map((cultureSegment, key) => (
                  <li key={`cultureSegment-${key}`}>
                    <span>{cultureSegment}</span>
                  </li>
                ))}
              </ul>
            </div>

            <ul className="course-share">
              <li>
                <Link to="/">
                  <i className="fa fa-facebook" aria-hidden="true"></i>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <i className="fa fa-google-plus" aria-hidden="true"></i>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <i className="fa fa-dribbble" aria-hidden="true"></i>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <i className="fa fa-vimeo" aria-hidden="true"></i>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <i className="fa fa-rss" aria-hidden="true"></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    ))
  ) : (
    <div className="col-xs-12">
      <h3>Nenhum item encontrado.</h3>
    </div>
  );

  return (
    <>
      <section
        style={{
          backgroundImage: `url('${BASE_URL_API}/files/${configSite.imagemPaginaHome}')`,
        }}
        className="banner banner-three"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div
                className="banner-content text-center"
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  marginBottom: "30px",
                }}
              >
                <h3>Ainda não possui sua IAG?</h3>
                <Link to="/registre-se" className="button-default">
                  Registre-se aqui
                </Link>
              </div>

              <div
                className="banner-content text-center"
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  marginBottom: "30px",
                }}
              >
                <h3>
                  <img
                    src="/images/logo.png"
                    alt="logo"
                    className="img-responsive"
                    style={{ margin: "auto" }}
                  />
                </h3>
                <a
                  className="button-default"
                  target="_blank"
                  href={`${process.env.PUBLIC_URL}/projeto-de-lei.pdf`}
                >
                  PROJETO DE LEI N.º 013/2022
                </a>
              </div>

              {edital && edital.map((item)=>{
                if(item.show === true){
                  return (
                    <div
                    key={item.id}
                  className="banner-content text-center"
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    marginBottom: "30px",
                  }}
                >
                  <h3>{item.nome}</h3>
                  <a
                    className="button-default"
                    target="_blank"
                    href={`${BASE_URL_API}/files/${item.arquivo}`}
                  >
                    Leia aqui
                  </a>
                  <a
                    className="button-default"
                    target="_blank"
                    href={`${process.env.PUBLIC_URL}/login`}
                  >
                    Inscreva-se
                  </a>
                </div>
                  )
                }
              })}
            </div>
            <div className="col-md-6">
              <img
                src={CarteiraIagImage}
                className="img-responsive"
                style={{ borderRadius: "10px" }}
              />
              {/* <div className="banner-content">
                <h3>Buscar por...</h3>
                <SearchProducersAndArtistsForm />
              </div> */}
            </div>
          </div>
        </div>
      </section>

      {/* <section className="artists padding-120">
        <div className="container">
          <div className="section-header">
            <h3>Artistas Guaibenses</h3>
            <p>Encontre um de nossos artistas registrados e entre em contato</p>
          </div>
          <div className="row">{renderList}</div>

          <div className="courses-button">
            <Link
              to={ROUTES_PATHS.SEARCH_PRODUCERS_ARTISTS}
              className="button-default"
            >
              Ver Todos Artistas
            </Link>
          </div>
        </div>
      </section> */}

      {/* <section className="teachers section-bg padding-120">
        <div className="container">
          <div className="section-header">
            <h3>Clubes de vantagens</h3>
            <p>
              Encontre nossos Clubes de vantagens e seus benefícios ao artista
            </p>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="teacher-item">
                <div className="teacher-image">
                  <img
                    src="/images/teachers/teacher_01.jpg"
                    alt="teacher image"
                    aria-hidden
                    className="img-responsive"
                  />
                </div>
                <div className="teacher-content">
                  <h4>
                    <a href="teacher-profile.html">Thales Pães e Doces Ltda</a>
                  </h4>
                  <span>20% em qualquer produto</span>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="teacher-item">
                <div className="teacher-image">
                  <img
                    src="/images/teachers/teacher_02.jpg"
                    alt="teacher image"
                    aria-hidden
                    className="img-responsive"
                  />
                </div>
                <div className="teacher-content">
                  <h4>
                    <a href="teacher-profile.html">Sabrina viagens Ltda</a>
                  </h4>
                  <span>10% nas passagens</span>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="teacher-item">
                <div className="teacher-image">
                  <img
                    src="/images/teachers/teacher_03.jpg"
                    alt="teacher image"
                    aria-hidden
                    className="img-responsive"
                  />
                </div>
                <div className="teacher-content">
                  <h4>
                    <a href="teacher-profile.html">
                      Lavínia e Rafael Joalheria ME
                    </a>
                  </h4>
                  <span>15% em produtos de pintura</span>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center mt-2">
            <Link to="/" className="button-default">
              Ver Todos Clubes de vantagens
            </Link>
          </div>
        </div>
      </section> */}
    </>
  );
};
