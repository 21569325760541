import React, { useEffect, useState } from "react";
import { Routes as RrdRoutes, Route, Outlet } from "react-router-dom";
import { App } from "./app";
import { ClubsCategoriesAdminPage } from "./components/admin/clubs-categories-admin-page";
import { HomeAdminPage } from "./components/admin/home-admin-page";
import { PerksClubsAdminPage } from "./components/admin/perks-clubs-admin-page";
import { HomePage } from "./components/home-page";
import { NotFoundPage } from "./components/not-found-page";
import { ProducerAndArtistDetailPage } from "./components/producer-and-artist-detail-page";
import { SearchProducersAndArtistsPage } from "./components/search-producers-and-artists-page";
import { LoginPage, REGISTER_VIEW } from "./components/login-page";
import { SiteTemplate } from "./site-template";
import { AdminTemplate } from "./admin-template";
import { LoginTemplate } from "./login-template";
import { ArtistsCategoriesAdminPage } from "./components/admin/artists-categories-admin-page";
import { TechnicianCategoriesAdminPage } from "./components/admin/technician-categories-admin-page";
import { IagAdminPage } from "./components/admin/iag-admin-page";
import { SearchClubeDeVantagensPage } from "./components/search-clube-de-vantagens-page";
import { ClubeDeVantagensDetailPage } from "./components/clube-de-vantagens-detail-page";
import { EditalAdminPage } from "./components/admin/edital-admin-page";
import { InscricaoEditaisAdminPage } from "./components/admin/inscricao-editais-admin-page";
import { useAuth } from "./hooks/use-auth";
import { AdminIagTemplate } from "./admin-iag-template";
import { HomeAdminIagPage } from "./components/adminIag/home-admin-iag-page";
import { InscricaoEditaisAdminIagPage } from "./components/adminIag/inscricao-editais-admin-iag-page";
import { IagAdminIagPage } from "./components/adminIag/iag-admin-iag-page";
import { UsuarioAdminPage } from "./components/admin/usuario-admin-page";
import { ConfiguracaoSitePage } from "./components/admin/configuracao-site-page";

export const ROUTES_PATHS = {
  HOME: "/",
  SEARCH_PRODUCERS_ARTISTS: "/buscar-produtores-e-artistas",
  PRODUCER_ARTIST_DETAIL: "/detalhes-do-artista/:id",
  SEARCH_CLUBE_DE_VANTAGENS: "/buscar-clube-de-vantagens",
  CLUBE_DE_VANTAGENS_DETAIL: "/detalhes-do-clube-de-vantagens/:id",
  ADMIN_HOME: "/admin",
  ADMIN_CLUBS_CATEGORIES: "/admin/categorias-de-clubes",
  ADMIN_ARTISTS_CATEGORIES: "/admin/categorias-de-artistas",
  ADMIN_TECHNICIAN_CATEGORIES: "/admin/categorias-de-tecnicos",
  ADMIN_PERKS_CLUBS: "/admin/clubes-de-vantagens",
  ADMIN_IAGS: "/admin/iags",
  ADMIN_USUARIOS: "/admin/usuarios",
  ADMIN_EDITAIS: "/admin/editais",
  ADMIN_INSCRICOES_EM_EDITAIS: "/admin/inscricoes-em-editais",
  ADMIN_CONFIGURACAO_SITE: "/admin/configuracao-site",
  ADMIN_IAG_HOME: "/admin-iag",
  ADMIN_IAG_INSCRICOES_EM_EDITAIS: "/admin-iag/inscricoes-em-editais",
  ADMIN_IAG_IAG: "/admin-iag/iag",
};

const PrivateAdminRoute = () => {
  const [ok, setOk] = useState(false);
  const { verificaUsuarioLogadoAdmin } = useAuth();

  useEffect(() => {
    setOk(false);

    const asyncFunc = async () => {
      await verificaUsuarioLogadoAdmin();
      setOk(true);
    };

    asyncFunc();
  }, []);

  return ok ? <Outlet /> : null;
};

const PrivateAdminIagRoute = () => {
  const [ok, setOk] = useState(false);
  const { verificaUsuarioLogadoAdminIag } = useAuth();

  useEffect(() => {
    setOk(false);

    const asyncFunc = async () => {
      await verificaUsuarioLogadoAdminIag();
      setOk(true);
    };

    asyncFunc();
  }, []);

  return ok ? <Outlet /> : null;
};

export const Routes = () => (
  <RrdRoutes>
    <Route path="/" element={<App />}>
      <Route path="/login" element={<LoginTemplate />}>
        <Route element={<LoginPage />} index />
      </Route>

      <Route path="/registre-se" element={<LoginTemplate />}>
        <Route element={<LoginPage view={REGISTER_VIEW} />} index />
      </Route>

      <Route path="/admin" element={<AdminTemplate />}>
        <Route
          exact
          path="categorias-de-clubes"
          element={<PrivateAdminRoute />}
        >
          <Route index element={<ClubsCategoriesAdminPage />} />
        </Route>
        <Route
          exact
          path="categorias-de-artistas"
          element={<PrivateAdminRoute />}
        >
          <Route index element={<ArtistsCategoriesAdminPage />} />
        </Route>
        <Route
          exact
          path="categorias-de-tecnicos"
          element={<PrivateAdminRoute />}
        >
          <Route index element={<TechnicianCategoriesAdminPage />} />
        </Route>
        <Route exact path="clubes-de-vantagens" element={<PrivateAdminRoute />}>
          <Route index element={<PerksClubsAdminPage />} />
        </Route>
        <Route exact path="iags" element={<PrivateAdminRoute />}>
          <Route index element={<IagAdminPage />} />
        </Route>
        <Route exact path="usuarios" element={<PrivateAdminRoute />}>
          <Route index element={<UsuarioAdminPage />} />
        </Route>
        <Route exact path="editais" element={<PrivateAdminRoute />}>
          <Route index element={<EditalAdminPage />} />
        </Route>
        <Route
          exact
          path="inscricoes-em-editais"
          element={<PrivateAdminRoute />}
        >
          <Route index element={<InscricaoEditaisAdminPage />} />
        </Route>
        <Route exact path="configuracao-site" element={<PrivateAdminRoute />}>
          <Route index element={<ConfiguracaoSitePage />} />
        </Route>
        <Route exact element={<PrivateAdminRoute />}>
          <Route index element={<HomeAdminPage />} />
        </Route>
      </Route>

      <Route path="/admin-iag" element={<AdminIagTemplate />}>
        <Route exact path="iag" element={<PrivateAdminIagRoute />}>
          <Route index element={<IagAdminIagPage />} />
        </Route>
        <Route
          exact
          path="inscricoes-em-editais"
          element={<PrivateAdminIagRoute />}
        >
          <Route index element={<InscricaoEditaisAdminIagPage />} />
        </Route>
        <Route exact element={<PrivateAdminIagRoute />}>
          <Route index element={<HomeAdminIagPage />} />
        </Route>
      </Route>

      <Route path="/" element={<SiteTemplate />}>
        <Route element={<HomePage />} index />
        <Route
          element={<SearchProducersAndArtistsPage />}
          path="buscar-produtores-e-artistas"
        />
        <Route
          element={<ProducerAndArtistDetailPage />}
          path="detalhes-do-artista/:id"
        />
        <Route
          element={<SearchClubeDeVantagensPage />}
          path="buscar-clube-de-vantagens"
        />
        <Route
          element={<ClubeDeVantagensDetailPage />}
          path="detalhes-do-clube-de-vantagens/:id"
        />

        <Route element={<NotFoundPage />} path="*" />
      </Route>
    </Route>
  </RrdRoutes>
);
