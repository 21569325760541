import { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { ApiService, BASE_URL_API } from "../../services/api";
import { cutWithReticence } from "../../utils";
import { PageHeder } from "../page-header";
import camera from "../../assets/camera.svg";
import "./perks-clubs-admin-page.css";

const INITIAL_ITEM = {
  id: null,
  nome: "",
  descricao: "",
  foto: null,
  categoriaClubeDeVantagem: [],
  contatoClubeDeVantagem: [],
};

const LIST_ITEMS_VIEW = "LIST_ITEMS_VIEW";
const REGISTER_ITEM_VIEW = "REGISTER_ITEM_VIEW";
const DELETE_ITEM_VIEW = "DELETE_ITEM_VIEW";

export const PerksClubsAdminPage = () => {
  const [view, setView] = useState(LIST_ITEMS_VIEW);
  const [items, setItems] = useState([]);
  const [item, setItem] = useState(INITIAL_ITEM);
  const [errorMessageApi, setErrorMessageApi] = useState("");
  const [categoriaClubeDeVantagem, setClubCategories] = useState([]);

  const loadListView = () => {
    setErrorMessageApi("");
    setItem(INITIAL_ITEM);
    setView(LIST_ITEMS_VIEW);
    loadItems();
  };

  const loadRegisterItemView = (item) => {
    setErrorMessageApi("");
    setItem(item);
    setView(REGISTER_ITEM_VIEW);
  };

  const loadDeleteItemView = (item) => {
    setErrorMessageApi("");
    setItem(item);
    setView(DELETE_ITEM_VIEW);
  };

  const changeName = (nome) => {
    setItem({ ...item, nome });
  };

  const changeDescription = (descricao) => {
    setItem({ ...item, descricao });
  };

  const changePhoto = (foto) => {
    setItem({ ...item, foto });
  };

  const changeClubCategories = (categoriaClubeDeVantagemSelected) => {
    let cc = [...item.categoriaClubeDeVantagem];
    let ccNew = categoriaClubeDeVantagemSelected.map(({ value }) =>
      categoriaClubeDeVantagem.find((cc) => cc.id == value)
    );

    cc = cc.map((cco) => {
      if (!ccNew.find((ccn) => ccn.id == cco.id)) {
        cco.excluir = true;
      } else {
        delete cco.excluir;
      }

      return cco;
    });

    ccNew.filter((ccn) => {
      if (!cc.find((cco) => cco.id == ccn.id)) {
        cc.push(ccn);
      }
    });

    setItem({
      ...item,
      categoriaClubeDeVantagem: cc,
    });
  };

  const adicionarContatoClubeDeVantagem = () => {
    const contatoClubeDeVantagem = [
      ...item.contatoClubeDeVantagem,
      {
        id: null,
        tipoContato: "",
        contato: "",
        excluir: false,
      },
    ];

    const newItem = { ...item, contatoClubeDeVantagem };

    setItem(newItem);
  };

  const removerContatoClubeDeVantagem = (idx) => {
    item.contatoClubeDeVantagem[idx].excluir = true;

    const contatoClubeDeVantagem = [...item.contatoClubeDeVantagem];

    const newItem = { ...item, contatoClubeDeVantagem };

    setItem(newItem);
  };

  const changeContatoClubeDeVantagemField = (idx, field, value) => {
    item.contatoClubeDeVantagem[idx][field] = value;

    const contatoClubeDeVantagem = [...item.contatoClubeDeVantagem];

    const newItem = { ...item, contatoClubeDeVantagem };

    setItem(newItem);
  };

  const preview = useMemo(() => {
    if (item.foto) {
      if (typeof item.foto == "string") {
        return `${BASE_URL_API}/files/${item.foto}`;
      } else {
        return URL.createObjectURL(item.foto);
      }
    }

    return null;
  }, [item.foto]);

  const validateForm = async (item) => {
    let errorMessages = [];

    if (!item.nome) {
      const errorMessage = "Campo Nome inválido";
      window.$(`form .form-group:has(#nome)`).addClass("has-error");
      window.$(`form .form-group #nome-help-block`).text(errorMessage);

      errorMessages.push(errorMessage);
    } else {
      window.$(`form .form-group:has(#nome)`).removeClass("has-error");
      window.$(`form .form-group #nome-help-block`).text("");
    }

    if (!item.descricao) {
      const errorMessage = "Campo Descrição inválido";
      window.$(`form .form-group:has(#descricao)`).addClass("has-error");
      window.$(`form .form-group #descricao-help-block`).text(errorMessage);

      errorMessages.push(errorMessage);
    } else {
      window.$(`form .form-group:has(#descricao)`).removeClass("has-error");
      window.$(`form .form-group #descricao-help-block`).text("");
    }

    if (
      !item.categoriaClubeDeVantagem ||
      !item.categoriaClubeDeVantagem.length
    ) {
      const errorMessage = "Campo categorias inválido";
      window
        .$(`form .form-group:has(#categoriaClubeDeVantagem)`)
        .addClass("has-error");
      window
        .$(`form .form-group #categoriaClubeDeVantagem-help-block`)
        .text(errorMessage);

      errorMessages.push(errorMessage);
    } else {
      window
        .$(`form .form-group:has(#categoriaClubeDeVantagem)`)
        .removeClass("has-error");
      window
        .$(`form .form-group #categoriaClubeDeVantagem-help-block`)
        .text("");
    }

    if (!item.foto) {
      const errorMessage = "Campo foto inválido";
      window.$(`form .form-group:has(#foto)`).addClass("has-error");
      window.$(`form .form-group #foto-help-block`).text(errorMessage);

      errorMessages.push(errorMessage);
    } else {
      window.$(`form .form-group:has(#foto)`).removeClass("has-error");
      window.$(`form .form-group #foto-help-block`).text("");
    }

    return errorMessages.length ? errorMessages.join(";") : null;
  };

  const loadClubCategories = async () => {
    await ApiService.get("/categorias-clube-de-vantagem").then(({ data }) =>
      setClubCategories(data)
    );
  };

  const loadItems = async () => {
    await loadClubCategories();
    await ApiService.get("/clubes-de-vantagem").then(({ data }) =>
      setItems(data)
    );
  };

  const saveItem = async (item) => {
    setErrorMessageApi("");

    const errorMessage = await validateForm(item);
    if (errorMessage) {
      return;
    }

    const data = new FormData();

    data.append("nome", item.nome);
    data.append("descricao", item.descricao);
    data.append(
      "categoriaClubeDeVantagem",
      JSON.stringify(item.categoriaClubeDeVantagem)
    );
    data.append(
      "contatoClubeDeVantagem",
      JSON.stringify(item.contatoClubeDeVantagem)
    );
    data.append("foto", item.foto);

    try {
      if (!item.id) {
        await ApiService.post("/clubes-de-vantagem", data).then();

        toast.success("Cadastro efetuado com sucesso!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        await ApiService.post("/clubes-de-vantagem/" + item.id, data).then();

        toast.success("Edição efetuada com sucesso!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      loadListView();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.mensagem
      ) {
        setErrorMessageApi(error.response.data.mensagem);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
    }
  };

  const deleteItem = async (item) => {
    setErrorMessageApi("");

    try {
      await ApiService.delete("/clubes-de-vantagem/" + item.id).then();

      toast.success("Remoção efetuada com sucesso!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      loadListView();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.mensagem
      ) {
        setErrorMessageApi(error.response.data.mensagem);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
    }
  };

  const renderDeleteItemView = () => (
    <div className="row">
      <div className="col-md-12 text-center">
        {item ? (
          <>
            <p className="m-0">
              Você realmente deseja deletar o item "{item.nome}"?
            </p>
            <p className="m-0">Esta ação não poderá ser revertida.</p>
          </>
        ) : null}

        <br />

        <div>
          <button
            type="button"
            className="btn btn-default"
            onClick={() => loadListView()}
          >
            Cancelar
          </button>
          &nbsp;
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => deleteItem(item)}
          >
            Remover
          </button>
        </div>
      </div>
    </div>
  );

  const renderRegisterItemView = () => (
    <div className="row">
      <div className="col-md-12">
        {item ? (
          <form>
            {item.id ? (
              <div className="form-group">
                <label htmlFor="id"># ID</label>
                <input
                  type="text"
                  className="form-control"
                  id="id"
                  placeholder="ID"
                  value={item.id}
                  disabled
                />
              </div>
            ) : null}

            <div className="form-group">
              <label htmlFor="nome">Nome (obrigatório)</label>
              <input
                type="text"
                className="form-control"
                id="nome"
                placeholder="Nome"
                required
                value={item.nome}
                onChange={(event) => changeName(event.target.value)}
                aria-describedby="nome-help-block"
              />
              <span id="nome-help-block" className="help-block"></span>
            </div>

            <div className="form-group">
              <label htmlFor="categoriaClubeDeVantagem">
                Categorias (obrigatório)
              </label>
              <Select
                id="categoriaClubeDeVantagem"
                required
                placeholder="Selecione..."
                noOptionsMessage={() => "Não encontrado"}
                isMulti={true}
                value={item.categoriaClubeDeVantagem
                  .filter((cc) => !cc.excluir)
                  .map((cc) => ({
                    value: cc.id,
                    label: cc.nome,
                  }))}
                onChange={(value) => changeClubCategories(value)}
                options={categoriaClubeDeVantagem.map((cc) => ({
                  value: cc.id,
                  label: cc.nome,
                }))}
                aria-describedby="categoriaClubeDeVantagem-help-block"
              />
              <span
                id="categoriaClubeDeVantagem-help-block"
                className="help-block"
              ></span>
            </div>

            <div className="form-group">
              <label>Contatos</label>

              <br />

              <button
                type="button"
                className="btn btn-primary"
                onClick={() => adicionarContatoClubeDeVantagem()}
              >
                Adicionar Contato
              </button>

              <br />
              <br />

              <ul className="list-group">
                {item && item.contatoClubeDeVantagem
                  ? item.contatoClubeDeVantagem.map((cp, idx) =>
                      cp.excluir != true ? (
                        <li className="list-group-item">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="tipoContato">Tipo</label>
                                <select
                                  required
                                  id="tipoContato"
                                  className="form-control"
                                  value={
                                    item.contatoClubeDeVantagem[idx].tipoContato
                                  }
                                  onChange={(event) =>
                                    changeContatoClubeDeVantagemField(
                                      idx,
                                      "tipoContato",
                                      event.target.value
                                    )
                                  }
                                >
                                  <option value={"email"}>Email</option>
                                  <option value={"telefone"}>Telefone</option>
                                  <option value={"linkYoutube"}>
                                    Link Youtube
                                  </option>
                                  <option value={"linkFacebook"}>
                                    Link Facebook
                                  </option>
                                  <option value={"linkInstagram"}>
                                    Link Instagram
                                  </option>
                                  <option value={"linkSite"}>Link Site</option>
                                </select>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="contato">Contato</label>
                                <input
                                  id="contato"
                                  type="text"
                                  className="form-control"
                                  value={
                                    item.contatoClubeDeVantagem[idx].contato
                                  }
                                  onChange={(event) =>
                                    changeContatoClubeDeVantagemField(
                                      idx,
                                      "contato",
                                      event.target.value
                                    )
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-md-2">
                              <button
                                type="button"
                                className="btn btn-danger"
                                style={{ marginTop: 33 }}
                                onClick={() =>
                                  removerContatoClubeDeVantagem(idx)
                                }
                              >
                                Remover
                              </button>
                            </div>
                          </div>
                        </li>
                      ) : null
                    )
                  : null}
              </ul>
            </div>

            <div className="form-group">
              <label>Logo (obrigatório)</label>
              <label
                id="foto"
                style={{ backgroundImage: `url(${preview})` }}
                className={item.foto ? "has-thumbnail" : ""}
              >
                <input
                  type="file"
                  accept="image/png,image/jpeg,image/jpg"
                  onChange={(event) => changePhoto(event.target.files[0])}
                  aria-describedby="foto-help-block"
                />
                <img src={camera} alt="Select img" />
              </label>
              <span id="foto-help-block" className="help-block"></span>
            </div>

            <div className="form-group">
              <label htmlFor="descricao">Descrição (obrigatório)</label>
              <textarea
                style={{ height: "150px" }}
                type="text"
                className="form-control"
                id="descricao"
                placeholder="Descrição"
                required
                value={item.descricao}
                onChange={(event) => changeDescription(event.target.value)}
                aria-describedby="descricao-help-block"
              ></textarea>
              <span id="descricao-help-block" className="help-block"></span>
            </div>
          </form>
        ) : null}

        <div className="text-right">
          <button
            type="button"
            className="btn btn-default"
            onClick={() => loadListView()}
          >
            Cancelar
          </button>
          &nbsp;
          <button
            type="button"
            className="btn btn-success"
            onClick={() => saveItem(item)}
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
  );

  const renderItem = (item, index) => (
    <tr key={`item-${index}`}>
      <td>{item.id}</td>
      <td>{item.nome}</td>
      <td>{cutWithReticence(item.descricao)}</td>
      <td>
        {item.categoriaClubeDeVantagem && item.categoriaClubeDeVantagem.length
          ? item.categoriaClubeDeVantagem
              .map((clubCategoryItem) => {
                const clubCategory = categoriaClubeDeVantagem.find(
                  ({ id }) => clubCategoryItem.id == id
                );

                return clubCategory.nome;
              })
              .join(",")
          : "Sem categoria"}
      </td>
      <td>
        <button
          type="button"
          className="btn btn-warning"
          onClick={() => loadRegisterItemView(item)}
        >
          Editar
        </button>
        &nbsp;
        <button
          type="button"
          className="btn btn-danger"
          onClick={() => loadDeleteItemView(item)}
        >
          Remover
        </button>
      </td>
    </tr>
  );

  const renderListView = () => (
    <>
      <div className="row">
        <div className="col-md-12 text-right mb-30">
          <button
            type="button"
            className="btn btn-success"
            onClick={() => loadRegisterItemView(INITIAL_ITEM)}
          >
            Cadastrar
          </button>
        </div>
      </div>

      <div className="row">
        <table className="col-md-12">
          <thead>
            <tr>
              <th># ID</th>
              <th>Nome</th>
              <th>Descrição</th>
              <th>Categorias</th>
              <th style={{ width: "172px" }}>Opções</th>
            </tr>
          </thead>
          <tbody>
            {items && items.length ? (
              items.map(renderItem)
            ) : (
              <tr>
                <td className="text-center" colSpan={5}>
                  Nenhum registro encontrado
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );

  const renderErrorMessageApi = (errorMessageApi) => (
    <div className="alert alert-danger" role="alert">
      {errorMessageApi}
    </div>
  );

  useEffect(() => {
    loadListView();
  }, []);

  return (
    <>
      <PageHeder>Clubes de Vantagens</PageHeder>

      <section className="pt-30 pb-30">
        <div className="container">
          {errorMessageApi ? renderErrorMessageApi(errorMessageApi) : null}
          {view === LIST_ITEMS_VIEW ? renderListView() : null}
          {view === REGISTER_ITEM_VIEW ? renderRegisterItemView() : null}
          {view === DELETE_ITEM_VIEW ? renderDeleteItemView() : null}
        </div>
      </section>
    </>
  );
};
