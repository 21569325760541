export const cutWithReticence = (
  str,
  maxWidth = 100,
  addReticenceAtTheEnd = true
) => {
  let strSliced = String(str);

  if (strSliced && strSliced.length > maxWidth) {
    if (addReticenceAtTheEnd) {
      return `${strSliced.slice(0, maxWidth)}...`;
    } else {
      return `...${strSliced.slice(str.length - maxWidth, str.length)}`;
    }
  } else {
    return strSliced;
  }
};
