import { Link } from "react-router-dom";
import { PageHeder } from "./page-header";

export const NotFoundPage = () => {
  return (
    <>
      <PageHeder>Página não encontrada</PageHeder>

      <section className="error-page padding-120">
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <div className="error-content">
                <h2>404</h2>
                <h3>Ops, Esta página não foi encontrada!</h3>
                <p>
                  Sentimos muito, mas a página que você solicitou está faltando
                  :(
                </p>
                <Link to="/" className="button-default">
                  Volto para a página inicial
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
