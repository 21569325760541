import { useConfigSite } from "../hooks/use-config-site";
import { BASE_URL_API } from "../services/api";

export const PageHeder = ({ children, isAdminIag = false }) => {
  const { configSite } = useConfigSite();

  return (
    <section
      style={{
        backgroundImage: `url('${BASE_URL_API}/files/${configSite.imagemCabecalhoOutrasPaginas}')`,
      }}
      className={`page-header ${isAdminIag ? "page-header-admin-iag" : ""}`}
    >
      <div className="overlay">
        <div className="container">
          <h3>{children}</h3>
        </div>
      </div>
    </section>
  );
};
