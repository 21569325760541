import { useConfigSite } from "../hooks/use-config-site";
import gifCarregando from "../assets/loading2.gif";

export const Preloader = ({ children }) => {
  const { configSite } = useConfigSite();

  if (!configSite) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          position: "relative",
          display: "block",
        }}
      >
        <img
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          src={gifCarregando}
          alt="imagem de carregamento"
        />
      </div>
    );
  }

  return children;
};
