import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../hooks/use-query";
import { ROUTES_PATHS } from "../routes";
import { ApiService } from "../services/api";

const INITIAL_STATE = {
  tipo: "",
  categoriaTecnico: "",
  categoriaArtista: "",
  nome: "",
  endereco_cidade: "",
  endereco_bairro: "",
};

export const SearchProducersAndArtistsForm = ({
  initialState = INITIAL_STATE,
}) => {
  const query = useQuery();
  const navigate = useNavigate();
  const [item, setItem] = useState(initialState);
  const [categoriaArtista, setArtistCategories] = useState([]);
  const [categoriaTecnico, setTechnicianCategories] = useState([]);

  const urlBusca = useMemo(() => {
    let url = ROUTES_PATHS.SEARCH_PRODUCERS_ARTISTS;
    let querySearch = {};

    if (item.tipo !== "") {
      querySearch.tipo = item.tipo;
    }

    if (item.categoriaTecnico !== "") {
      querySearch.categoriaTecnico = item.categoriaTecnico;
    }

    if (item.categoriaArtista !== "") {
      querySearch.categoriaArtista = item.categoriaArtista;
    }

    if (item.nome !== "") {
      querySearch.nome = item.nome;
    }

    if (item.endereco_cidade !== "") {
      querySearch.endereco_cidade = item.endereco_cidade;
    }

    if (item.endereco_bairro !== "") {
      querySearch.endereco_bairro = item.endereco_bairro;
    }

    return `${url}?${new URLSearchParams(querySearch).toString()}`;
  }, [item]);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      navigate(urlBusca);
    },
    [urlBusca]
  );

  const changeItemField = (field, value) => {
    const newItem = { ...item, [field]: value };

    setItem(newItem);
  };

  const loadArtistCategories = async () => {
    await ApiService.get("/categorias-artista").then(({ data }) =>
      setArtistCategories(data)
    );
  };

  const loadTechnicianCategories = async () => {
    await ApiService.get("/categorias-tecnico").then(({ data }) =>
      setTechnicianCategories(data)
    );
  };

  const loadItems = async () => {
    loadArtistCategories();
    loadTechnicianCategories();
  };

  useEffect(() => {
    const newItem = { ...item };

    newItem.tipo = initialState.tipo || INITIAL_STATE.tipo;

    newItem.categoriaTecnico =
      initialState.categoriaTecnico || INITIAL_STATE.categoriaTecnico;

    newItem.categoriaArtista =
      initialState.categoriaArtista || INITIAL_STATE.categoriaArtista;

    newItem.nome = initialState.nome || INITIAL_STATE.nome;

    newItem.endereco_cidade =
      initialState.endereco_cidade || INITIAL_STATE.endereco_cidade;

    newItem.endereco_bairro =
      initialState.endereco_bairro || INITIAL_STATE.endereco_bairro;

    setItem(newItem);
  }, [initialState]);

  useEffect(() => {
    loadItems();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <select
        name="tipo"
        value={item.tipo}
        onChange={(event) => {
          let newItem = { ...item };
          newItem.categoriaArtista = "";
          newItem.categoriaTecnico = "";
          newItem.tipo = event.target.value;
          setItem(newItem);
        }}
      >
        <option value="">Tipo</option>
        <option value="TEC">Técnico</option>
        <option value="ART">Artista</option>
      </select>

      {item.tipo === "TEC" ? (
        <select
          name="categoriaTecnico"
          value={item.categoriaTecnico}
          onChange={(event) =>
            changeItemField("categoriaTecnico", event.target.value)
          }
        >
          <option value={""}>Categoria...</option>
          {categoriaTecnico?.map((cat) => (
            <option key={`categoriaTecnico-${cat.id}`} value={cat.id}>{cat.nome}</option>
          ))}
        </select>
      ) : item.tipo === "ART" ? (
        <select
          name="categoriaArtista"
          value={item.categoriaArtista}
          onChange={(event) =>
            changeItemField("categoriaArtista", event.target.value)
          }
        >
          <option value={""}>Categoria...</option>
          {categoriaArtista?.map((cat) => (
            <option key={`categoriaArtista-${cat.id}`} value={cat.id}>{cat.nome}</option>
          ))}
        </select>
      ) : (
        <select disabled>
          <option value={""}>Categoria...</option>
        </select>
      )}

      <input
        type="text"
        name="nome"
        value={item.nome}
        onChange={(event) => changeItemField("nome", event.target.value)}
        placeholder="Nome"
      />

      <input
        type="text"
        name="endereco_cidade"
        value={item.endereco_cidade}
        onChange={(event) =>
          changeItemField("endereco_cidade", event.target.value)
        }
        placeholder="Cidade"
      />

      <input
        type="text"
        name="endereco_bairro"
        value={item.endereco_bairro}
        onChange={(event) =>
          changeItemField("endereco_bairro", event.target.value)
        }
        placeholder="Bairro"
      />

      <button>Buscar</button>
    </form>
  );
};
