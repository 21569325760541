import registers from "../data/products-and-artists.json";

export class ProductsAndArtistsService {
  findById(id) {
    let results = [...registers];

    results = results.filter((register) => register.id == id);

    return results;
  }

  find(
    {
      name = "",
      city = "",
      district = "",
      registerType = "",
      cultureSegment = "",
    },
    limit = 100
  ) {
    let results = [...registers];

    if (name !== "") {
      results = results.filter((register) =>
        String(register.name).toUpperCase().includes(String(name).toUpperCase())
      );
    }

    if (city !== "") {
      results = results.filter((register) =>
        String(register.city).toUpperCase().includes(String(city).toUpperCase())
      );
    }

    if (district !== "") {
      results = results.filter((register) =>
        String(register.district)
          .toUpperCase()
          .includes(String(district).toUpperCase())
      );
    }

    if (registerType !== "") {
      results = results.filter((register) =>
        String(register.registerType)
          .toUpperCase()
          .includes(String(registerType).toUpperCase())
      );
    }

    if (cultureSegment !== "") {
      results = results.filter((register) => {
        return register.cultureSegments
          .map((cultureSegment) => String(cultureSegment).toUpperCase())
          .includes(String(cultureSegment).toUpperCase());
      });
    }

    return results.slice(0, limit);
  }
}
