import { useNavigate } from "react-router-dom";
import { ApiService } from "../services/api";
import { toast } from "react-toastify";
import { ROUTES_PATHS } from "../routes";

export const useAuth = () => {
  const navigate = useNavigate();

  const login = async (usuario) => {
    const { flagAdmin } = await ApiService.post("/auth/login", usuario).then(
      ({ data }) => {
        localStorage.setItem("token", data.token);
        localStorage.setItem("idIag", data.idIag);
        localStorage.setItem("flagAdmin", data.flagAdmin);
        return data;
      }
    );

    if (flagAdmin) {
      navigate(ROUTES_PATHS.ADMIN_HOME);
    } else {
      navigate(ROUTES_PATHS.ADMIN_IAG_HOME);
    }
  };

  const logoff = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("idIag");
    localStorage.removeItem("flagAdmin");

    navigate("/login");
  };

  const verificaUsuarioLogadoAdmin = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      return logoff();
    } else {
      try {
        const { flagAdmin } = await ApiService.get("/auth/refresh-token", {
          headers: {
            "x-access-token": token,
          },
        }).then(({ data }) => {
          localStorage.setItem("token", data.token);
          localStorage.setItem("idIag", data.idIag);
          localStorage.setItem("flagAdmin", data.flagAdmin);
          return data;
        });

        if (!flagAdmin) {
          toast.error("Usuario não autenticado!");
          return logoff();
        }
      } catch (error) {
        toast.error("Usuario não autenticado!");
        return logoff();
      }
    }
  };

  const verificaUsuarioLogadoAdminIag = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      return logoff();
    } else {
      try {
        const { flagAdmin } = await ApiService.get("/auth/refresh-token", {
          headers: {
            "x-access-token": token,
          },
        }).then(({ data }) => {
          localStorage.setItem("token", data.token);
          localStorage.setItem("idIag", data.idIag);
          localStorage.setItem("flagAdmin", data.flagAdmin);
          return data;
        });

        if (flagAdmin) {
          toast.error("Usuario não autenticado!");
          return logoff();
        }
      } catch (error) {
        toast.error("Usuario não autenticado!");
        return logoff();
      }
    }
  };

  return {
    login,
    logoff,
    verificaUsuarioLogadoAdmin,
    verificaUsuarioLogadoAdminIag,
  };
};
