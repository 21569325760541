import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./app.css";
import { appendScript } from "./utils";
import { ConfigSiteContextProvider } from "./hooks/use-config-site";
import { Preloader } from "./components/preloader";

export const App = () => {
  useEffect(() => {
    const asyncFunction = async () => {
      //  jquery
      await appendScript("/assets/js/jquery-1.12.4.min.js");

      //  Bootstrap
      await appendScript("/assets/js/bootstrap.min.js");

      //  counterup
      await appendScript(
        "http://cdnjs.cloudflare.com/ajax/libs/waypoints/2.0.3/waypoints.min.js"
      );
      await appendScript("/assets/js/jquery.counterup.min.js");

      //  Isotope
      await appendScript("/assets/js/isotope.min.js");

      //  lightcase
      await appendScript("/assets/js/lightcase.js");

      //  Swiper
      await appendScript("/assets/js/swiper.jquery.min.js");

      // progres
      await appendScript("/assets/js/circle-progress.min.js");

      // velocit
      await appendScript("/assets/js/velocity.min.js");

      // quick-vie
      await appendScript("/assets/js/quick-view.js");

      // nstSlide
      await appendScript("/assets/js/jquery.nstSlider.js");

      // flexslide
      await appendScript("/assets/js/flexslider-min.js");

      // easin
      await appendScript("/assets/js/jquery.easing.min.js");

      //  custom
      await appendScript("/assets/js/custom.js");
    };

    asyncFunction();
  }, []);

  return (
    <ConfigSiteContextProvider>
      <Preloader>
        <div>
          <ToastContainer />

          <Outlet />
        </div>
      </Preloader>
    </ConfigSiteContextProvider>
  );
};
