import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiService, BASE_URL_API } from "../services/api";
import { Galeria } from "./gallery";
import { PageHeder } from "./page-header";

export const ProducerAndArtistDetailPage = () => {
  const { id } = useParams();
  const [item, setItem] = useState(null);

  const searchItem = async (id) => {
    await ApiService.get(`/iags/${id}`).then(({ data }) => setItem(data));
  };

  useEffect(() => {
    if (id) {
      searchItem(id);
    }
  }, [id]);

  return item ? (
    <>
      <PageHeder>IAG</PageHeder>
      <section className="teacher-details padding-120">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="teacher-image">
                <img
                  src={`${BASE_URL_API}/files/${item.foto}`}
                  aria-hidden
                  alt={item.nome}
                  className="img-responsive"
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="teacher-content">
                <h4>{item.nome}</h4>

                {item.flagTecnico && item.flagArtista ? (
                  <span>Técnico e Artista</span>
                ) : item.flagTecnico ? (
                  <span>Técnico</span>
                ) : item.flagArtista ? (
                  <span>Artista</span>
                ) : null}

                <p style={{ whiteSpace: "pre-wrap" }}>
                  {item?.perfil?.descricao}
                </p>

                <ul className="social-default">
                  {item?.perfil?.contatoPerfil?.map((cp) => {
                    if (cp.tipoContato == "email") {
                      return (
                        <l1>
                          <a
                            href={`mailto:${cp.contato}`}
                            alt={cp.contato}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {cp.contato}
                          </a>
                        </l1>
                      );
                    }
                    if (cp.tipoContato == "telefone") {
                      return (
                        <l1>
                          <a
                            href={`http://wa.me/55${cp.contato}`}
                            alt={cp.contato}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i
                              className="fa fa-whatsapp"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </l1>
                      );
                    }
                    if (cp.tipoContato == "linkYoutube") {
                      return (
                        <l1>
                          <a
                            href={cp.contato}
                            alt={cp.contato}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-youtube" aria-hidden="true"></i>
                          </a>
                        </l1>
                      );
                    }
                    if (cp.tipoContato == "linkFacebook") {
                      return (
                        <l1>
                          <a
                            href={cp.contato}
                            alt={cp.contato}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i
                              className="fa fa-facebook"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </l1>
                      );
                    }
                    if (cp.tipoContato == "linkInstagram") {
                      return (
                        <l1>
                          <a
                            href={cp.contato}
                            alt={cp.contato}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i
                              className="fa fa-instagram"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </l1>
                      );
                    }
                    if (cp.tipoContato == "linkSite") {
                      return (
                        <l1>
                          <a href={cp.contato} target="_blank" rel="noreferrer">
                            <i className="fa fa-globe" aria-hidden="true"></i>
                          </a>
                        </l1>
                      );
                    }
                  })}
                </ul>

                {item?.categoriaArtista && item?.categoriaArtista.length ? (
                  <div className="culture-segments">
                    <div>Categorias do Artista</div>
                    <p>
                      {item?.categoriaArtista
                        ?.map((categoria, key) => categoria.nome)
                        .join(", ")}
                    </p>
                  </div>
                ) : null}

                {item?.categoriaTecnico && item?.categoriaTecnico.length ? (
                  <div className="culture-segments">
                    <div>Categorias do Técnico</div>
                    <p>
                      {item?.categoriaTecnico
                        ?.map((categoria, key) => categoria.nome)
                        .join(", ")}
                    </p>
                  </div>
                ) : null}

                <ul className="teacher-address">
                  <li>
                    <span>
                      <i className="fa fa-home" aria-hidden="true"></i>
                    </span>
                    {item?.endereco?.bairro + ", " + item?.endereco?.cidade}
                  </li>
                  <li>
                    <span>
                      <i className="fa fa-envelope-o" aria-hidden="true"></i>
                    </span>
                    {item.email}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {item && item.imagemGaleriaIag && item.imagemGaleriaIag.length ? (
            <div className="teacher-statement">
              <h4 className="text-center">Galeria</h4>
              <Galeria
                images={item.imagemGaleriaIag.map(
                  ({ imagem }) => `${BASE_URL_API}/files/${imagem}`
                )}
              />
            </div>
          ) : null}
        </div>
      </section>
    </>
  ) : (
    <>
      <PageHeder>Detalhes do Artista</PageHeder>
      <h4 className="text-center padding-120">Artista não encontrado</h4>
    </>
  );
};
