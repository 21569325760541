import { Outlet } from "react-router-dom";
import { Footer } from "./components/footer";
import { Header } from "./components/header";
import { ScrollToTop } from "./components/scroll-to-top";
import { ScrollTopButton } from "./components/scroll-top-button";

export const AdminIagTemplate = () => {
  return (
    <>
      <ScrollToTop />

      <Header isAdminIag={true} />

      <Outlet />

      <Footer isAdminIag={true} />

      <ScrollTopButton />
    </>
  );
};
