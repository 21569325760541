import { Link } from "react-router-dom";
import { useAuth } from "../hooks/use-auth";
import { ROUTES_PATHS } from "../routes";

export const Header = ({ isAdmin = false, isAdminIag = false }) => {
  const { logoff } = useAuth();

  const handleLogoff = (event) => {
    event.preventDefault();
    logoff();
  };

  const renderAdminMenu = () => (
    <>
      <li className="dropdown">
        <a
          className="dropdown-toggle"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Cadastros <span className="caret"></span>
        </a>
        <ul className="dropdown-menu">
          <li>
            <Link to={`${ROUTES_PATHS.ADMIN_USUARIOS}`}>Usuarios</Link>
          </li>
          <li>
            <Link to={`${ROUTES_PATHS.ADMIN_IAGS}`}>IAGs</Link>
          </li>
          <li>
            <Link to={`${ROUTES_PATHS.ADMIN_EDITAIS}`}>Editais</Link>
          </li>
          <li>
            <Link to={`${ROUTES_PATHS.ADMIN_INSCRICOES_EM_EDITAIS}`}>
              Inscrições em Editais
            </Link>
          </li>
          <li>
            <Link to={`${ROUTES_PATHS.ADMIN_PERKS_CLUBS}`}>
              Clube de Vantagens
            </Link>
          </li>
          <li>
            <Link to={`${ROUTES_PATHS.ADMIN_CLUBS_CATEGORIES}`}>
              Categorias de Clube de Vantagens
            </Link>
          </li>
          <li>
            <Link to={`${ROUTES_PATHS.ADMIN_ARTISTS_CATEGORIES}`}>
              Categorias de Artistas
            </Link>
          </li>
          <li>
            <Link to={`${ROUTES_PATHS.ADMIN_TECHNICIAN_CATEGORIES}`}>
              Categorias de Técnicos
            </Link>
          </li>
        </ul>
      </li>
      <li>
        <a to={ROUTES_PATHS.HOME} onClick={handleLogoff}>
          Sair
        </a>
      </li>
    </>
  );

  const renderAdminIagMenu = () => (
    <>
      <li className="dropdown">
        <a
          className="dropdown-toggle"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Configurações <span className="caret"></span>
        </a>
        <ul className="dropdown-menu">
          <li>
            <Link to={`${ROUTES_PATHS.ADMIN_IAG_IAG}`}>IAG</Link>
          </li>
          <li>
            <Link to={`${ROUTES_PATHS.ADMIN_IAG_INSCRICOES_EM_EDITAIS}`}>
              Inscrições em Editais
            </Link>
          </li>
        </ul>
      </li>

      <li>
        <a to={ROUTES_PATHS.HOME} onClick={handleLogoff}>
          Sair
        </a>
      </li>
    </>
  );

  const renderMenu = () => (
    <>
      <li>
        <Link to={ROUTES_PATHS.HOME}>Início</Link>
      </li>
      <li className="dropdown">
        <Link
          to={`${ROUTES_PATHS.SEARCH_PRODUCERS_ARTISTS}`}
          className="dropdown-toggle"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Artistas Guaibenses <span className="caret"></span>
        </Link>
        <ul className="dropdown-menu">
          <li>
            <Link
              to={`${ROUTES_PATHS.SEARCH_PRODUCERS_ARTISTS}?tipo=TEC`}
            >
              Técnicos
            </Link>
          </li>
          <li>
            <Link
              to={`${ROUTES_PATHS.SEARCH_PRODUCERS_ARTISTS}?tipo=ART`}
            >
              Artistas
            </Link>
          </li>
        </ul>
      </li>
      <li>
        <Link to={`${ROUTES_PATHS.SEARCH_CLUBE_DE_VANTAGENS}`}>
          Clubes de Vantagens
        </Link>
      </li>
    </>
  );

  return (
    <header className="header-three">
      {!isAdmin && !isAdminIag ? (
        <div className="header-top">
          <div className="container">
            <ul className="left"></ul>
            <ul className="right">
              <li>
                <Link to="/registre-se">
                  <i className="fa fa-plus-circle" aria-hidden="true"></i>
                  &nbsp;
                  <span>Registre-se</span>
                </Link>
              </li>
              <li>|</li>
              <li>
                <Link to="/login">
                  <i className="fa fa-lock" aria-hidden="true"></i>
                  &nbsp;
                  <span>Login</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <div className="header-top">
          <div className="container">
            <ul className="left"></ul>
          </div>
        </div>
      )}

      <div className="main-menu">
        <nav className="navbar">
          <div className="container">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#bs-example-navbar-collapse-1"
                aria-expanded="false"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <Link
                className="navbar-brand"
                to={
                  isAdmin
                    ? ROUTES_PATHS.ADMIN_HOME
                    : isAdminIag
                    ? ROUTES_PATHS.ADMIN_IAG_HOME
                    : ROUTES_PATHS.HOME
                }
                style={{
                  display: "inline-block",
                  height: "100%",
                  verticalAlign: "middle",
                }}
              >
                <img
                  style={{ verticalAlign: "middle" }}
                  src="/images/logo.png"
                  alt="logo"
                  className="img-responsive"
                />
              </Link>
            </div>

            <div
              className="collapse navbar-collapse"
              id="bs-example-navbar-collapse-1"
            >
              <ul className="nav navbar-nav navbar-right">
                {isAdmin ? renderAdminMenu() : null}
                {isAdminIag ? renderAdminIagMenu() : null}
                {!isAdmin && !isAdminIag ? renderMenu() : null}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};
