import { Link } from "react-router-dom";
import { ROUTES_PATHS } from "../../routes";
import { PageHeder } from "../page-header";

export const HomeAdminIagPage = () => {
  return (
    <>
      <PageHeder isAdminIag>Painel do Usuário</PageHeder>

      <section className="padding-120">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-4 col-xs-12 mb-30">
              <Link
                to={ROUTES_PATHS.ADMIN_IAG_IAG}
                className="button-default text-center btn-block"
              >
                IAG
              </Link>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12 mb-30">
              <Link
                to={ROUTES_PATHS.ADMIN_IAG_INSCRICOES_EM_EDITAIS}
                className="button-default text-center btn-block"
              >
                Inscrições em Editais
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
