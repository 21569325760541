const adicionaZeroEsquerda = (numero) => {
  return numero < 10 ? `0${numero}` : numero;
};

const traduzirDataHora = (data) => {
  var dataAtual = new Date(data);
  var dia = dataAtual.getDate();
  var mes = dataAtual.getMonth() + 1;
  var ano = dataAtual.getFullYear();
  var horas = dataAtual.getHours();
  var minutos = dataAtual.getMinutes();

  var data = `${adicionaZeroEsquerda(dia)}/${adicionaZeroEsquerda(mes)}/${ano}`;
  var hora = `${adicionaZeroEsquerda(horas)}:${adicionaZeroEsquerda(minutos)}`;

  return `${data} ${hora}`;
};

module.exports = { traduzirDataHora };
