import { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { traduzirDataHora } from "../../helpers/datas";
import { ApiService, BASE_URL_API } from "../../services/api";
import { cutWithReticence } from "../../utils";
import { PageHeder } from "../page-header";
import Next from "../../assets/next.png";

const INITIAL_ITEM = {
  id: null,
  nome: "",
  email: "",
  telefone: "",
  foto: null,
  status: "Pendente",
  resolucao: null,
  id_carteira: "",
  flagArtista: false,
  flagTecnico: false,
  usuario: {
    usuario: "",
    senha: "",
    confirmeSenha: "",
  },
  contaBancaria: {
    banco: "",
    conta: "",
    agencia: "",
    chavePix: "",
  },
  endereco: {
    cep: "",
    logradouro: "",
    endereco: "",
    numero: "",
    bairro: "",
    cidade: "",
    complemento: "",
    comprovanteResidencia: null,
  },
  categoriaArtista: [],
  categoriaTecnico: [],
  perfil: {
    descricao: "",
    contatoPerfil: [],
    imagemoPerfil: [],
  },
  pessoaFisica: {
    nomeArtistico: "",
    nomeMae: "",
    cpf: "",
    pisPasepNit: "",
    rg: "",
    orgaoExpedidorRg: "",
    dataNascimento: "",
    comprovanteRg: null,
    comprovanteCpf: null,
  },
  flagPessoaFisica: true,
  flagPessoaJuridica: false,
  pessoaJuridica: {
    nomeFantasia: "",
    razaoSocial: "",
    cnpj: "",
    funcao: "",
    dataRegistro: "",
    comprovanteCnpj: null,
  },
  pessoaResponsavel: {
    nome: "",
    telefone: "",
  },
  comprovanteAtividade: [],
  imagemGaleriaIag: [],
};

const LIST_ITEMS_VIEW = "LIST_ITEMS_VIEW";
const REGISTER_ITEM_VIEW = "REGISTER_ITEM_VIEW";
const DELETE_ITEM_VIEW = "DELETE_ITEM_VIEW";

export const IagAdminPage = () => {
  const [carregando, setCarregando] = useState(true);
  const [filtros, setFiltros] = useState(" Todos ");
  const [view, setView] = useState(LIST_ITEMS_VIEW);
  const [items, setItems] = useState([]);
  const [receivedData, setReceivedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterAproved, setFilterAproved] = useState(false);
  const [item, setItem] = useState(INITIAL_ITEM);
  const [errorMessageApi, setErrorMessageApi] = useState("");
  const [editarSenha, setEditarSenha] = useState(0);
  const [categoriaTecnico, setTechnicianCategories] = useState([]);
  const [categoriaArtista, setArtistCategories] = useState([]);
  const [count, setCount] = useState(0);

  //pages
  const [totalPages, setTotalPages] = useState();
  const itemsPerPage = 200;

  //lógica das páginas
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(100);
  const [currentPeople, setCurrentPeople] = useState([]);

  const loadListView = () => {
    setErrorMessageApi("");
    setItem(INITIAL_ITEM);
    setView(LIST_ITEMS_VIEW);
    loadItems();
  };

  const loadRegisterItemView = (item) => {
    setEditarSenha(0);
    setErrorMessageApi("");
    setItem(item);
    setView(REGISTER_ITEM_VIEW);
  };

  const loadDeleteItemView = (item) => {
    setErrorMessageApi("");
    setItem(item);
    setView(DELETE_ITEM_VIEW);
  };

  const changeIagField = (field, value) => {
    const newIag = { ...item, [field]: value };

    setItem(newIag);
  };

  const changeIagUserField = (field, value) => {
    const usuario = { ...item.usuario, [field]: value };
    const newIag = { ...item, usuario };

    setItem(newIag);
  };

  const changeIagPessoaFisicaField = (field, value) => {
    const pessoaFisica = { ...item.pessoaFisica, [field]: value };
    const newIag = { ...item, pessoaFisica };

    setItem(newIag);
  };

  const changeIagPessoaJuridicaField = (field, value) => {
    const pessoaJuridica = { ...item.pessoaJuridica, [field]: value };
    const newIag = { ...item, pessoaJuridica };

    setItem(newIag);
  };

  const changeIagPessoaResponsavelField = (field, value) => {
    const pessoaResponsavel = { ...item.pessoaResponsavel, [field]: value };
    const newIag = { ...item, pessoaResponsavel };

    setItem(newIag);
  };

  const changeIagEnderecoField = (field, value) => {
    const endereco = { ...item.endereco, [field]: value };
    const newIag = { ...item, endereco };

    setItem(newIag);
  };

  const changeIagPerfilField = (field, value) => {
    const perfil = { ...item.perfil, [field]: value };
    const newIag = { ...item, perfil };

    setItem(newIag);
  };

  const adicionarComprovanteAtividade = () => {
    const comprovanteAtividade = [
      ...item.comprovanteAtividade,
      {
        id: null,
        comprovante: null,
      },
    ];
    const newItem = { ...item, comprovanteAtividade };

    setItem(newItem);
  };

  const removerComprovanteAtividade = (idx) => {
    item.comprovanteAtividade[idx].excluir = true;

    const comprovanteAtividade = [...item.comprovanteAtividade];
    const newItem = { ...item, comprovanteAtividade };

    setItem(newItem);
  };

  const changeItemComprovanteAtividadeField = (idx, field, value) => {
    item.comprovanteAtividade[idx][field] = value;

    const comprovanteAtividade = [...item.comprovanteAtividade];
    const newItem = { ...item, comprovanteAtividade };

    setItem(newItem);
  };

  const adicionarImagemGaleriaIag = () => {
    const imagemGaleriaIag = [
      ...item.imagemGaleriaIag,
      {
        id: null,
        comprovante: null,
      },
    ];
    const newItem = { ...item, imagemGaleriaIag };

    setItem(newItem);
  };

  const removerImagemGaleriaIag = (idx) => {
    item.imagemGaleriaIag[idx].excluir = true;

    const imagemGaleriaIag = [...item.imagemGaleriaIag];
    const newItem = { ...item, imagemGaleriaIag };

    setItem(newItem);
  };

  const changeItemImagemGaleriaIagField = (idx, field, value) => {
    item.imagemGaleriaIag[idx][field] = value;

    const imagemGaleriaIag = [...item.imagemGaleriaIag];
    const newItem = { ...item, imagemGaleriaIag };

    setItem(newItem);
  };

  const adicionarContatoPerfil = () => {
    const contatoPerfil = [
      ...item.perfil.contatoPerfil,
      {
        id: null,
        tipoContato: "",
        contato: "",
        excluir: false,
      },
    ];
    const perfil = { ...item.perfil, contatoPerfil };
    const newIag = { ...item, perfil };

    setItem(newIag);
  };

  const removerContatoPerfil = (idx) => {
    item.perfil.contatoPerfil[idx].excluir = true;

    const contatoPerfil = [...item.perfil.contatoPerfil];
    const perfil = { ...item.perfil, contatoPerfil };
    const newIag = { ...item, perfil };

    setItem(newIag);
  };

  const changeIagContatoPerfilField = (idx, field, value) => {
    item.perfil.contatoPerfil[idx][field] = value;

    const contatoPerfil = [...item.perfil.contatoPerfil];
    const perfil = { ...item.perfil, contatoPerfil };
    const newIag = { ...item, perfil };

    setItem(newIag);
  };

  const changeIagContaBancariaField = (field, value) => {
    const contaBancaria = { ...item.contaBancaria, [field]: value };
    const newIag = { ...item, contaBancaria };

    setItem(newIag);
  };

  const changeArtistCategories = (categoriaArtistaSelected) => {
    let cc = [...item.categoriaArtista];
    let ccNew = categoriaArtistaSelected.map(({ value }) =>
      categoriaArtista.find((cc) => cc.id == value)
    );

    cc = cc.map((cco) => {
      if (!ccNew.find((ccn) => ccn.id == cco.id)) {
        cco.excluir = true;
      } else {
        delete cco.excluir;
      }

      return cco;
    });

    ccNew.filter((ccn) => {
      if (!cc.find((cco) => cco.id == ccn.id)) {
        cc.push(ccn);
      }
    });

    setItem({
      ...item,
      categoriaArtista: cc,
    });
  };

  const changeTechnicianCategories = (categoriaTecnicoSelected) => {
    let cc = [...item.categoriaTecnico];
    let ccNew = categoriaTecnicoSelected.map(({ value }) =>
      categoriaTecnico.find((cc) => cc.id == value)
    );

    cc = cc.map((cco) => {
      if (!ccNew.find((ccn) => ccn.id == cco.id)) {
        cco.excluir = true;
      } else {
        delete cco.excluir;
      }

      return cco;
    });

    ccNew.filter((ccn) => {
      if (!cc.find((cco) => cco.id == ccn.id)) {
        cc.push(ccn);
      }
    });

    setItem({
      ...item,
      categoriaTecnico: cc,
    });
  };

  const requestErrorHandler = (error) => {
    if (error.response && error.response.data && error.response.data.mensagem) {
      setErrorMessageApi(error.response.data.mensagem);
      toast.error(error.response.data.mensagem);
    } else if (error.request) {
      toast.error("Falha na requisição.");
      console.log(error.request);
    } else {
      toast.error("Ops! Algo de errado aconteceu.");
      console.log("Error", error.message);
    }
  };

  const validateForm = async (item) => {
    let errorMessages = [];

    return errorMessages.length ? errorMessages.join(";") : null;
  };

  const loadTechnicianCategories = async () => {
    await ApiService.get("/categorias-tecnico").then(({ data }) =>
      setTechnicianCategories(data)
    );
  };

  const loadArtistCategories = async () => {
    await ApiService.get("/categorias-artista").then(({ data }) =>
      setArtistCategories(data)
    );
  };

  const loadItems = async () => {
    setCarregando(true);

    try {
      let iags = await ApiService.get("/iags").then(({ data }) => data); //abcde
      setTotalPages(Math.ceil(iags.length / itemsPerPage));
      setReceivedData(iags);
      setFilteredData(iags);
      setCurrentPeople(iags.slice(startIndex, endIndex));

      setItems(iags);
      await loadTechnicianCategories();
      await loadArtistCategories();

      setCarregando(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.mensagem
      ) {
        setErrorMessageApi(error.response.data.mensagem);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
      setCarregando(false);
    }
  };

  const saveItem = async (item) => {
    setErrorMessageApi("");

    const errorMessage = await validateForm(item);
    if (errorMessage) {
      return;
    }

    const data = new FormData();

    data.append("nome", item.nome);
    data.append("email", item.email);
    data.append("telefone", item.telefone);
    data.append("foto", item.foto);
    data.append("status", item.status);
    data.append("resolucao", item.resolucao);
    data.append("id_carteira", item.id_carteira)
    data.append("flagArtista", item.flagArtista);
    data.append("flagTecnico", item.flagTecnico);
    data.append(
      "usuario",
      JSON.stringify({
        ...item.usuario,
        email: item.email,
        senha: editarSenha ? item.usuario.senha : undefined,
        flagAdmin: undefined,
      })
    );
    data.append("contaBancaria", JSON.stringify(item.contaBancaria));
    data.append(
      "endereco",
      JSON.stringify({
        ...item.endereco,
        comprovanteResidencia: undefined,
      })
    );
    data.append(
      "endereco.comprovanteResidencia",
      item.endereco.comprovanteResidencia
    );
    data.append("categoriaArtista", JSON.stringify(item.categoriaArtista));
    data.append("categoriaTecnico", JSON.stringify(item.categoriaTecnico));
    data.append("perfil", JSON.stringify(item.perfil));
    data.append(
      "pessoaFisica",
      JSON.stringify({
        ...item.pessoaFisica,
        comprovanteCpf: undefined,
        comprovanteRg: undefined,
      })
    );
    data.append(
      "pessoaFisica.comprovanteCpf",
      item.pessoaFisica.comprovanteCpf
    );
    data.append("pessoaFisica.comprovanteRg", item.pessoaFisica.comprovanteRg);
    data.append("flagPessoaFisica", item.flagPessoaFisica);
    data.append("flagPessoaJuridica", item.flagPessoaJuridica);
    data.append(
      "pessoaJuridica",
      JSON.stringify({
        ...item.pessoaJuridica,
        comprovanteCnpj: undefined,
      })
    );
    data.append(
      "pessoaJuridica.comprovanteCnpj",
      item.pessoaJuridica.comprovanteCnpj
    );
    data.append("pessoaResponsavel", JSON.stringify(item.pessoaResponsavel));
    data.append(
      "comprovanteAtividade",
      JSON.stringify(
        item.comprovanteAtividade.map((ae) => ({
          ...ae,
          comprovante: undefined,
        }))
      )
    );

    data.append(
      "imagemGaleriaIag",
      JSON.stringify(
        item.imagemGaleriaIag.map((ae) => ({
          ...ae,
          imagem: undefined,
        }))
      )
    );

    for (let i = 0; i < item.imagemGaleriaIag.length; i++) {
      data.append(
        `imagemGaleriaIag[${i}].imagem`,
        item.imagemGaleriaIag[i].imagem
      );
    }

    for (let i = 0; i < item.comprovanteAtividade.length; i++) {
      data.append(
        `comprovanteAtividade[${i}].comprovante`,
        item.comprovanteAtividade[i].comprovante
      );
    }

    try {
      if (!item.id) {
        await ApiService.post("/iags", data).then();

        toast.success("Cadastro efetuado com sucesso!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        await ApiService.post("/iags/" + item.id, data).then();

        toast.success("Edição efetuada com sucesso!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      loadListView();
    } catch (error) {
      requestErrorHandler(error);
    }
  };

  const deleteItem = async (item) => {
    setErrorMessageApi("");

    try {
      await ApiService.delete("/iags/" + item.id).then();

      toast.success("Remoção efetuada com sucesso!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      loadListView();
    } catch (error) {
      requestErrorHandler(error);
    }
  };

  const handleSubmitRegisterItem = (event) => {
    event.preventDefault();
  };

  const renderDeleteItemView = () => (
    <div className="row">
      <div className="col-md-12 text-center">
        {item ? (
          <>
            <p className="m-0">
              Você realmente deseja deletar o item "{item.nome}"?
            </p>
            <p className="m-0">Esta ação não poderá ser revertida.</p>
          </>
        ) : null}

        <br />

        <div>
          <button
            type="button"
            className="btn btn-default"
            onClick={() => loadListView()}
          >
            Cancelar
          </button>
          &nbsp;
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => deleteItem(item)}
          >
            Remover
          </button>
        </div>
      </div>
    </div>
  );

  const renderRegisterItemView = () => (
    <div className="row">
      <div className="col-md-12">
        {item ? (
          <form onSubmit={handleSubmitRegisterItem}>
            {item.id ? (
              <div className="form-group">
                <label htmlFor="id"># •</label>
                <input
                  type="text"
                  className="form-control"
                  id="id"
                  placeholder="•"
                  value={item.id}
                  disabled
                />
              </div>
            ) : null}

            <div className="form-group">
              <label htmlFor="nome">Número da carteirinha</label>
              <input
                id="id_carteira"
                type="text"
                className="form-control"
                value={item.id_carteira}
                onChange={(event) => changeIagField("id_carteira", event.target.value)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="nome">Nome Completo (obrigatório)</label>
              <input
                id="nome"
                type="text"
                required
                className="form-control"
                value={item.nome}
                onChange={(event) => changeIagField("nome", event.target.value)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">E-mail (obrigatório)</label>
              <input
                required
                id="email"
                type="email"
                className="form-control"
                value={item.email}
                onChange={(event) =>
                  changeIagField("email", event.target.value)
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="telefone">Telefone (obrigatório)</label>
              <input
                required
                id="telefone"
                type="number"
                className="form-control"
                value={item.telefone}
                onChange={(event) =>
                  changeIagField("telefone", event.target.value)
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="flagArtista">
                Você é um artista ? (obrigatório)
              </label>
              <select
                required
                id="flagArtista"
                className="form-control"
                value={item.flagArtista}
                onChange={(event) =>
                  changeIagField("flagArtista", event.target.value)
                }
              >
                <option value={true}>Sim</option>
                <option value={false}>Não</option>
              </select>
            </div>

            {item.flagArtista ? (
              <>
                <div className="form-group">
                  <label htmlFor="categoriaArtista">
                    Categorias Artistica (obrigatório)
                  </label>
                  <Select
                    id="categoriaArtista"
                    required
                    placeholder="Selecione..."
                    noOptionsMessage={() => "Não encontrado"}
                    isMulti={true}
                    value={item.categoriaArtista
                      .filter((cc) => !cc.excluir)
                      .map((cc) => ({
                        value: cc.id,
                        label: cc.nome,
                      }))}
                    onChange={(value) => changeArtistCategories(value)}
                    options={categoriaArtista.map((cc) => ({
                      value: cc.id,
                      label: cc.nome,
                    }))}
                    aria-describedby="categoriaArtista-help-block"
                  />
                  <span
                    id="categoriaArtista-help-block"
                    className="help-block"
                  ></span>
                </div>

                <div className="form-group">
                  <label htmlFor="pessoaFisica.nomeArtistico">
                    Nome Artístico (obrigatório)
                  </label>
                  <input
                    id="pessoaFisica.nomeArtistico"
                    type="text"
                    required
                    className="form-control"
                    value={item.pessoaFisica.nomeArtistico}
                    onChange={(event) =>
                      changeIagPessoaFisicaField(
                        "nomeArtistico",
                        event.target.value
                      )
                    }
                  />
                </div>
              </>
            ) : null}

            <div className="form-group">
              <label htmlFor="flagTecnico">
                Você é um técnico ? (obrigatório)
              </label>
              <select
                required
                id="flagTecnico"
                className="form-control"
                value={item.flagTecnico}
                onChange={(event) =>
                  changeIagField("flagTecnico", event.target.value)
                }
              >
                <option value={true}>Sim</option>
                <option value={false}>Não</option>
              </select>
            </div>

            {item.flagTecnico ? (
              <>
                <div className="form-group">
                  <label htmlFor="categoriaTecnico">
                    Categorias Técnica (obrigatório)
                  </label>
                  <Select
                    id="categoriaTecnico"
                    required
                    placeholder="Selecione..."
                    noOptionsMessage={() => "Não encontrado"}
                    isMulti={true}
                    value={item.categoriaTecnico
                      .filter((cc) => !cc.excluir)
                      .map((cc) => ({
                        value: cc.id,
                        label: cc.nome,
                      }))}
                    onChange={(value) => changeTechnicianCategories(value)}
                    options={categoriaTecnico.map((cc) => ({
                      value: cc.id,
                      label: cc.nome,
                    }))}
                    aria-describedby="categoriaTecnico-help-block"
                  />
                  <span
                    id="categoriaTecnico-help-block"
                    className="help-block"
                  ></span>
                </div>
              </>
            ) : null}

            <div className="form-group">
              <label htmlFor="pessoaFisica.nomeMae">
                Nome da mãe (obrigatório)
              </label>
              <input
                required
                id="pessoaFisica.nomeMae"
                type="text"
                className="form-control"
                value={item.pessoaFisica.nomeMae}
                onChange={(event) =>
                  changeIagPessoaFisicaField("nomeMae", event.target.value)
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="pessoaFisica.cpf">CPF (obrigatório)</label>
              <input
                required
                id="pessoaFisica.cpf"
                type="number"
                className="form-control"
                value={item.pessoaFisica.cpf}
                onChange={(event) =>
                  changeIagPessoaFisicaField("cpf", event.target.value)
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="pessoaFisica.comprovanteCpf">
                Comprovante de CPF (obrigatório)
              </label>
              <ul className="list-group">
                <li className="list-group-item">
                  {item.pessoaFisica.comprovanteCpf &&
                  typeof item.pessoaFisica.comprovanteCpf == "string" ? (
                    <>
                      <button
                        type="button"
                        className="btn btn-xs btn-danger"
                        onClick={() =>
                          changeIagPessoaFisicaField("comprovanteCpf", null)
                        }
                      >
                        Alterar
                      </button>
                      &nbsp;
                      <a
                        target="_blank"
                        href={`${BASE_URL_API}/files/${item.pessoaFisica.comprovanteCpf}`}
                        download="Comprovante de CPF"
                        rel="noreferrer"
                      >
                        {item.pessoaFisica.comprovanteCpf}
                      </a>
                    </>
                  ) : (
                    <input
                      required
                      id="comprovanteCpf"
                      type="file"
                      accept="image/png, image/jpeg, image/jpg, image/jpeg, application/pdf"
                      className="form-control"
                      onChange={(event) =>
                        changeIagPessoaFisicaField(
                          "comprovanteCpf",
                          event.target.files[0]
                        )
                      }
                    />
                  )}
                </li>
              </ul>
            </div>

            <div className="form-group">
              <label htmlFor="pessoaFisica.rg">RG (obrigatório)</label>
              <input
                required
                id="pessoaFisica.rg"
                type="number"
                className="form-control"
                value={item.pessoaFisica.rg}
                onChange={(event) =>
                  changeIagPessoaFisicaField("rg", event.target.value)
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="pessoaFisica.comprovanteRg">
                Comprovante de Rg (obrigatório)
              </label>
              <ul className="list-group">
                <li className="list-group-item">
                  {item.pessoaFisica.comprovanteRg &&
                  typeof item.pessoaFisica.comprovanteRg == "string" ? (
                    <>
                      <button
                        type="button"
                        className="btn btn-xs btn-danger"
                        onClick={() =>
                          changeIagPessoaFisicaField("comprovanteRg", null)
                        }
                      >
                        Alterar
                      </button>
                      &nbsp;
                      <a
                        target="_blank"
                        href={`${BASE_URL_API}/files/${item.pessoaFisica.comprovanteRg}`}
                        download="Comprovante de Rg"
                        rel="noreferrer"
                      >
                        {item.pessoaFisica.comprovanteRg}
                      </a>
                    </>
                  ) : (
                    <input
                      required
                      id="comprovanteRg"
                      type="file"
                      accept="image/png, image/jpeg, image/jpg, image/jpeg, application/pdf"
                      className="form-control"
                      onChange={(event) =>
                        changeIagPessoaFisicaField(
                          "comprovanteRg",
                          event.target.files[0]
                        )
                      }
                    />
                  )}
                </li>
              </ul>
            </div>

            <div className="form-group">
              <label htmlFor="pessoaFisica.orgaoExpedidorRg">
                Orgão Expeditor (obrigatório)
              </label>
              <input
                required
                id="pessoaFisica.orgaoExpedidorRg"
                type="text"
                className="form-control"
                value={item.pessoaFisica.orgaoExpedidorRg}
                onChange={(event) =>
                  changeIagPessoaFisicaField(
                    "orgaoExpedidorRg",
                    event.target.value
                  )
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="pessoaFisica.pisPasepNit">
                PIS / Pasep / Nit
              </label>
              <input
                id="pessoaFisica.pisPasepNit"
                type="number"
                className="form-control"
                value={item.pessoaFisica.pisPasepNit}
                onChange={(event) =>
                  changeIagPessoaFisicaField("pisPasepNit", event.target.value)
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="pessoaFisica.dataNascimento">
                Data de Nscimento (obrigatório)
              </label>
              <input
                required
                id="pessoaFisica.dataNascimento"
                type="date"
                className="form-control"
                value={item.pessoaFisica.dataNascimento}
                onChange={(event) =>
                  changeIagPessoaFisicaField(
                    "dataNascimento",
                    event.target.value
                  )
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="pessoaResponsavel.nome">
                Nome do Responsável
              </label>
              <input
                required
                id="pessoaResponsavel.nome"
                type="text"
                className="form-control"
                value={item.pessoaResponsavel.nome}
                onChange={(event) =>
                  changeIagPessoaResponsavelField("nome", event.target.value)
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="pessoaResponsavel.telefone">
                Telefone do Responsavel
              </label>
              <input
                required
                id="pessoaResponsavel.telefone"
                type="text"
                className="form-control"
                value={item.pessoaResponsavel.telefone}
                onChange={(event) =>
                  changeIagPessoaResponsavelField(
                    "telefone",
                    event.target.value
                  )
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="endereco.cep">CEP (obrigatório)</label>
              <input
                required
                id="endereco.cep"
                type="number"
                className="form-control"
                value={item.endereco.cep}
                onChange={(event) =>
                  changeIagEnderecoField("cep", event.target.value)
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="endereco.endereco">Endereço (obrigatório)</label>
              <input
                required
                id="endereco.endereco"
                type="text"
                className="form-control"
                value={item.endereco.endereco}
                onChange={(event) =>
                  changeIagEnderecoField("endereco", event.target.value)
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="endereco.numero">Numero (obrigatório)</label>
              <input
                required
                id="endereco.numero"
                type="number"
                className="form-control"
                value={item.endereco.numero}
                onChange={(event) =>
                  changeIagEnderecoField("numero", event.target.value)
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="endereco.bairro">Bairro (obrigatório)</label>
              <input
                required
                id="endereco.bairro"
                type="text"
                className="form-control"
                value={item.endereco.bairro}
                onChange={(event) =>
                  changeIagEnderecoField("bairro", event.target.value)
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="endereco.cidade">Cidade (obrigatório)</label>
              <input
                required
                id="endereco.cidade"
                type="text"
                className="form-control"
                value={item.endereco.cidade}
                onChange={(event) =>
                  changeIagEnderecoField("cidade", event.target.value)
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="endereco.complemento">
                Complemento (obrigatório)
              </label>
              <input
                required
                id="endereco.complemento"
                type="text"
                className="form-control"
                value={item.endereco.complemento}
                onChange={(event) =>
                  changeIagEnderecoField("complemento", event.target.value)
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="endereco.comprovanteResidencia">
                Comprovante de Residência (obrigatório)
              </label>

              <ul className="list-group">
                <li className="list-group-item">
                  {item.endereco.comprovanteResidencia &&
                  typeof item.endereco.comprovanteResidencia == "string" ? (
                    <>
                      <button
                        type="button"
                        className="btn btn-xs btn-danger"
                        onClick={() =>
                          changeIagEnderecoField("comprovanteResidencia", null)
                        }
                      >
                        Alterar
                      </button>
                      &nbsp;
                      <a
                        target="_blank"
                        href={`${BASE_URL_API}/files/${item.endereco.comprovanteResidencia}`}
                        download="Comprovante de Residência"
                        rel="noreferrer"
                      >
                        {item.endereco.comprovanteResidencia}
                      </a>
                    </>
                  ) : (
                    <input
                      required
                      id="comprovanteResidencia"
                      type="file"
                      accept="image/png, image/jpeg, image/jpg, image/jpeg, application/pdf"
                      className="form-control"
                      onChange={(event) =>
                        changeIagEnderecoField(
                          "comprovanteResidencia",
                          event.target.files[0]
                        )
                      }
                    />
                  )}
                </li>
              </ul>
            </div>

            <div className="form-group">
              <label>Contatos</label>

              <br />

              <button
                type="button"
                className="btn btn-primary"
                onClick={() => adicionarContatoPerfil()}
              >
                Adicionar Contato
              </button>

              <br />
              <br />

              <ul className="list-group">
                {item.perfil && item.perfil.contatoPerfil
                  ? item.perfil.contatoPerfil.map((cp, idx) =>
                      cp.excluir != true ? (
                        <li className="list-group-item">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="tipoContato">Tipo</label>
                                <select
                                  required
                                  id="tipoContato"
                                  className="form-control"
                                  value={
                                    item.perfil.contatoPerfil[idx].tipoContato
                                  }
                                  onChange={(event) =>
                                    changeIagContatoPerfilField(
                                      idx,
                                      "tipoContato",
                                      event.target.value
                                    )
                                  }
                                >
                                  <option value={"email"}>Email</option>
                                  <option value={"telefone"}>Telefone</option>
                                  <option value={"linkYoutube"}>
                                    Link Youtube
                                  </option>
                                  <option value={"linkFacebook"}>
                                    Link Facebook
                                  </option>
                                  <option value={"linkInstagram"}>
                                    Link Instagram
                                  </option>
                                  <option value={"linkSite"}>Link Site</option>
                                </select>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="contato">Contato</label>
                                <input
                                  id="contato"
                                  type="text"
                                  className="form-control"
                                  value={item.perfil.contatoPerfil[idx].contato}
                                  onChange={(event) =>
                                    changeIagContatoPerfilField(
                                      idx,
                                      "contato",
                                      event.target.value
                                    )
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-md-2">
                              <button
                                type="button"
                                className="btn btn-danger"
                                style={{ marginTop: 33 }}
                                onClick={() => removerContatoPerfil(idx)}
                              >
                                Remover
                              </button>
                            </div>
                          </div>
                        </li>
                      ) : null
                    )
                  : null}
              </ul>
            </div>

            <div className="form-group">
              <label htmlFor="flagPessoaJuridica">
                Pessoa Jurídica (obrigatório)
              </label>
              <select
                required
                id="flagPessoaJuridica"
                className="form-control"
                value={item.flagPessoaJuridica}
                onChange={(event) =>
                  changeIagField("flagPessoaJuridica", event.target.value)
                }
              >
                <option value={true}>Sim</option>
                <option value={false}>Não</option>
              </select>
            </div>

            {item.flagPessoaJuridica ? (
              <>
                <div className="form-group">
                  <label htmlFor="pessoaJuridica.nomeFantasia">
                    Nome Fantasia (obrigatório)
                  </label>
                  <input
                    required
                    id="pessoaJuridica.nomeFantasia"
                    type="text"
                    className="form-control"
                    value={item.pessoaJuridica.nomeFantasia}
                    onChange={(event) =>
                      changeIagPessoaJuridicaField(
                        "nomeFantasia",
                        event.target.value
                      )
                    }
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="pessoaJuridica.razaoSocial">
                    Razão Social (obrigatório)
                  </label>
                  <input
                    required
                    id="pessoaJuridica.razaoSocial"
                    type="text"
                    className="form-control"
                    value={item.pessoaJuridica.razaoSocial}
                    onChange={(event) =>
                      changeIagPessoaJuridicaField(
                        "razaoSocial",
                        event.target.value
                      )
                    }
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="pessoaJuridica.funcao">Função</label>
                  <input
                    id="pessoaJuridica.funcao"
                    type="text"
                    className="form-control"
                    value={item.pessoaJuridica.funcao}
                    onChange={(event) =>
                      changeIagPessoaJuridicaField("funcao", event.target.value)
                    }
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="pessoaJuridica.cnpj">
                    CNPJ (obrigatório)
                  </label>
                  <input
                    required
                    id="pessoaJuridica.cnpj"
                    type="number"
                    className="form-control"
                    value={item.pessoaJuridica.cnpj}
                    onChange={(event) =>
                      changeIagPessoaJuridicaField("cnpj", event.target.value)
                    }
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="pessoaJuridica.dataRegistro">
                    Data de registro (obrigatório)
                  </label>
                  <input
                    required
                    id="pessoaJuridica.dataRegistro"
                    type="date"
                    className="form-control"
                    value={item.pessoaJuridica.dataRegistro}
                    onChange={(event) =>
                      changeIagPessoaJuridicaField(
                        "dataRegistro",
                        event.target.value
                      )
                    }
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="pessoaJuridica.comprovanteCnpj">
                    Comprovante de CNPJ (obrigatório)
                  </label>
                  <ul className="list-group">
                    <li className="list-group-item">
                      {item.pessoaJuridica.comprovanteCnpj &&
                      typeof item.pessoaJuridica.comprovanteCnpj == "string" ? (
                        <>
                          <button
                            type="button"
                            className="btn btn-xs btn-danger"
                            onClick={() =>
                              changeIagPessoaJuridicaField(
                                "comprovanteCnpj",
                                null
                              )
                            }
                          >
                            Alterar
                          </button>
                          &nbsp;
                          <a
                            target="_blank"
                            href={`${BASE_URL_API}/files/${item.pessoaJuridica.comprovanteCnpj}`}
                            download="Comprovante de CNPJ"
                            rel="noreferrer"
                          >
                            {item.pessoaJuridica.comprovanteCnpj}
                          </a>
                        </>
                      ) : (
                        <input
                          required
                          id="comprovanteCnpj"
                          type="file"
                          accept="image/png, image/jpeg, image/jpg, image/jpeg, application/pdf"
                          className="form-control"
                          onChange={(event) =>
                            changeIagPessoaJuridicaField(
                              "comprovanteCnpj",
                              event.target.files[0]
                            )
                          }
                        />
                      )}
                    </li>
                  </ul>
                </div>
              </>
            ) : null}

            <div className="form-group">
              <label>Material Comprobatório</label>

              <br />

              <button
                type="button"
                className="btn btn-primary"
                onClick={() => adicionarComprovanteAtividade()}
              >
                Adicionar Comprovante
              </button>

              <br />
              <br />

              <ul className="list-group">
                {item && item.comprovanteAtividade
                  ? item.comprovanteAtividade.map((cp, idx) =>
                      cp.excluir != true ? (
                        <li className="list-group-item">
                          <div className="row">
                            <div className="col-md-10">
                              <div className="form-group">
                                <label htmlFor={`comprovante-${idx}`}>
                                  Comprovante
                                </label>

                                <div>
                                  {item.comprovanteAtividade[idx].comprovante &&
                                  typeof item.comprovanteAtividade[idx]
                                    .comprovante == "string" ? (
                                    <>
                                      <button
                                        type="button"
                                        className="btn btn-xs btn-danger"
                                        onClick={() =>
                                          changeItemComprovanteAtividadeField(
                                            idx,
                                            "comprovante",
                                            null
                                          )
                                        }
                                      >
                                        Alterar
                                      </button>
                                      &nbsp;
                                      <a
                                        target="_blank"
                                        href={`${BASE_URL_API}/files/${item.comprovanteAtividade[idx].comprovante}`}
                                        download="Arquivo"
                                        rel="noreferrer"
                                      >
                                        {cutWithReticence(
                                          item.comprovanteAtividade[idx]
                                            .comprovante,
                                          30,
                                          false
                                        )}
                                      </a>
                                    </>
                                  ) : (
                                    <input
                                      required
                                      id={`comprovante-${idx}`}
                                      type="file"
                                      accept="image/png, image/jpeg, image/jpg, image/jpeg, application/pdf"
                                      className="form-control"
                                      onChange={(event) =>
                                        changeItemComprovanteAtividadeField(
                                          idx,
                                          "comprovante",
                                          event.target.files[0]
                                        )
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-md-2">
                              <button
                                type="button"
                                className="btn btn-danger"
                                style={{ marginTop: 33 }}
                                onClick={() => removerComprovanteAtividade(idx)}
                              >
                                Remover
                              </button>
                            </div>
                          </div>
                        </li>
                      ) : null
                    )
                  : null}
              </ul>
            </div>

            <div className="form-group">
              <label>Galeria</label>

              <br />

              <button
                type="button"
                className="btn btn-primary"
                onClick={() => adicionarImagemGaleriaIag()}
              >
                Adicionar Imagem
              </button>

              <br />
              <br />

              <ul className="list-group">
                {item && item.imagemGaleriaIag
                  ? item.imagemGaleriaIag.map((cp, idx) =>
                      cp.excluir != true ? (
                        <li className="list-group-item">
                          <div className="row">
                            <div className="col-md-10">
                              <div className="form-group">
                                <label htmlFor={`imagem-${idx}`}>Imagem</label>

                                <div>
                                  {item.imagemGaleriaIag[idx].imagem &&
                                  typeof item.imagemGaleriaIag[idx].imagem ==
                                    "string" ? (
                                    <>
                                      <button
                                        type="button"
                                        className="btn btn-xs btn-danger"
                                        onClick={() =>
                                          changeItemImagemGaleriaIagField(
                                            idx,
                                            "imagem",
                                            null
                                          )
                                        }
                                      >
                                        Alterar
                                      </button>
                                      &nbsp;
                                      <a
                                        target="_blank"
                                        href={`${BASE_URL_API}/files/${item.imagemGaleriaIag[idx].imagem}`}
                                        download="Arquivo"
                                        rel="noreferrer"
                                      >
                                        {cutWithReticence(
                                          item.imagemGaleriaIag[idx].imagem,
                                          30,
                                          false
                                        )}
                                      </a>
                                    </>
                                  ) : (
                                    <input
                                      required
                                      id={`imagem-${idx}`}
                                      type="file"
                                      accept="image/png, image/jpeg, image/jpg, image/jpeg"
                                      className="form-control"
                                      onChange={(event) =>
                                        changeItemImagemGaleriaIagField(
                                          idx,
                                          "imagem",
                                          event.target.files[0]
                                        )
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-md-2">
                              <button
                                type="button"
                                className="btn btn-danger"
                                style={{ marginTop: 33 }}
                                onClick={() => removerImagemGaleriaIag(idx)}
                              >
                                Remover
                              </button>
                            </div>
                          </div>
                        </li>
                      ) : null
                    )
                  : null}
              </ul>
            </div>

            <div className="form-group">
              <label htmlFor="foto">Foto (obrigatório)</label>
              <ul className="list-group">
                <li className="list-group-item">
                  {item.foto && typeof item.foto == "string" ? (
                    <>
                      <button
                        type="button"
                        className="btn btn-xs btn-danger"
                        onClick={() => changeIagField("foto", null)}
                      >
                        Alterar
                      </button>
                      &nbsp;
                      <a
                        target="_blank"
                        href={`${BASE_URL_API}/files/${item.foto}`}
                        download="Foto"
                        rel="noreferrer"
                      >
                        {item.foto}
                      </a>
                    </>
                  ) : (
                    <input
                      required
                      id="foto"
                      type="file"
                      accept="image/png, image/jpeg, image/jpg, image/jpeg"
                      className="form-control"
                      onChange={(event) =>
                        changeIagField("foto", event.target.files[0])
                      }
                    />
                  )}
                </li>
              </ul>
            </div>

            <div className="form-group">
              <label htmlFor="perfil.descricao">
                Breve texto da trajetória artistica / técnica (obrigatório)
              </label>
              <textarea
                required
                id="perfil.descricao"
                type="text"
                className="form-control"
                value={item.perfil.descricao}
                onChange={(event) =>
                  changeIagPerfilField("descricao", event.target.value)
                }
                style={{ height: 200 }}
              ></textarea>
            </div>

            <div className="form-group">
              <label htmlFor="contaBancaria.banco">Banco</label>
              <input
                id="contaBancaria.banco"
                type="text"
                className="form-control"
                value={item.contaBancaria.banco}
                onChange={(event) =>
                  changeIagContaBancariaField("banco", event.target.value)
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="contaBancaria.conta">Conta</label>
              <input
                id="contaBancaria.conta"
                type="text"
                className="form-control"
                value={item.contaBancaria.conta}
                onChange={(event) =>
                  changeIagContaBancariaField("conta", event.target.value)
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="contaBancaria.agencia">Agencia</label>
              <input
                id="contaBancaria.agencia"
                type="text"
                className="form-control"
                value={item.contaBancaria.agencia}
                onChange={(event) =>
                  changeIagContaBancariaField("agencia", event.target.value)
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="contaBancaria.chavePix">Chave Pix</label>
              <input
                id="contaBancaria.chavePix"
                type="text"
                className="form-control"
                value={item.contaBancaria.chavePix}
                onChange={(event) =>
                  changeIagContaBancariaField("chavePix", event.target.value)
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="status">Status (obrigatório)</label>
              <select
                required
                id="status"
                className="form-control"
                value={item.status}
                onChange={(event) =>
                  changeIagField("status", event.target.value)
                }
              >
                <option value={"Aprovado"}>Aprovado</option>
                <option value={"Pendente"}>Pendente</option>
                <option value={"Recusado"}>Recusado</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="resolucao">Resolução</label>
              <textarea
                id="resolucao"
                type="text"
                className="form-control"
                value={item.resolucao}
                onChange={(event) =>
                  changeIagField("resolucao", event.target.value)
                }
              ></textarea>
            </div>

            {item.usuario ? (
              <div className="form-group">
                <label htmlFor="usuario">Usuário (obrigatório)</label>
                <input
                  required
                  id="usuario"
                  type="text"
                  className="form-control"
                  value={item.usuario.usuario}
                  onChange={(event) =>
                    changeIagUserField("usuario", event.target.value)
                  }
                />
              </div>
            ) : null}

            <div className="form-group">
              <label htmlFor="editarSenha">Editar senha do Usuario?</label>
              <select
                required
                id="editarSenha"
                className="form-control"
                value={editarSenha}
                onChange={(event) => setEditarSenha(event.target.value)}
              >
                <option value={1}>Sim</option>
                <option value={0}>Não</option>
              </select>
            </div>

            {editarSenha == 1 ? (
              <>
                <div className="form-group">
                  <label htmlFor="senha">Senha (obrigatório)</label>
                  <input
                    required
                    id="senha"
                    type="text"
                    className="form-control"
                    value={item.usuario.senha}
                    onChange={(event) =>
                      changeIagUserField("senha", event.target.value)
                    }
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="confirmeSenha">
                    Confirmar Senha (obrigatório)
                  </label>
                  <input
                    required
                    id="confirmeSenha"
                    type="text"
                    className="form-control"
                    value={item.usuario.confirmeSenha}
                    onChange={(event) =>
                      changeIagUserField("confirmeSenha", event.target.value)
                    }
                  />
                </div>
              </>
            ) : null}
          </form>
        ) : null}

        <div className="text-right">
          <button
            type="button"
            className="btn btn-default"
            onClick={() => loadListView()}
          >
            Cancelar
          </button>
          &nbsp;
          <button
            type="button"
            className="btn btn-success"
            onClick={() => saveItem(item)}
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
  );

  const renderItem = (item, index) => (
    <tr key={`item-${index}`}>
      <td>{item.id}</td>
      <td>{item.createdAt ? traduzirDataHora(item.createdAt) : ""}</td>
      <td>{item.nome}</td>
      <td>{item.email}</td>
      <td>{item.status}</td>
      <td>{item.id_carteira}</td>
      <td>
        <button
          type="button"
          className="btn btn-warning"
          onClick={() => loadRegisterItemView(item)}
        >
          Editar
        </button>
        &nbsp;
        <button
          type="button"
          className="btn btn-danger"
          onClick={() => loadDeleteItemView(item)}
        >
          Remover
        </button>
      </td>
    </tr>
  );

  const [actualPage, setActualPage] = useState(1);

  const handlePageNumberChange = (e) => {
    const page = e.target.value;

    setActualPage(page);
  };

  useEffect(() => {
    if (actualPage == 1) {
      setCurrentPeople(filteredData.slice(0, itemsPerPage));
    } else if (actualPage > 1 && actualPage <= totalPageNumbers.length) {
      const startIndex = (actualPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setCurrentPeople(filteredData.slice(startIndex, endIndex));
    }
  }, [actualPage]);

  const [totalPageNumbers, setTotalPageNumbers] = useState([]);

  useEffect(() => {
    const arrayNumbers = [];
    for (let index = 1; index <= totalPages; index++) {
      arrayNumbers.push(index);
    }
    setTotalPageNumbers(arrayNumbers);
  }, [totalPages]);

  useEffect(() => {
    setCurrentPeople(filteredData.slice(0, itemsPerPage));
    setActualPage(1);
    setTotalPages(filteredData.length/itemsPerPage+1)
  }, [filteredData]);

  useEffect(() => {
    const sortedItems = [...receivedData].sort((a, b) =>
      a.nome.localeCompare(b.nome)
    );
    setFilteredData(sortedItems);
  }, [filtros]);

  const handleFilterChange = (e) => {
    const option = e.target.value;

    if (option == "idMenor") {
      const sortedItems = [...receivedData].sort((a, b) => {
        // Ignorar itens com id_carteira igual a zero ou nulo
        if (a.id_carteira === 0 || a.id_carteira === null) {
          return 1; // Mover 'a' para o final da lista
        }
        if (b.id_carteira === 0 || b.id_carteira === null) {
          return -1; // Mover 'b' para o final da lista
        }
        
        // Ordenar com base no id_carteira
        return a.id_carteira - b.id_carteira;
      });
      
      setFilteredData(sortedItems);
    }

    if (option == "idMaior") {
      const sortedItems = [...receivedData].sort((a, b) => {
  
        if (a.id_carteira === 0 || a.id_carteira === null) {
          return 1;
        }
        if (b.id_carteira === 0 || b.id_carteira === null) {
          return -1;
        }
        
      
        return b.id_carteira - a.id_carteira;
      });
      
      setFilteredData(sortedItems);
    }

    if (option == "nomeA") {
      const sortedItems = [...receivedData].sort((a, b) =>
        a.nome.localeCompare(b.nome)
      );
      setFilteredData(sortedItems);
    }

    if (option == "nomeZ") {
      const sortedItems = [...receivedData].sort((a, b) =>
        b.nome.localeCompare(a.nome)
      );
      setFilteredData(sortedItems);
    }

    if (option == "emailA") {
      const sortedItems = [...receivedData].sort((a, b) =>
        a.email.localeCompare(b.email)
      );
      setFilteredData(sortedItems);
    }

    if (option == "emailZ") {
      const sortedItems = [...receivedData].sort((a, b) =>
        b.email.localeCompare(a.email)
      );
      setFilteredData(sortedItems);
    }
  };

  const filterName = (event) => {
    const nameToFilter = event.target.value
    if(nameToFilter){
      console.log("Entrou no tem")
      const filteredName = [...filteredData].filter((item)=>{
       return item.nome.toLowerCase().includes(nameToFilter.toLowerCase())
      })

      setCurrentPeople(filteredName)
    }else{
      setActualPage(1)
      setCurrentPeople(filteredData.slice(0, itemsPerPage));
    }

    
  }

  const renderListView = () => (
    <>
      {carregando || <div className="row">
        <h4>Filtros</h4>

        <div className="dropdown">
          <button
            className="btn btn-default dropdown-toggle"
            type="button"
            data-toggle="dropdown"
          >
            Status: {filtros}
            &nbsp;
            <span className="caret"></span>
          </button>
          <ul className="dropdown-menu">
            <li>
              <a
                href="#"
                onClick={() => {
                  setReceivedData(items);
                  setFilteredData(items);
                  setFilterAproved(false);

                  setFiltros("Todos");
                }}
              >
                Todos
              </a>
            </li>
            <li>
              <a
                href="#"
                onClick={() => {
                  setReceivedData(
                    items.filter((item) => item.status == "Pendente")
                  );
                  setFilterAproved(true);

                  setFiltros("Pendentes");
                }}
              >
                Pendentes
              </a>
            </li>
            <li>
              <a
                href="#"
                onClick={() => {
                  setReceivedData(
                    items.filter((item) => item.status == "Aprovado")
                  );
                  setFilterAproved(true);

                  setFiltros("Aprovados");
                  
                }}
              >
                Aprovados
              </a>
            </li>
            <li>
              <a
                href="#"
                onClick={() => {
                  {
                    setReceivedData(
                      items.filter((item) => item.status == "Recusado")
                    );
                    setFilterAproved(true);

                    setFiltros("Recusados");
                  }
                }}
              >
                Recusados
              </a>
            </li>
          </ul>
        </div>
      </div>}

      {carregando || <div className="row">
        <div className="col-md-12 mb-30 header-cadastrar">
          <button
            type="button"
            className="btn btn-success"
            onClick={() => loadRegisterItemView(INITIAL_ITEM)}
          >
            Cadastrar
          </button>
        </div>
      </div>}

      <div className="row">
        <div className="col-md-12 mb-30 header-total-registros">
          <span>
            {carregando ? (
              "Carregando Registros..."
            ) : (
              <>
                <b>{items.length}</b> Registro(s) totais
                <br />
                <br />
                <b>{currentPeople.length}</b> Registro(s) nessa página
              </>
            )}
          </span>
        </div>
      </div>

      <div className="row">
      {carregando ||<div className="col-md-12">
          Filtrar por:
          <select
            className="selectNumbers"
            style={{
              width: "170px",
              padding: "5px",
              margin: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              backgroundColor: "#fff",
              fontWeight: "bold",
            }}
            onChange={handleFilterChange}
          >
            <option value="" style={{ display: "none" }} disabled>
              {" "}
              Escolha um filtro
            </option>

            <option value="nomeA"> Nome: A-Z </option>
            <option value="nomeZ"> Nome: Z-A </option>
            <option value="idMenor"> Nº Carteira: Menor </option>
            <option value="idMaior"> Nº Carteira: Maior </option>
            <option value="emailA"> Email: A-Z </option>
            <option value="emailZ"> Email: Z-A </option>
          </select>
        </div>}
        <div className="col-md-12">
        {carregando ||<div>
            Página:
            <select
              className="selectNumbers"
              style={{
                width: "50px",
                padding: "5px",
                margin: "10px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                backgroundColor: "#fff",
                fontWeight: "bold",
              }}
              onChange={handlePageNumberChange}
              value={actualPage}
            >
              {totalPageNumbers.map((number) => (
                <option
                  key={number}
                  value={number}
                  style={{
                    color: "#333",
                    fontWeight: "bold",
                  }}
                >
                  {number}
                </option>
              ))}
            </select>
          </div>}

          {carregando ||<div>
            Pesquisar nome:
            <input type="text" onChange={filterName} style={{
                width: "200px",
                padding: "5px",
                margin: "10px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                backgroundColor: "#fff",
                fontWeight: "bold",
              }}/>
          </div>}

          <table>
            <thead>
              <tr>
                <th style={{ cursor: "auto" }}># ID</th>
                <th style={{ width: "160px", cursor: "auto" }}>
                  Data de Registro
                </th>
                <th style={{ cursor: "auto" }}>Nome</th>
                <th style={{ cursor: "auto" }}>E-mail</th>
                <th style={{ cursor: "auto" }}>Status</th>
                <th style={{ cursor: "auto" }}>Carteirinha</th>
                <th style={{ width: "172px", cursor: "auto" }}>Opções</th>
              </tr>
            </thead>
            <tbody>
              {carregando ? (
                <tr>
                  <td className="text-center" colSpan={7}>
                    Carregando...
                  </td>
                </tr>
              ) : items && items.length ? (
                currentPeople.map(renderItem) //renderizar
              ) : (
                <tr>
                  <td className="text-center" colSpan={7}>
                    Nenhum registro encontrado
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );

  const renderErrorMessageApi = (errorMessageApi) => (
    <div className="alert alert-danger" role="alert">
      {errorMessageApi}
    </div>
  );

  useEffect(() => {
    loadListView();
  }, []);

  return (
    <>
      <PageHeder>IAGs</PageHeder>

      <section className="pt-30 pb-30">
        <div className="container">
          {errorMessageApi ? renderErrorMessageApi(errorMessageApi) : null}
          {view === LIST_ITEMS_VIEW ? renderListView() : null}
          {view === REGISTER_ITEM_VIEW ? renderRegisterItemView() : null}
          {view === DELETE_ITEM_VIEW ? renderDeleteItemView() : null}
        </div>
      </section>
    </>
  );
};
