import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import { ApiService, BASE_URL_API } from "../../services/api";
import { cutWithReticence } from "../../utils";
import { PageHeder } from "../page-header";
import { traduzirDataHora } from "../../helpers/datas";

const INITIAL_ITEM = {
  id: null,
  edital: null,
  iag: null,
  status: "Pendente",
  resolucao: "",
  anexoAuxEditalIag: [],
};

const LIST_ITEMS_VIEW = "LIST_ITEMS_VIEW";
const REGISTER_ITEM_VIEW = "REGISTER_ITEM_VIEW";
const DELETE_ITEM_VIEW = "DELETE_ITEM_VIEW";

export const InscricaoEditaisAdminPage = () => {
  const [carregando, setCarregando] = useState(true);
  const [filtros, setFiltros] = useState({ status: "Todos" });
  const [view, setView] = useState(LIST_ITEMS_VIEW);
  const [items, setItems] = useState([]);
  const [editais, setEditais] = useState([]);
  const [iags, setIags] = useState([]);
  const [item, setItem] = useState(INITIAL_ITEM);
  const [errorMessageApi, setErrorMessageApi] = useState("");

  const loadListView = () => {
    setErrorMessageApi("");
    setItem(INITIAL_ITEM);
    setView(LIST_ITEMS_VIEW);
    loadItems();
  };

  const verificaAnexosInscricao = (item) => {
    if (!item.anexoAuxEditalIag.length) {
      if (item.edital) {
        item.anexoAuxEditalIag = item.edital.anexoEdital.map((ae) => ({
          arquivo: null,
          anexoEdital: ae,
          idAnexoEdital: ae.id,
        }));
      } else {
        item.anexoAuxEditalIag = item.anexoAuxEditalIag.map((anexo) => ({
          ...anexo,
          excluir: true,
        }));
      }
    }

    return item;
  };

  const loadRegisterItemView = (item) => {
    setErrorMessageApi("");
    setItem(verificaAnexosInscricao(item));
    setView(REGISTER_ITEM_VIEW);
  };

  const loadDeleteItemView = (item) => {
    setErrorMessageApi("");
    setItem(item);
    setView(DELETE_ITEM_VIEW);
  };

  const changeitemField = (field, value) => {
    const newitem = { ...item, [field]: value };

    setItem(newitem);
  };

  const changeitemEditalField = ({ value }) => {
    let edital = null;

    if (value) {
      edital = editais.find((ed) => ed.id == value);
    }

    const newitem = { ...item, edital };

    setItem(verificaAnexosInscricao(newitem));
  };

  const changeitemIagField = ({ value }) => {
    let iag = null;

    if (value) {
      iag = iags.find((ig) => ig.id == value);
    }

    const newitem = { ...item, iag };

    setItem(newitem);
  };

  const changeItemAnexoAuxEditalFieldIag = (idx, field, value) => {
    item.anexoAuxEditalIag[idx][field] = value;

    const anexoAuxEditalIag = [...item.anexoAuxEditalIag];
    const newItem = { ...item, anexoAuxEditalIag };

    setItem(newItem);
  };

  const validateForm = async (item) => {
    let errorMessages = [];

    if (!item.edital) {
      const errorMessage = "Campo Edital inválido";
      window.$(`form .form-group:has(#edital)`).addClass("has-error");
      window.$(`form .form-group #edital-help-block`).text(errorMessage);

      errorMessages.push(errorMessage);
    } else {
      window.$(`form .form-group:has(#edital)`).removeClass("has-error");
      window.$(`form .form-group #edital-help-block`).text("");
    }

    if (!item.iag) {
      const errorMessage = "Campo Edital inválido";
      window.$(`form .form-group:has(#iag)`).addClass("has-error");
      window.$(`form .form-group #iag-help-block`).text(errorMessage);

      errorMessages.push(errorMessage);
    } else {
      window.$(`form .form-group:has(#iag)`).removeClass("has-error");
      window.$(`form .form-group #iag-help-block`).text("");
    }

    return errorMessages.length ? errorMessages.join(";") : null;
  };

  const loadItems = async () => {
    setCarregando(true);

    try {
      let inscricoes = [];

      const editaisFromApi = await ApiService.get("/editais").then(
        ({ data }) => data
      );

      const iagsFromApi = await ApiService.get("/iags").then(
        ({ data }) => data
      );

      if (editaisFromApi && Array.isArray(editaisFromApi)) {
        for (let edital of editaisFromApi) {
          let inscricoesFromApi = await ApiService.get(
            `/editais/${edital.id}/inscricoes`
          ).then(({ data }) => data);

          if (inscricoesFromApi && Array.isArray(inscricoesFromApi)) {
            for (let inscricaoFromApi of inscricoesFromApi) {
              inscricoes.push(inscricaoFromApi);
            }
          }
        }
      }

      setEditais(editaisFromApi);
      setIags(iagsFromApi);

      if (filtros.status == "Pendentes") {
        inscricoes = inscricoes.filter(
          (inscricao) => inscricao.status == "Pendente"
        );
      } else if (filtros.status == "Aprovados") {
        inscricoes = inscricoes.filter(
          (inscricao) => inscricao.status == "Aprovado"
        );
      } else if (filtros.status == "Recusados") {
        inscricoes = inscricoes.filter(
          (inscricao) => inscricao.status == "Recusado"
        );
      }

      setItems(inscricoes);
      setCarregando(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.mensagem
      ) {
        setErrorMessageApi(error.response.data.mensagem);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
      setCarregando(false);
    }
  };

  const saveItem = async (item) => {
    setErrorMessageApi("");

    const errorMessage = await validateForm(item);
    if (errorMessage) {
      return;
    }

    const data = new FormData();

    data.append("status", item.status);
    data.append("resolucao", item.resolucao);

    data.append(
      "anexoAuxEditalIag",
      JSON.stringify(
        item.anexoAuxEditalIag.map((ae) => ({ ...ae, arquivo: undefined }))
      )
    );

    for (let i = 0; i < item.anexoAuxEditalIag.length; i++) {
      data.append(
        `anexoAuxEditalIag[${i}].arquivo`,
        item.anexoAuxEditalIag[i].arquivo
      );
    }

    try {
      if (!item.id) {
        await ApiService.post(
          `/editais/${item.edital.id}/inscricoes/${item.iag.id}`,
          data
        ).then();

        toast.success("Cadastro efetuado com sucesso!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        await ApiService.post(
          `/editais/${item.edital.id}/inscricoes/${item.iag.id}`,
          data
        ).then();

        toast.success("Edição efetuada com sucesso!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      loadListView();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.mensagem
      ) {
        setErrorMessageApi(error.response.data.mensagem);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
    }
  };

  const deleteItem = async (item) => {
    setErrorMessageApi("");

    try {
      await ApiService.delete(
        `/editais/${item.edital.id}/inscricoes/${item.iag.id}`
      ).then();

      toast.success("Remoção efetuada com sucesso!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      loadListView();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.mensagem
      ) {
        setErrorMessageApi(error.response.data.mensagem);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
    }
  };

  const renderDeleteItemView = () => (
    <div className="row">
      <div className="col-md-12 text-center">
        {item ? (
          <>
            <p className="m-0">
              Você realmente deseja deletar a inscrição do "{item?.iag?.nome}"
              do edital "{item?.edital?.nome}"?
            </p>
            <p className="m-0">Esta ação não poderá ser revertida.</p>
          </>
        ) : null}

        <br />

        <div>
          <button
            type="button"
            className="btn btn-default"
            onClick={() => loadListView()}
          >
            Cancelar
          </button>
          &nbsp;
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => deleteItem(item)}
          >
            Remover
          </button>
        </div>
      </div>
    </div>
  );

  const renderRegisterItemView = () =>
    item ? (
      <div className="row">
        <div className="col-md-12">
          {item ? (
            <form>
              {item.id ? (
                <div className="form-group">
                  <label htmlFor="id"># ID</label>
                  <input
                    type="text"
                    className="form-control"
                    id="id"
                    placeholder="ID"
                    value={item.id}
                    disabled
                  />
                </div>
              ) : null}

              <div className="form-group">
                <label htmlFor="iag">Iag (obrigatório)</label>
                <Select
                  id="iag"
                  required
                  isDisabled={!!item.id}
                  placeholder="Selecione..."
                  noOptionsMessage={() => "Não encontrado"}
                  value={
                    item.iag
                      ? { value: item.iag.id, label: item.iag.nome }
                      : null
                  }
                  onChange={(value) => changeitemIagField(value)}
                  options={iags.map((ig) => ({ value: ig.id, label: ig.nome }))}
                  aria-describedby="iag-help-block"
                />
                <span id="iag-help-block" className="help-block"></span>
              </div>

              <div className="form-group">
                <label htmlFor="edital">Edital (obrigatório)</label>
                <Select
                  id="edital"
                  required
                  isDisabled={!!item.id}
                  placeholder="Selecione..."
                  noOptionsMessage={() => "Não encontrado"}
                  value={
                    item.edital
                      ? { value: item.edital.id, label: item.edital.nome }
                      : null
                  }
                  onChange={(value) => changeitemEditalField(value)}
                  options={editais.map((ig) => ({
                    value: ig.id,
                    label: ig.nome,
                  }))}
                  aria-describedby="edital-help-block"
                />
                <span id="edital-help-block" className="help-block"></span>
              </div>

              {item.edital && item.edital.arquivo ? (
                <>
                  <a
                    target="_blank"
                    href={`${BASE_URL_API}/files/${item.edital.arquivo}`}
                    download={`Baixar arquivo do Edital`}
                    rel="noreferrer"
                    className="btn btn-primary"
                  >
                    {`Baixar arquivo do Edital`}
                  </a>

                  <br />
                  <br />
                </>
              ) : null}

              <div className="form-group">
                <label htmlFor="status">Status (obrigatório)</label>
                <select
                  required
                  id="status"
                  className="form-control"
                  value={item.status}
                  onChange={(event) =>
                    changeitemField("status", event.target.value)
                  }
                >
                  <option value={"Aprovado"}>Aprovado</option>
                  <option value={"Pendente"}>Pendente</option>
                  <option value={"Recusado"}>Recusado</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="resolucao">Resolução</label>
                <textarea
                  id="resolucao"
                  type="text"
                  className="form-control"
                  value={item.resolucao}
                  onChange={(event) =>
                    changeitemField("resolucao", event.target.value)
                  }
                ></textarea>
              </div>

              {item && item.anexoAuxEditalIag
                ? item.anexoAuxEditalIag
                    .filter(({ excluir }) => !excluir == true)
                    .map((cp, idx) => (
                      <div className="form-group">
                        <label htmlFor={`arquivo-${idx}`}>
                          {item.anexoAuxEditalIag[idx]?.anexoEdital?.nome}
                        </label>

                        <div>
                          {item.anexoAuxEditalIag[idx]?.arquivo &&
                          typeof item.anexoAuxEditalIag[idx]?.arquivo ==
                            "string" ? (
                            <>
                              <button
                                type="button"
                                className="btn btn-xs btn-danger"
                                onClick={() =>
                                  changeItemAnexoAuxEditalFieldIag(
                                    idx,
                                    "arquivo",
                                    null
                                  )
                                }
                              >
                                Alterar
                              </button>
                              &nbsp;
                              <a
                                target="_blank"
                                href={`${BASE_URL_API}/files/${item.anexoAuxEditalIag[idx]?.arquivo}`}
                                download="Arquivo"
                                rel="noreferrer"
                              >
                                {cutWithReticence(
                                  item.anexoAuxEditalIag[idx]?.arquivo,
                                  30,
                                  false
                                )}
                              </a>
                            </>
                          ) : (
                            <input
                              required
                              id={`arquivo-${idx}`}
                              type="file"
                              accept="image/png, image/jpeg, image/jpg, image/jpeg, application/pdf"
                              className="form-control"
                              onChange={(event) =>
                                changeItemAnexoAuxEditalFieldIag(
                                  idx,
                                  "arquivo",
                                  event.target.files[0]
                                )
                              }
                            />
                          )}
                        </div>

                        <br />

                        <a
                          target="_blank"
                          href={`${BASE_URL_API}/files/${item.anexoAuxEditalIag[idx]?.anexoEdital.arquivo}`}
                          download={`Baixar ${item.anexoAuxEditalIag[idx]?.anexoEdital.nome} do Edital`}
                          rel="noreferrer"
                          className="btn btn-primary"
                        >
                          {`Baixar ${item.anexoAuxEditalIag[idx]?.anexoEdital.nome} do Edital`}
                        </a>
                      </div>
                    ))
                : null}
            </form>
          ) : null}

          <div className="text-right">
            <button
              type="button"
              className="btn btn-default"
              onClick={() => loadListView()}
            >
              Cancelar
            </button>
            &nbsp;
            <button
              type="button"
              className="btn btn-success"
              onClick={() => saveItem(item)}
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    ) : null;

  const renderItem = (item, index) => (
    <tr key={`item-${index}`}>
      <td>{item.id}</td>
      <td>{item.createdAt ? traduzirDataHora(item.createdAt) : ""}</td>
      <td>{item.edital.nome}</td>
      <td>{item.iag.nome}</td>
      <td>{item.status}</td>
      <td>
        <button
          type="button"
          className="btn btn-warning"
          onClick={() => loadRegisterItemView(item)}
        >
          Editar
        </button>
        &nbsp;
        <button
          type="button"
          className="btn btn-danger"
          onClick={() => loadDeleteItemView(item)}
        >
          Remover
        </button>
      </td>
    </tr>
  );

  const renderListView = () => (
    <>
      <div className="row">
        <h4>Filtros</h4>

        <div className="dropdown">
          <button
            className="btn btn-default dropdown-toggle"
            type="button"
            data-toggle="dropdown"
          >
            Status: {filtros.status}
            &nbsp;
            <span className="caret"></span>
          </button>
          <ul className="dropdown-menu">
            <li>
              <a
                href="#"
                onClick={() => setFiltros({ ...filtros, status: "Todos" })}
              >
                Todos
              </a>
            </li>
            <li>
              <a
                href="#"
                onClick={() => setFiltros({ ...filtros, status: "Pendentes" })}
              >
                Pendentes
              </a>
            </li>
            <li>
              <a
                href="#"
                onClick={() => setFiltros({ ...filtros, status: "Aprovados" })}
              >
                Aprovados
              </a>
            </li>
            <li>
              <a
                href="#"
                onClick={() => setFiltros({ ...filtros, status: "Recusados" })}
              >
                Recusados
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 text-right mb-30">
          <button
            type="button"
            className="btn btn-success"
            onClick={() => loadRegisterItemView(INITIAL_ITEM)}
          >
            Cadastrar
          </button>
        </div>
      </div>

      <div className="row">
        <table className="col-md-12">
          <thead>
            <tr>
              <th># ID</th>
              <th style={{ width: "160px" }}>Data de Registro</th>
              <th>Edital</th>
              <th>Inscrito</th>
              <th>Status</th>
              <th style={{ width: "172px" }}>Opções</th>
            </tr>
          </thead>
          <tbody>
            {carregando ? (
              <tr>
                <td className="text-center" colSpan={6}>
                  Carregando...
                </td>
              </tr>
            ) : items && items.length ? (
              items.map(renderItem)
            ) : (
              <tr>
                <td className="text-center" colSpan={6}>
                  Nenhum registro encontrado
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );

  const renderErrorMessageApi = (errorMessageApi) => (
    <div className="alert alert-danger" role="alert">
      {errorMessageApi}
    </div>
  );

  useEffect(() => {
    loadListView();
  }, [filtros]);

  useEffect(() => {
    loadListView();
  }, []);

  return (
    <>
      <PageHeder>Inscrições em Editais</PageHeder>

      <section className="pt-30 pb-30">
        <div className="container">
          {errorMessageApi ? renderErrorMessageApi(errorMessageApi) : null}
          {view === LIST_ITEMS_VIEW ? renderListView() : null}
          {view === REGISTER_ITEM_VIEW ? renderRegisterItemView() : null}
          {view === DELETE_ITEM_VIEW ? renderDeleteItemView() : null}
        </div>
      </section>
    </>
  );
};
