import React, { useEffect, useMemo, useState } from "react";
import { Carousel } from "react-carousel-minimal";
import "./styles.css";

export const Galeria = ({ images }) => {
  const data = useMemo(() => {
    return images.map((image) => ({ image, caption: "" }));
  }, [images]);

  const captionStyle = {
    fontSize: "2em",
    fontWeight: "bold",
  };

  const slideNumberStyle = {
    fontSize: "20px",
    fontWeight: "bold",
  };

  function removeFadeClass() {
    document
      .querySelectorAll(".carousel-container .carousel-item.fade")
      .forEach((box) => box.classList.remove("fade"));
  }

  useMemo(() => {
    setTimeout(() => {
      removeFadeClass();
    }, 1000);
  }, [images]);

  return data.length ? (
    <div style={{ textAlign: "center" }}>
      <Carousel
        className={"carousel-gallery"}
        data={data}
        width="100%"
        height="600px"
        captionStyle={captionStyle}
        radius="10px"
        slideNumber={true}
        slideNumberStyle={slideNumberStyle}
        captionPosition="bottom"
        automatic={false}
        time={3000}
        dots={true}
        pauseIconColor="white"
        pauseIconSize="40px"
        slideBackgroundColor="#999"
        slideImageFit="contain"
        thumbnails={true}
        thumbnailWidth="100px"
        style={{
          textAlign: "center",
          maxWidth: "100%",
          maxHeight: "600px",
          margin: "40px auto",
        }}
      />
    </div>
  ) : null;
};
