import { Link } from "react-router-dom";
import { ROUTES_PATHS } from "../../routes";
import { PageHeder } from "../page-header";

export const HomeAdminPage = () => {
  return (
    <>
      <PageHeder>Administração</PageHeder>

      <section className="padding-120">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-4 col-xs-12 mb-30">
              <Link
                to={ROUTES_PATHS.ADMIN_CONFIGURACAO_SITE}
                className="button-default text-center btn-block"
              >
                Configuração do Site
              </Link>
            </div>

            <div className="col-md-4 col-sm-4 col-xs-12 mb-30">
              <Link
                to={ROUTES_PATHS.ADMIN_CLUBS_CATEGORIES}
                className="button-default text-center btn-block"
              >
                Categorias dos Clubes de Vantagens
              </Link>
            </div>

            <div className="col-md-4 col-sm-4 col-xs-12 mb-30">
              <Link
                to={ROUTES_PATHS.ADMIN_ARTISTS_CATEGORIES}
                className="button-default text-center btn-block"
              >
                Categorias dos Artistas
              </Link>
            </div>

            <div className="col-md-4 col-sm-4 col-xs-12 mb-30">
              <Link
                to={ROUTES_PATHS.ADMIN_TECHNICIAN_CATEGORIES}
                className="button-default text-center btn-block"
              >
                Categorias dos Técnicos
              </Link>
            </div>

            <div className="col-md-4 col-sm-4 col-xs-12 mb-30">
              <Link
                to={ROUTES_PATHS.ADMIN_PERKS_CLUBS}
                className="button-default text-center btn-block"
              >
                Clubes de Vantagens
              </Link>
            </div>

            <div className="col-md-4 col-sm-4 col-xs-12 mb-30">
              <Link
                to={ROUTES_PATHS.ADMIN_USUARIOS}
                className="button-default text-center btn-block"
              >
                Usuários
              </Link>
            </div>

            <div className="col-md-4 col-sm-4 col-xs-12 mb-30">
              <Link
                to={ROUTES_PATHS.ADMIN_IAGS}
                className="button-default text-center btn-block"
              >
                IAGs
              </Link>
            </div>

            <div className="col-md-4 col-sm-4 col-xs-12 mb-30">
              <Link
                to={ROUTES_PATHS.ADMIN_EDITAIS}
                className="button-default text-center btn-block"
              >
                Editais
              </Link>
            </div>

            <div className="col-md-4 col-sm-4 col-xs-12 mb-30">
              <Link
                to={ROUTES_PATHS.ADMIN_INSCRICOES_EM_EDITAIS}
                className="button-default text-center btn-block"
              >
                Inscrições em Editais
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
